import { EventType } from "../constants/event-type-constants";
import { MarketingClientConstants } from "../constants/marketing-client-constants";
import { DOMHelper } from "../helpers/dom-helper";
import { Utils } from "./utils";
export class Checkbox {
    constructor(id, onClick = null) {
        var _a;
        this.input = DOMHelper.getElementById(id);
        this.required = ((_a = this.input) === null || _a === void 0 ? void 0 : _a.dataset.notRequired) === undefined;
        this.text = DOMHelper.querySelector(`#${id}-text`);
        this.checkmark = DOMHelper.querySelector(`#${id} + span`);
        this.error = DOMHelper.querySelector(`#${id}-error`);
        this.callback = onClick;
        this.bindEventToCallbackOnClick();
    }
    setText(text) {
        if (this.text) {
            this.text.innerHTML = text;
        }
    }
    isValidate() {
        var _a;
        return !this.required || !!((_a = this.input) === null || _a === void 0 ? void 0 : _a.checked);
    }
    getValue() {
        var _a;
        return !!((_a = this.input) === null || _a === void 0 ? void 0 : _a.checked);
    }
    toggle() {
        if (this.isValidate()) {
            this.hideError();
        }
        else {
            this.showError();
        }
    }
    showError() {
        var _a;
        Utils.showContainer(this.error);
        (_a = this.checkmark) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hasErrorClassName);
    }
    hideError() {
        var _a;
        Utils.hideContainer(this.error);
        (_a = this.checkmark) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hasErrorClassName);
    }
    reset() {
        this.hideError();
        if (this.input) {
            this.input.checked = false;
        }
    }
    bindEventToCallbackOnClick() {
        if (this.input) {
            this.input.addEventListener(EventType.CLICK, () => {
                if (this.callback) {
                    this.callback();
                }
            });
        }
    }
}
