import { Selector } from "./constants/closing-without-lp";
import { DOMHelper } from "./helpers/dom-helper";
export class Padlock {
    init() {
        this.initializeSection();
        this.checkIfFixedScreening();
        if (this.isFixedScreening && this.section)
            this.fixPadlock();
    }
    initializeSection() {
        this.section = DOMHelper.querySelector(Selector.padlock);
    }
    checkIfFixedScreening() {
        this.isFixedScreening = document.body.classList.contains(Selector.fixedScreening);
    }
    fixPadlock() {
        this.section.classList.add(Selector.fixedPadlock);
    }
}
