import { RwSelector } from "./constants/rw-object.constants";
import { MarketingClientRWBase } from "./marketing-client-rw-base";
import { BigDataClickID } from "./constants/big-data-constants";
import { EventType } from "./constants/event-type-constants";
import { UtilizationConstants } from "./constants/marketing-client-utilization-constants";
import { DeviceInformationHelper } from "./helpers/device-information-helper";
import { DOMHelper } from "./helpers/dom-helper";
import { httpInstance } from "./http/http";
export class MarketingClientRWOwner extends MarketingClientRWBase {
    constructor(capping, utilizationObject, classSection = RwSelector.rwOwnerSelector) {
        super(capping, utilizationObject, classSection);
        this.capping = capping;
        this.utilizationObject = utilizationObject;
        this.classSection = classSection;
        this.purchaseSubscription = ".button-purchase";
        this.isLoading = false;
    }
    init() {
        this.initialize();
        if (!this.section) {
            return;
        }
        this.initializePurchaseButton();
        this.initializeRwElement();
        this.initializeButtonLoader();
        this.bindSwimmerEvents();
        this.bindClickIDEvents();
        this.bindUtilization();
        this.showSwimmer();
    }
    initializePurchaseButton() {
        this.purchaseButton = DOMHelper.querySelector(this.purchaseSubscription);
    }
    initializeRwElement() {
        if (this.section) {
            this.rwElement = DOMHelper.querySelector(`[${UtilizationConstants.dataUtilizeRegistrationWall}]`, this.section);
        }
    }
    initializeButtonLoader() {
        if (this.rwElement) {
            this.buttonLoader = DOMHelper.querySelector(".button-loader", this.rwElement);
        }
    }
    bindClickIDEvents() {
        if (this.purchaseButton) {
            this.purchaseButton.addEventListener(EventType.CLICK, () => {
                this.utilize(BigDataClickID.purchaseSubscription, true);
            });
        }
    }
    bindUtilization() {
        if (this.rwElement) {
            this.rwElement.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.utilization();
            });
        }
    }
    utilization() {
        if (this.isLoading)
            return;
        this.isLoading = true;
        this.utilize(BigDataClickID.unlockAndRead);
        this.openArticleAndreloadLocation();
    }
    openArticleAndreloadLocation() {
        var _a;
        const article = DeviceInformationHelper.getXX();
        (_a = this.buttonLoader) === null || _a === void 0 ? void 0 : _a.classList.add(UtilizationConstants.visible);
        if (article) {
            this.openArticle(article).then(() => {
                this.reloadLocation();
            }, (error) => {
                var _a;
                (_a = this.buttonLoader) === null || _a === void 0 ? void 0 : _a.classList.remove(UtilizationConstants.visible);
                this.isLoading = false;
                console.error(error);
            });
        }
    }
    reloadLocation() {
        setTimeout(() => {
            if (window.location) {
                window.location.reload();
            }
        }, 3000);
    }
    openArticle(article) {
        const url = "https://squid-api.wyborcza.pl/lead-catcher/leads/v2/article", headers = { "Content-Type": "application/json" };
        return httpInstance.put({
            url,
            headers,
            withCredentials: true,
            data: { article }
        });
    }
}
