export const BigDataObjectConstants = {
    dataOceanKey: "DataOcean",
    bigDataGUIDCookie: "bwGuidv3",
    bigDataBlueWhaleObject: "DataOcean.BluewhalePortal.bwTrck",
    bigDataGUID: "BLUEWHALE_GUID",
    bigDataSiteDetails: "site_details",
    pageViewBigData: "PAGE_VIEW_ID",
    pageViewCookie: "bwPageViewId",
    visitIdCookie: "bwVisitId",
    visitIdBigData: "VISIT_ID",
    artPvidParam: "art_pvid",
};
