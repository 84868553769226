export default class Tooltip {
    static show(button, message) {
        const tooltip = document.createElement('div');
        const rect = button.getBoundingClientRect();
        tooltip.className = 'tooltip';
        tooltip.textContent = message;
        tooltip.style.zIndex = "2000";
        tooltip.style.top = (rect.top + rect.height / 2 + window.scrollY) + 'px';
        if (rect.left < window.innerWidth / 2) {
            tooltip.style.left = (rect.left + rect.width) + 'px';
        }
        else {
            tooltip.style.right = (window.innerWidth - rect.right + rect.width) + 'px';
        }
        document.body.insertAdjacentElement('beforeend', tooltip);
        setTimeout(() => tooltip.remove(), 3000);
    }
}
