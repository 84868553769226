import { MarketingClientAttributesConstants } from "./marketing-client-attributes-constants";
export const SwimmerConstants = {
    swimmerSelector: `[${MarketingClientAttributesConstants.isSwimmer}="true"]`,
    swimmerHPSelector: "PLDM_SWIM_HP",
    closeButtonSelector: ".close",
    padlockSelector: "#p_lock_body",
    copSwimmerCarouselSelector: ".cop_swimmer_carousel",
    visibleClass: "is-visible",
    closedManuallyClass: "closed-manually",
    animationClass: "hiding",
    hiddenClass: "hidden",
    promoboxDelay: 8e3,
    dataPromoboxDelay: "data-promobox-delay",
    dataPromoboxDelayValue: "data-promobox-delay-value",
    dataOnExitPopUp: "data-on-exit-pop-up",
    dataOnExitPopUpValue: "data-on-exit-pop-up-value",
    dataUserInactivityTimeout: "data-user-inactivity-timeout",
    dataUserInactivityTimeoutValue: "data-user-inactivity-timeout-value",
    dataAutoRotation: "data-auto-rotate",
    dataRotationInterval: "data-rotation-interval",
    collapsButtonID: "collaps-button",
    arrowUpID: "arrow-up",
    arrowLeftID: "arrow-left",
    arrowRightID: "arrow-right",
    articleBoxesID: ["box0", "box1", "box2"],
    timerDateSelector: "[data-timer-swimmer]",
    timerSelector: ".swimmer-timer-countdown",
    placeholderPadlock: "[data-mc-placeholder-id='PLDM_ART_PADLOCK']",
    placeholderWidgetHp: "[data-mc-placeholder-id='PLDM_WIDGET_HP']",
    dataIsZcp: "data-is-zcp",
    dataArticleScroll: "data-article-scroll",
    dataArticleScrollValue: "data-article-scroll-value",
};
