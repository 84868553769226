export const NEXT_STEP = "nextStep";
export const PREV_STEP = "prevStep";
export const OnboardingConstants = {
    containerSelector: ".ddbox-onboarding",
    nextStepEvent: "Przejdź dalej",
    prevStepEvent: "Wróć",
    closeEvent: "X",
    finishEvent: "Zakończ",
    shareSubEvent: "Podaruj prenumeratę",
    viewAbility: "1",
    viewAbilityId: "rekomendacje-onboarding-dm",
    prevCarouselEvent: "article-carousel-left",
    nextCarouselEvent: "article-carousel-right",
    downloadApp: "pobierz app",
    downloadAppIos: "pobierz app ios",
    downloadAppAndroid: "pobierz app android",
};
export const RecommendationOnboardingConst = {
    dataRecommendation: "data-recommendation",
    sliderElements: "ddbox-onboarding__recommendation__slider__elements",
    desktopWrapper: "ddbox-onboarding__recommendation__slider__desktop-wrapper",
    sliderElement: "ddbox-onboarding__recommendation__slider__element",
    sliderTag: "ddbox-onboarding__recommendation__slider__element__tag",
    sliderTitle: "ddbox-onboarding__recommendation__slider__element__title",
    sliderPhoto: "ddbox-onboarding__recommendation__slider__element__photo",
    sliderPhotoWrapper: "ddbox-onboarding__recommendation__slider__element__photo-wrapper",
    arrowRight: "ddbox-onboarding__recommendation__slider__arrow--right",
    arrowLeft: "ddbox-onboarding__recommendation__slider__arrow--left",
    arrowDesktop: "arrow-desktop",
    arrowMobile: "arrow-mobile",
    activeDesktop: "active-desktop",
    activeElement: "active-element",
};
export const SubShareOnboardingConst = {
    visible: "visible",
    visibleDesktop: "visible_desktop",
    enterSecondEmail: "Wprowadź drugi adres e-mail",
    invitationSent: "✓ Zaproszenie wysłane",
};
export const SubShareOnboardingSelector = {
    sharesubSelector: ".mc-sharesub",
    firstEmailSelector: ".mc-sharesub-email-1",
    secondEmailSelector: ".mc-sharesub-email-2",
    firstGiftButtonSelector: ".mc-sharesub-gift-button-1",
    secondGiftButtonSelector: ".mc-sharesub-gift-button-2",
    firstEmailContainerSelector: ".mc-sharesub-input-container--1",
    secondEmailContainerSelector: ".mc-sharesub-input-container--2",
    emailContainerSelectors: ".mc-sharesub-input-container",
    addButtonSelector: ".mc-sharesub-add-button",
    prevBtnSelector: ".mc-sharesub-back-btn",
    nextBtnSelector: ".mc-sharesub-forward-btn",
    finishButtonSelector: ".mc-sharesub-save-btn",
    descriptionSelector: ".mc-sharesub-description",
    sentTwoInvitesDescSelector: ".mc-sharesub-sent-two-invites-desc",
};
export const SubShareOnboardingErrorMessage = {
    banned: "Podany e-mail jest błędny, wpisz poprawny adres",
    fillToShare: "Uzupełnij, aby podzielić się subskrypcją",
    required: "Pole e-mail jest wymagane!",
    shareToAdd: "Podziel się, aby dodać kolejną osobę",
};
