import { MarketingClientConstants } from "../constants/marketing-client-constants";
import { DOMHelper } from "../helpers/dom-helper";
import { Utils } from "./utils";
export class ErrorHandler {
    constructor(element) {
        this.dataErrorElementSelector = "data-error-selector";
        this.defaultMessage = "";
        this.element = element;
        this.errorElementSelector = element === null || element === void 0 ? void 0 : element.getAttribute(this.dataErrorElementSelector);
        this.errorElement = this.errorElementSelector ? DOMHelper.querySelector(`.${this.errorElementSelector}`) : null;
    }
    setMessage(message) {
        if (this.errorElement) {
            this.errorElement.innerHTML = (message || this.defaultMessage);
        }
    }
    ;
    show(message = "") {
        var _a;
        this.setMessage(message);
        Utils.showContainer(this.errorElement);
        (_a = this.element) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hasErrorClassName);
        return false;
    }
    hide() {
        var _a;
        Utils.hideContainer(this.errorElement);
        (_a = this.element) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hasErrorClassName);
        return true;
    }
}
