import { DOMHelper } from "./helpers/dom-helper";
import { MarketingClientDataLayer } from "./marketing-client-datalayer";
import { MarketingClientConstants } from "./constants/marketing-client-constants";
import { MarketingClientAttributesConstants } from "./constants/marketing-client-attributes-constants";
import { Utils } from "./utils/utils";
import { MarketingClientCustomDataLayerConstants } from "./constants/marketing-client-custom-data-layer-constants";
export class MarketingClientCustomDataLayer extends MarketingClientDataLayer {
    constructor() {
        super(...arguments);
        this.windowHeight = 0;
        this.dmElements = new Array();
    }
    init() {
        this.windowHeight = window.innerHeight;
        this.prepareDMElements();
        this.onDisplay();
        this.onScroll();
    }
    prepareDMElements() {
        DOMHelper.querySelectorAsArray(`[${MarketingClientConstants.placeholderDataSetAttribute}]`).forEach(domElement => {
            var _a;
            const element = domElement, templateId = (_a = element.getAttribute(MarketingClientConstants.placeholderDataSetAttribute)) !== null && _a !== void 0 ? _a : "", isSwimmerAttribute = element.getAttribute(MarketingClientAttributesConstants.isSwimmer), isDDBoxAttribute = element.getAttribute(MarketingClientAttributesConstants.isDDBox), isDDBox = Utils.stringNotEmpty(isDDBoxAttribute) && isDDBoxAttribute === "true", isSwimmer = Utils.stringNotEmpty(isSwimmerAttribute) && isSwimmerAttribute === "true", ctaObject = Utils.prepareCtaObject(element), collectionIdsObject = Utils.prepareCollectionIdsObject(element);
            if (!isDDBox) {
                this.dmElements.push({ element, templateId, isSwimmer, ctaObject, collectionIdsObject, visible: false });
            }
        });
    }
    onDisplay() {
        this.dmElements.forEach((dmElement) => {
            const dmElementIsSwimmerAndIsVisible = dmElement.isSwimmer
                && DOMHelper.elementIsVisible(dmElement.element)
                && dmElement.element.innerHTML !== "", dmElementIsVisibleByUser = DOMHelper.elementIsVisible(dmElement.element) && dmElement.element.offsetTop <= this.windowHeight, { cta = "", ctaCategory = "" } = dmElement.ctaObject;
            if (dmElementIsSwimmerAndIsVisible || dmElementIsVisibleByUser) {
                dmElement.visible = true;
                MarketingClientCustomDataLayer.sendDMEvent(ctaCategory, cta);
            }
        });
    }
    onScroll() {
        window.onscroll = this.sendEventOnScroll.bind(this);
    }
    sendEventOnScroll() {
        const scroll = window.scrollY || 0;
        this.dmElements.forEach(dmElement => {
            if (dmElement.element.offsetTop
                && scroll + this.windowHeight * 0.7 >= dmElement.element.offsetTop
                && !dmElement.visible
                && dmElement.element.innerHTML !== "") {
                const { cta = "", ctaCategory = "" } = dmElement.ctaObject;
                dmElement.visible = true;
                MarketingClientCustomDataLayer.sendDMEvent(ctaCategory, cta);
            }
        });
    }
    static sendDMEvent(category, message) {
        const customDataLayer = window[MarketingClientCustomDataLayerConstants.customDataLayer] || {};
        if (typeof customDataLayer.zajawkaDMEvent === "function") {
            customDataLayer.zajawkaDMEvent({
                category, action: MarketingClientCustomDataLayerConstants.displayAttribute, message
            });
        }
    }
}
export const marketingClientCustomDataLayer = new MarketingClientCustomDataLayer();
marketingClientCustomDataLayer.init();
