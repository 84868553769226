import { BigDataEventConstants } from "./constants/big-data-constants";
import { Selector } from "./constants/closing-without-lp";
import { EventType } from "./constants/event-type-constants";
import { BigDataHelper } from "./helpers/big-data-helper";
import { DOMHelper } from "./helpers/dom-helper";
import { BigData } from "./marketing-client-bigdata";
import { ClosingWithoutLp } from "./marketing-client-closing-without-lp";
import { LiveSession } from "./utils/livesession";
export class HardClosing extends ClosingWithoutLp {
    init() {
        this.initializeHardClosing();
        this.delayLoading();
        this.prepareAccordionHeaders(Selector.hardClosingAccordionHeader);
        this.toggleAccordionEvents(Selector.hardClosingAccordionArrow);
        if (this.isMobile) {
            this.initializeMobileScrollButton();
            this.initializeMobileActionButton();
            this.initializeMobileFixedButton();
            this.bindScrollOnClick(this.mobileScrollButton);
            this.bindScrollButtonInViewportEvent(this.mobileScrollButton);
        }
        else {
            this.initializeDesktopScrollButton();
            this.bindScrollOnClick(this.desktopScrollButton);
            this.bindScrollButtonInViewportEvent(this.desktopScrollButton);
        }
    }
    initializeHardClosing() {
        this.initializeSection();
        this.moveHardClosingOnTheScreen();
        this.initializeHeader();
        this.addDefaultClasses();
    }
    delayLoading() {
        var _a;
        const delayTime = ((_a = this.section) === null || _a === void 0 ? void 0 : _a.dataset.loadingTime) ? +this.section.dataset.loadingTime : 1;
        setTimeout(() => {
            var _a;
            (_a = this.section) === null || _a === void 0 ? void 0 : _a.classList.remove(Selector.hidden);
            this.initializeLiveSession();
            this.sendEvent();
        }, delayTime * 1000);
    }
    moveHardClosingOnTheScreen() {
        const element = DOMHelper.querySelector(Selector.placeholder);
        if (element) {
            const contentHeight = this.isMobile ? 440 : 370;
            const elDistanceToViewport = window.innerHeight - element.offsetTop;
            const pixelsToMove = -Math.abs(contentHeight - elDistanceToViewport);
            const marginValue = pixelsToMove.toString() + "px";
            this.section.style.marginTop = marginValue;
        }
    }
    initializeSection() {
        this.section = DOMHelper.querySelector(Selector.hardClosing);
    }
    initializeHeader() {
        this.header = DOMHelper.querySelector(Selector.hardClosingHeader);
    }
    initializeDesktopScrollButton() {
        this.desktopScrollButton = DOMHelper.querySelector(Selector.hardClosingDesktopScrollButton, this.section);
    }
    initializeMobileActionButton() {
        this.mobileActionButton = DOMHelper.querySelector(Selector.hardClosingMobileActionButton, this.section);
    }
    initializeMobileFixedButton() {
        this.mobileFixedActionButton = DOMHelper.querySelector(Selector.hardClosingMobileFixedButton);
    }
    initializeMobileScrollButton() {
        this.mobileScrollButton = DOMHelper.querySelector(Selector.hardClosingMobileScrollButton, this.section);
    }
    initializeLiveSession() {
        if (!this.section)
            return;
        new LiveSession(this.section).init();
    }
    addDefaultClasses() {
        var _a, _b;
        (_a = this.section) === null || _a === void 0 ? void 0 : _a.classList.add(Selector.closer);
        (_b = this.header) === null || _b === void 0 ? void 0 : _b.classList.add(Selector.sticky);
    }
    bindScrollOnClick(element) {
        element === null || element === void 0 ? void 0 : element.addEventListener(EventType.CLICK, () => {
            const elementToScrollTo = DOMHelper.querySelector(Selector.hardClosingConditionsOfPromotion, this.section);
            this.scrollToElement(elementToScrollTo);
        });
    }
    bindScrollButtonInViewportEvent(element) {
        const observer = new IntersectionObserver((entries, observer) => {
            entries[0].isIntersecting ? this.hideScrollButton(element) : this.showScrollButton(element);
            if (this.isMobile)
                entries[0].isIntersecting || entries[0].boundingClientRect.y < 0 ? this.showFixedButton() : this.showInlineButton();
        });
        const elementToObserve = DOMHelper.querySelector(Selector.hardClosingAccordionsContent, this.section);
        if (elementToObserve)
            observer.observe(elementToObserve);
    }
    hideScrollButton(element) {
        element === null || element === void 0 ? void 0 : element.classList.add(Selector.unvisible);
    }
    showScrollButton(element) {
        element === null || element === void 0 ? void 0 : element.classList.remove(Selector.unvisible);
    }
    showFixedButton() {
        var _a, _b;
        (_a = this.mobileActionButton) === null || _a === void 0 ? void 0 : _a.classList.add(Selector.hidden);
        (_b = this.mobileFixedActionButton) === null || _b === void 0 ? void 0 : _b.classList.remove(Selector.hidden);
    }
    showInlineButton() {
        var _a, _b;
        (_a = this.mobileActionButton) === null || _a === void 0 ? void 0 : _a.classList.remove(Selector.hidden);
        (_b = this.mobileFixedActionButton) === null || _b === void 0 ? void 0 : _b.classList.add(Selector.hidden);
    }
    scrollToElement(element) {
        element === null || element === void 0 ? void 0 : element.scrollIntoView(false);
    }
    sendEvent() {
        if (this.section) {
            const { cta, ctaCategory } = BigDataHelper.createAttribute(this.section, true), { adaptivePaywallRuleId } = window[Selector.marketingParams];
            const toSend = {
                cta: cta,
                ctaCategory: ctaCategory,
                ruleId: adaptivePaywallRuleId,
            };
            BigData.sendDataOceanEvent(BigDataEventConstants.onLoadingHardClosing, toSend);
        }
    }
}
