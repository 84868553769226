import { DOMHelper } from "./helpers/dom-helper";
import { EventType } from "./constants/event-type-constants";
import { ObjectConstants, Selector } from "./constants/closing-without-lp";
export class ClosingWithoutLp {
    constructor(isMobile) {
        this.accordionList = [];
        this.isMobile = isMobile;
    }
    init() {
        this.prepareAccordionHeaders(Selector.accordionHeader);
        this.toggleAccordionEvents(Selector.accordionArrow);
    }
    prepareAccordionHeaders(selector) {
        this.accordionList = DOMHelper.querySelectorAsArray(selector);
    }
    toggleAccordionEvents(arrowSelector) {
        this.accordionList.forEach((header) => {
            header.addEventListener(EventType.CLICK, () => {
                this.toggleAccordion(header, arrowSelector);
            });
        });
    }
    toggleAccordion(header, arrowSelector) {
        var _a;
        const accordionContent = header.nextElementSibling;
        accordionContent === null || accordionContent === void 0 ? void 0 : accordionContent.classList.toggle(ObjectConstants.active);
        (_a = header.querySelector(arrowSelector)) === null || _a === void 0 ? void 0 : _a.classList.toggle(ObjectConstants.active);
    }
}
