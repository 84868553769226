import { BigDataEventConstants } from "./constants/big-data-constants";
import { DdboxConstants } from "./constants/ddbox-constants";
import { EventType } from "./constants/event-type-constants";
import { DOMHelper } from "./helpers/dom-helper";
import { BigData } from "./marketing-client-bigdata";
import { MarketingClientCustomDataLayer } from "./marketing-client-custom-datalayer";
import { Utils } from "./utils/utils";
import { TimerCountdown } from "./helpers/timer-countdown";
import { DeviceInformationHelper, PageType } from "./helpers/device-information-helper";
import { SwimmerConstants } from "./constants/swimmer-constants";
import { OnboardingConstants } from "./constants/onboarding-constants";
export class Ddbox {
    constructor(isMobile, capping) {
        this.ddboxIsVisible = false;
        this.mouseleave = false;
        this.isZcp = false;
        this.isMobile = isMobile;
        this.capping = capping;
    }
    init() {
        var _a;
        const ddbox = DOMHelper.querySelector(DdboxConstants.class);
        if (ddbox === null || ddbox === void 0 ? void 0 : ddbox.parentElement) {
            this.collectionIdsObject = Utils.prepareCollectionIdsObject(ddbox.parentElement);
            this.ctaObject = Utils.prepareCtaObject(ddbox.parentElement);
            this.sendCapping();
            this.prepareDdbox(ddbox);
            this.initTimer((_a = ddbox === null || ddbox === void 0 ? void 0 : ddbox.dataset) === null || _a === void 0 ? void 0 : _a.timerDdbox);
        }
    }
    initTimer(dateEnd) {
        if (!dateEnd)
            return;
        this.timerCountdown = new TimerCountdown(dateEnd, DdboxConstants.timerSelector);
    }
    sendCapping(onDisplay = false) {
        const ctaObject = Object.assign({}, this.ctaObject);
        if (ctaObject === null || ctaObject === void 0 ? void 0 : ctaObject.customCtaCategory) {
            ctaObject.ctaCategory = ctaObject.customCtaCategory;
        }
        const attribute = BigData.prepareDataOceanAttribute(ctaObject, this.collectionIdsObject), eventName = onDisplay ? BigDataEventConstants.onDisplay : BigDataEventConstants.onLoad;
        this.capping.sendCapping(this.collectionIdsObject, onDisplay);
        BigData.sendDataOceanEvent(eventName, attribute);
    }
    prepareDdbox(box) {
        const isOnExitPopUp = box.dataset.onExitPopUp, isUserInactivityTimeout = box.dataset.userInactivityTimeout, isRw = box.dataset.ddboxWithRw, isZCP = box.dataset.ddboxWithZcp, isArticleScroll = box.dataset.articleScroll;
        this.isZcp = !!isZCP;
        box.style.display = "none";
        this.bindActions(box);
        this.ddboxIsVisible = true;
        if (this.isMobile) {
            if (EventType.ORIENTATION_CHANGE in window) {
                window.addEventListener(EventType.ORIENTATION_CHANGE, () => { this.shrinkIfTooBig(box); });
            }
            else {
                window.addEventListener(EventType.RESIZE, () => { this.shrinkIfTooBig(box); });
            }
        }
        if (isRw || isZCP) {
            return;
        }
        if (isOnExitPopUp && !this.isMobile) {
            this.onExitPopUp(box);
        }
        else if (isUserInactivityTimeout) {
            this.onUserInactivityTimeout(box);
        }
        else if (isArticleScroll) {
            this.onArticleScroll(box);
        }
        else {
            this.onDelay(box);
        }
    }
    onExitPopUp(box) {
        document.addEventListener(EventType.MOUSELEAVE, (event) => {
            if (event.clientY <= 0 && !this.mouseleave) {
                this.showDdbox(box);
                this.mouseleave = true;
            }
        });
    }
    onUserInactivityTimeout(box) {
        const attribute = box.dataset.userInactivityTimeoutValue, timeout = Utils.attributeToNumber(attribute), showDdboxAndSendCapping = () => {
            this.showDdbox(box);
        };
        Utils.showElementAfterTimeout(showDdboxAndSendCapping, timeout);
    }
    onArticleScroll(box) {
        var _a;
        const pageType = DeviceInformationHelper.getPageType(), delay = Number(box.dataset.articleScrollValue) || 0, hasVisited = JSON.parse((_a = localStorage.getItem(DdboxConstants.articleVisited)) !== null && _a !== void 0 ? _a : "false"), isOnboarding = !!DOMHelper.querySelector(OnboardingConstants.containerSelector);
        if (hasVisited || pageType !== PageType.ARTICLE) {
            this.showDdboxAfterDelay(box, delay);
            return;
        }
        if (isOnboarding) {
            localStorage.setItem(DdboxConstants.articleVisited, JSON.stringify(true));
        }
        this.observeArticleScroll(box, isOnboarding);
    }
    showDdboxAfterDelay(box, delay) {
        setTimeout(() => this.showDdbox(box), delay);
    }
    observeArticleScroll(box, isOnboarding) {
        var _a;
        const elementToObserve = DOMHelper.querySelector(`[${DdboxConstants.bdScrollPt}]`);
        const scrollValue = (_a = box.getAttribute(SwimmerConstants.dataArticleScrollValue)) !== null && _a !== void 0 ? _a : DdboxConstants.defaultMutationValue;
        const distance = isOnboarding ? 0 : DdboxConstants.defaultScrollUpDistance;
        if (!elementToObserve) {
            return;
        }
        const observer = new MutationObserver(Utils.showElementAfterMutation(() => Utils.detectScrollUp(this.showDdbox.bind(this, box), distance), DdboxConstants.mutationValue[scrollValue]));
        observer.observe(elementToObserve, {
            attributes: true,
            attributeFilter: [DdboxConstants.bdScrollPt],
            attributeOldValue: true,
        });
    }
    onDelay(box) {
        setTimeout(() => {
            this.showDdbox(box);
        }, DdboxConstants.delay);
    }
    showDdbox(box) {
        var _a, _b, _c;
        const target = document.body, isDynamicUtilization = (_b = (_a = box.parentElement) === null || _a === void 0 ? void 0 : _a.dataset.dynamicUtilization) !== null && _b !== void 0 ? _b : "", links = box.querySelectorAll(`a:not([href="#"])`), { campaignId = "", actionId = "", touchPointId = "" } = this.collectionIdsObject || {}, { cta = "", ctaCategory = "" } = this.ctaObject || {};
        box.dataset.campaignId = campaignId;
        box.dataset.actionId = actionId;
        box.dataset.touchPointId = touchPointId;
        box.dataset.dm = "true";
        target.append(box);
        if (isDynamicUtilization && links.length > 0) {
            links.forEach(link => {
                this.setHref(link, campaignId, actionId, touchPointId);
            });
        }
        if (this.isMobile) {
            this.shrinkIfTooBig(box);
            this.lockBody();
        }
        (_c = this.timerCountdown) === null || _c === void 0 ? void 0 : _c.init();
        box.style.display = "block";
        box.classList.add(DdboxConstants.visible);
        MarketingClientCustomDataLayer.sendDMEvent(ctaCategory, cta);
        this.sendCapping(true);
    }
    setHref(link, campaignId, actionId, touchPointId) {
        const href = link.getAttribute("href"), hasParameters = !!(href === null || href === void 0 ? void 0 : href.includes("?")), dmParams = `${href}${hasParameters ? "&" : "?"}campaignId=${campaignId}&actionId=${actionId}&touchPointId=${touchPointId}`;
        link.setAttribute("href", dmParams);
    }
    bindActions(box) {
        if (!this.isZcp) {
            const closeButtons = DOMHelper.querySelectorAsArray(DdboxConstants.closeButton, box);
            closeButtons.forEach(closeButton => {
                closeButton.addEventListener(EventType.CLICK, (event) => {
                    event.preventDefault();
                    this.closeDdbox(box);
                });
            });
        }
    }
    closeDdbox(box) {
        box.classList.remove(DdboxConstants.visible);
        if (this.isMobile) {
            this.unLockBody();
        }
        setTimeout(function () {
            box.remove();
        }, 300);
    }
    lockBody() {
        document.body.classList.add(DdboxConstants.locked);
    }
    unLockBody() {
        document.body.classList.remove(DdboxConstants.locked);
    }
    shrinkIfTooBig(box) {
        const image = box.querySelector("img");
        if (image) {
            if (image.complete) {
                this.resizeImage(image, box);
            }
            else {
                const tmpImage = new Image();
                tmpImage.onload = () => {
                    this.resizeImage(tmpImage, box);
                };
                tmpImage.src = image.src;
            }
        }
    }
    resizeImage(image, box) {
        const imageHeight = image.height, windowHeight = window.innerHeight;
        if (imageHeight > windowHeight) {
            box.classList.add(DdboxConstants.tooBig);
            image.style.height = windowHeight + "px";
        }
        else {
            box.classList.remove(DdboxConstants.tooBig);
            image.style.height = "auto";
        }
    }
}
