import { EventType } from "./constants/event-type-constants";
import { Browsers, MarketingClientConstants } from "./constants/marketing-client-constants";
import { ErrorHandler } from "./utils/error-handler-first-rw";
import { DOMHelper } from "./helpers/dom-helper";
import { httpInstance } from "./http/http";
import { MarketingClientRWBase } from "./marketing-client-rw-base";
import { Checkbox } from "./utils/checkbox";
import { Spinner } from "./utils/spinner";
import { Utils } from "./utils/utils";
import { BigDataClickID } from "./constants/big-data-constants";
import { RwAccountErrorCode, RwErrorMessage, RwSelector } from "./constants/rw-object.constants";
import { LiveSession } from "./utils/livesession";
import { AppleId } from "./utils/appleid";
export var LayerType;
(function (LayerType) {
    LayerType["MAIN"] = "main";
    LayerType["CONFIRMATION"] = "confirmation";
    LayerType["RESEND"] = "resend";
})(LayerType || (LayerType = {}));
export class MarketingClientRWFirstPromotion extends MarketingClientRWBase {
    constructor(capping, utilizationObject, classSection = RwSelector.rwFirstPromotionSelector) {
        super(capping, utilizationObject, classSection);
        this.capping = capping;
        this.utilizationObject = utilizationObject;
        this.classSection = classSection;
        this.socialList = [];
        this.socialType = null;
        this.apple = new AppleId();
        this.shouldInitializeAppleId = true;
        this.path = "/";
        this.idPrefixSelector = "rwFirstPromotion-";
        this.idSuffixForm = "form";
        this.idSuffixCheckboxA = "form-checkbox-A";
        this.idSuffixCheckboxB = "form-checkbox-B";
        this.classSuffix = {
            email: "_form-email",
            agreement: "_form-agreement",
            registrationAccountButton: "_form-button",
            registrationAccountError: "_form-create-account-error",
            emailInLayer: "_user-email",
            spinnerRegistrationAccount: "_form-spinner",
            spinnerResendEmail: "_email-confirmation-screen-spinner",
            headerDesktop: " .header-content-desktop",
            headerMobile: " .header-content-mobile",
            socialListIcon: "_form-social-list-icon",
            socialWrapper: "_form-social-wrapper",
            emailWrapper: "_form-email-wrapper",
            socialLogout: "_form-social_account-logout",
            socialEmail: "_form-social_account-email",
            helpContact: "_email-resend-screen_description-link",
            googleDetectedIcon: ".mc-browser-detected-google",
            appleDetectedIcon: ".mc-browser-detected-apple",
            googleIcon: ".mc-google-icon",
            appleIcon: ".mc-apple-icon",
        };
        this.messages = RwErrorMessage;
        this.agreementFormId = 91;
        this.locationLogin = "https://login.wyborcza.pl/";
        this.loginChanel = {
            "social-media-google": "GOOGLEPLUS",
            "social-media-fb": "FACEBOOK",
            "social-media-apple": "APPLEID",
            "WYBORCZA": "WYBORCZA"
        };
        this.offerButtonSelector = ".offer-button";
        this.bgColorClass = "standard-background";
    }
    init() {
        this.initialize();
        this.initializeForm();
        if (!this.section || !this.form || !this.email || !this.registrationAccountButton) {
            return;
        }
        this.initializeCheckbox();
        this.showDdbox();
        if (!this.ddbox)
            this.showSwimmer();
        this.showSection();
        this.getAgreementForm(this.agreementFormId);
        this.initializeHeader();
        this.initializeSpinner();
        this.initializeLayers();
        this.initializeResendEmail();
        this.initializeReset();
        this.initializeSocial();
        this.initializeSocialIcons();
        this.initializeOfferButton();
        this.initializeHelpContact();
        this.initializeLiveSession();
        this.bindEvents();
        this.bindSwimmerEvents();
        this.getUserEmail();
        this.detectBrowser();
        this.setIconsBasedOnBrowser();
    }
    initializeForm() {
        this.form = DOMHelper.getElementById(`${this.idPrefixSelector}${this.idSuffixForm}`);
        if (!this.form) {
            return;
        }
        this.email = DOMHelper.querySelector(`${this.classSection}${this.classSuffix.email}`, this.form);
        this.registrationAccountButton = DOMHelper.querySelector(`${this.classSection}${this.classSuffix.registrationAccountButton}`, this.form);
        if (!this.email) {
            return;
        }
        this.emailErrorHandler = new ErrorHandler(this.email);
        this.registrationAccountError = DOMHelper.querySelector(`${this.classSection}${this.classSuffix.registrationAccountError}`, this.form);
    }
    initializeHeader() {
        this.headerDesktop = DOMHelper.querySelector(`${this.classSection}${this.classSuffix.headerDesktop}`);
        this.headerMobile = DOMHelper.querySelector(`${this.classSection}${this.classSuffix.headerMobile}`);
    }
    initializeSpinner() {
        this.spinnerCreateAccount = new Spinner(`${this.classSection}${this.classSuffix.spinnerRegistrationAccount}`);
        this.spinnerResendEmail = new Spinner(`${this.classSection}${this.classSuffix.spinnerResendEmail}`);
    }
    initializeCheckbox() {
        this.checkboxA = new Checkbox(`${this.idPrefixSelector}${this.idSuffixCheckboxA}`);
        this.checkboxB = new Checkbox(`${this.idPrefixSelector}${this.idSuffixCheckboxB}`);
    }
    initializeLayers() {
        this.layers = {
            [LayerType.MAIN]: DOMHelper.getElementById(`${this.idPrefixSelector}${LayerType.MAIN}`),
            [LayerType.CONFIRMATION]: DOMHelper.getElementById(`${this.idPrefixSelector}${LayerType.CONFIRMATION}`),
            [LayerType.RESEND]: DOMHelper.getElementById(`${this.idPrefixSelector}${LayerType.RESEND}`)
        };
        this.showLayer();
    }
    initializeResendEmail() {
        this.resendEmailButton = DOMHelper.querySelector(".button", this.layers.confirmation);
        this.resendEmailError = DOMHelper.querySelector(".resend-email-error", this.layers.confirmation);
        this.resendEmailButtonInError = DOMHelper.querySelector(".resend-button", this.layers.confirmation);
    }
    initializeReset() {
        this.resetButton = DOMHelper.querySelector(".reset-button", this.layers.resend);
    }
    initializeSocial() {
        this.socialList = DOMHelper.querySelectorAsArray(`${this.classSection}${this.classSuffix.socialListIcon}`);
        this.socialWrapper = DOMHelper.querySelector(`${this.classSection}${this.classSuffix.socialWrapper}`);
        this.emailWrapper = DOMHelper.querySelector(`${this.classSection}${this.classSuffix.emailWrapper}`);
        this.socialLogout = DOMHelper.querySelector(`${this.classSection}${this.classSuffix.socialLogout}`);
        this.socialEmail = DOMHelper.querySelector(`${this.classSection}${this.classSuffix.socialEmail}`);
    }
    initializeOfferButton() {
        if (this.section) {
            this.offerButton = DOMHelper.querySelector(this.offerButtonSelector, this.section);
        }
    }
    initializeHelpContact() {
        if (this.section) {
            this.helpContact = DOMHelper.querySelector(`${this.classSection}${this.classSuffix.helpContact}`);
        }
    }
    initializeLiveSession() {
        if (!this.form)
            return;
        new LiveSession(this.form).init();
    }
    initializeSocialIcons() {
        this.googleDetectedIcon = DOMHelper.querySelector(this.classSuffix.googleDetectedIcon);
        this.appleDetectedIcon = DOMHelper.querySelector(this.classSuffix.appleDetectedIcon);
        this.googleIcon = DOMHelper.querySelector(this.classSuffix.googleIcon);
        this.appleIcon = DOMHelper.querySelector(this.classSuffix.appleIcon);
    }
    detectBrowser() {
        var _a, _b, _c, _d;
        this.browser = (_d = (_c = (_b = (_a = window["wyborcza_pl"]) === null || _a === void 0 ? void 0 : _a.MarketingClient) === null || _b === void 0 ? void 0 : _b.userData) === null || _c === void 0 ? void 0 : _c.profile) === null || _d === void 0 ? void 0 : _d.browser;
    }
    setIconsBasedOnBrowser() {
        var _a;
        if ((_a = this.browser) === null || _a === void 0 ? void 0 : _a.includes(Browsers.SAFARI)) {
            this.hideGoogleDetectedIcon();
            this.hideAppleIcon();
        }
        else {
            this.hideAppleDetectedIcon();
            this.hideGoogleIcon();
        }
    }
    hideGoogleDetectedIcon() {
        var _a;
        (_a = this.googleDetectedIcon) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
    }
    hideAppleDetectedIcon() {
        var _a;
        (_a = this.appleDetectedIcon) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
    }
    hideGoogleIcon() {
        var _a;
        (_a = this.googleIcon) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
    }
    hideAppleIcon() {
        var _a;
        (_a = this.appleIcon) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
    }
    bindEvents() {
        this.bindEventsToRegistrationAccount();
        this.bindEventsToEmailInput();
        this.bindEventsToResendEmail();
        this.bindEventsToResendEmailOnError();
        this.bindEventsToReset();
        this.bindEventsToSocialLogin();
        this.bindEventsToSocialLogout();
        this.bindEventsToBuyAnOffer();
        this.bindEventsToHelpContact();
    }
    bindEventsToSocialLogin() {
        if (this.socialList.length) {
            this.socialList.forEach(element => {
                element.addEventListener(EventType.CLICK, () => {
                    this.socialType = element.getAttribute("data-social");
                    this.utilize(this.createClickID(BigDataClickID.login), true);
                    this.login();
                });
            });
        }
    }
    bindEventsToSocialLogout() {
        if (this.socialLogout) {
            this.socialLogout.addEventListener(EventType.CLICK, () => {
                this.utilize(this.createClickID(BigDataClickID.logout), true);
                this.logout();
            });
        }
    }
    initializeAppleId() {
        if (this.shouldInitializeAppleId && this.socialType === SOCIAL.APPLEID) {
            this.apple.init();
            this.shouldInitializeAppleId = false;
        }
    }
    login() {
        this.initializeAppleId();
        if (this.socialType) {
            const tmp = this.socialType;
            window[this.socialType].login((email) => {
                this.socialType = tmp;
                this.onSuccessLogin(email);
                return;
            });
            this.clearSocialType();
        }
    }
    onSuccessLogin(email) {
        var _a;
        if (email && this.socialType) {
            (_a = this.socialWrapper) === null || _a === void 0 ? void 0 : _a.classList.add(this.socialType);
            this.setSocialEmail(email);
            this.hideEmailWrapper();
            this.showSocialWrapper();
        }
    }
    getUserEmail() {
        if (window[SOCIAL.GOOGLEPLUS]) {
            window[SOCIAL.GOOGLEPLUS].getUserEmail = () => {
                return window["gapi"].auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail();
            };
        }
    }
    userDisconnect() {
        var _a, _b, _c;
        if (window[SOCIAL.GOOGLEPLUS]) {
            (_c = (_b = (_a = window["gapi"]) === null || _a === void 0 ? void 0 : _a.auth2) === null || _b === void 0 ? void 0 : _b.getAuthInstance()) === null || _c === void 0 ? void 0 : _c.disconnect();
        }
    }
    logout() {
        if (this.socialType) {
            this.userDisconnect();
            window[this.socialType].logout();
            this.clearEmail();
            this.hideSocialWrapper();
            this.showEmailWrapper();
            this.clearSocialType();
        }
    }
    setSocialEmail(socialEmail) {
        if (this.socialEmail) {
            this.socialEmail.innerHTML = socialEmail;
        }
        if (this.email) {
            this.email.value = socialEmail;
        }
    }
    clearSocialType() {
        var _a;
        if (this.socialType) {
            (_a = this.socialWrapper) === null || _a === void 0 ? void 0 : _a.classList.remove(this.socialType);
            this.socialType = null;
        }
    }
    bindEventsToRegistrationAccount() {
        if (this.registrationAccountButton) {
            this.registrationAccountButton.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                const validateCheckboxes = this.validateCheckboxes();
                if (this.validateEmail() && validateCheckboxes) {
                    this.hideRegistrationAccountError();
                    this.spinnerCreateAccount.show();
                    this.utilize(this.createClickID(BigDataClickID.createAccount));
                    this.registrationAccount();
                }
            });
        }
    }
    validateCheckboxes() {
        this.checkboxA.toggle();
        this.checkboxB.toggle();
        return this.checkboxA.isValidate() && this.checkboxB.isValidate();
    }
    bindEventsToEmailInput() {
        if (this.email) {
            this.email.addEventListener(EventType.CHANGE, this.validateEmail.bind(this));
            this.email.addEventListener(EventType.INPUT, () => {
                var _a;
                return ((_a = this.email) === null || _a === void 0 ? void 0 : _a.value.length) ? this.emailErrorHandler.hide() : this.emailErrorHandler.show(this.messages.required);
            });
        }
    }
    bindEventsToResendEmail() {
        if (this.resendEmailButton) {
            this.resendEmailButton.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.spinnerResendEmail.show();
                this.utilize(BigDataClickID.resendEmail, true);
                this.resendEmail();
            });
        }
    }
    bindEventsToResendEmailOnError() {
        if (this.resendEmailButtonInError) {
            this.resendEmailButtonInError.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.spinnerResendEmail.show();
                this.utilize(BigDataClickID.resendEmail, true);
                this.resendEmail();
            });
        }
    }
    bindEventsToReset() {
        if (this.resetButton) {
            this.resetButton.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.utilize(BigDataClickID.startProcessAgain, true);
                this.reset();
            });
        }
    }
    bindEventsToBuyAnOffer() {
        if (this.offerButton) {
            this.offerButton.addEventListener(EventType.CLICK, () => {
                this.utilize(this.createClickID(BigDataClickID.purchaseSubscription), true);
            });
        }
    }
    bindEventsToHelpContact() {
        if (this.helpContact) {
            this.helpContact.addEventListener(EventType.CLICK, () => {
                this.utilize(BigDataClickID.helpContact, true);
            });
        }
    }
    clearEmail() {
        if (this.email) {
            this.email.value = "";
        }
    }
    reset() {
        var _a, _b;
        this.clearEmail();
        this.hideSocialWrapper();
        this.showEmailWrapper();
        this.clearSocialType();
        this.checkboxA.reset();
        this.checkboxB.reset();
        this.hideRegistrationAccountError();
        (_a = this.resendEmailError) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
        (_b = this.resendEmailButton) === null || _b === void 0 ? void 0 : _b.classList.remove(MarketingClientConstants.hiddenClassName);
        this.showHeader();
        this.showLayer(LayerType.MAIN);
    }
    validateEmail() {
        const value = DOMHelper.getEmailValue(this.email), emailIsValid = Utils.validateEmail(value);
        if (!value.length) {
            return this.emailErrorHandler.show(this.messages.required);
        }
        else if (!emailIsValid) {
            return this.emailErrorHandler.show(this.messages.banned);
        }
        else {
            return this.emailErrorHandler.hide();
        }
    }
    prepareDataToRegistrationAccount() {
        var _a, _b;
        const data = {
            email: DOMHelper.getEmailValue(this.email),
            articleUrl: window.location.href,
            fromUrl: window.location.href,
            testAB: "PASS",
            disableRedirects: "true",
            agreementA: this.agreementA.idAgreement.toString(),
            loginChanel: this.loginChanel[(_a = this.socialType) !== null && _a !== void 0 ? _a : "WYBORCZA"]
        };
        if ((_b = this.checkboxB.input) === null || _b === void 0 ? void 0 : _b.checked) {
            data.agreementB = this.agreementB.idAgreement.toString();
        }
        return new URLSearchParams(Object.assign({}, data));
    }
    registrationAccount() {
        return httpInstance
            .post({
            url: `${this.path}regwall/account`,
            data: this.prepareDataToRegistrationAccount(),
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "*/*"
            }
        })
            .then(this.successRegistrationAccount.bind(this), this.errorRegistrationAccount.bind(this))
            .then(() => {
            this.spinnerCreateAccount.hide();
        });
    }
    successRegistrationAccount(response) {
        if (response.data && !response.data.error) {
            this.hideHeader();
            this.setEmailInLayer();
            this.sendBigDataDisplay(BigDataClickID.confirmationEmailAddress);
            this.setStandardBackground();
            this.showLayer(LayerType.CONFIRMATION);
            this.scrollToElement();
        }
    }
    errorRegistrationAccount(error) {
        const data = error === null || error === void 0 ? void 0 : error.data, message = data === null || data === void 0 ? void 0 : data.message, errorHasMessage = data && message;
        if (errorHasMessage && message === RwAccountErrorCode.accountExists) {
            this.emailErrorHandler.show(this.messages.accountExists);
            this.goToLogin();
        }
        else if (errorHasMessage && message === RwAccountErrorCode.accountUnconfirmed) {
            this.bindEventsToResendEmailOnAccountUnconfirmed();
        }
        else {
            this.showRegistrationAccountError();
        }
    }
    bindEventsToResendEmailOnAccountUnconfirmed() {
        this.emailErrorHandler.show(this.messages.accountUnconfirmed);
        if (this.emailErrorHandler.errorElement) {
            const tagA = DOMHelper.querySelector("a", this.emailErrorHandler.errorElement);
            tagA === null || tagA === void 0 ? void 0 : tagA.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.hideHeader();
                this.setEmailInLayer();
                this.resendEmail();
            });
        }
    }
    goToLogin() {
        window.location.href = this.locationLogin;
    }
    resendEmail() {
        const data = {
            email: DOMHelper.getEmailValue(this.email)
        };
        return httpInstance
            .post({
            url: `${this.path}regwall/resendConfEmailForRegWall`,
            data: new URLSearchParams(data),
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "*/*"
            }
        })
            .then(this.successResendEmail.bind(this), this.errorResendEmail.bind(this))
            .then(() => {
            this.spinnerResendEmail.hide();
        });
    }
    successResendEmail() {
        this.sendBigDataDisplay(BigDataClickID.checkMailbox);
        this.showLayer(LayerType.RESEND);
    }
    errorResendEmail() {
        this.hideResendEmailButton();
        this.showResendEmailError();
    }
    getAgreementForm(formId) {
        return httpInstance
            .get({
            url: `${this.squidHomeUrl}squid-home/open/accounts/agreements?formId=${formId}`
        })
            .then(this.successResponseAgreementForm.bind(this));
    }
    successResponseAgreementForm(response) {
        var _a, _b;
        if (((_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.agreements) === null || _b === void 0 ? void 0 : _b.length) === 2) {
            this.agreementA = response.data.agreements[0];
            this.agreementB = response.data.agreements[1];
            this.setAgreementText();
        }
        else {
            console.warn("Incorrect number of agreements!");
            this.closeDdbox();
            this.hideSwimmer();
            this.hideSection();
        }
    }
    setEmailInLayer() {
        const emailInConfirmation = DOMHelper.querySelector(`${this.classSection}${this.classSuffix.emailInLayer}`, this.layers.confirmation), emailInResend = DOMHelper.querySelector(`${this.classSection}${this.classSuffix.emailInLayer}`, this.layers.resend), email = DOMHelper.getEmailValue(this.email);
        if (emailInConfirmation) {
            emailInConfirmation.innerHTML = email;
        }
        if (emailInResend) {
            emailInResend.innerHTML = email;
        }
    }
    createClickID(prefixOfId) {
        let suffixOfId;
        switch (this.socialType) {
            case SOCIAL.GOOGLEPLUS:
                suffixOfId = " Google";
                break;
            case SOCIAL.FACEBOOK:
                suffixOfId = " FB";
                break;
            case SOCIAL.APPLEID:
                suffixOfId = " Apple";
                break;
            default:
                suffixOfId = "";
        }
        return prefixOfId + suffixOfId;
    }
    setAgreementText() {
        this.checkboxA.setText(this.agreementA.agreementText);
        this.checkboxB.setText(this.agreementB.agreementText);
    }
    showLayer(layer = LayerType.MAIN) {
        Object.values(this.layers).forEach(layer => {
            layer.classList.add(MarketingClientConstants.hiddenClassName);
        });
        this.layers[layer].classList.remove(MarketingClientConstants.hiddenClassName);
    }
    hideHeader() {
        var _a, _b;
        (_a = this.headerDesktop) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
        (_b = this.headerMobile) === null || _b === void 0 ? void 0 : _b.classList.add(MarketingClientConstants.hiddenClassName);
    }
    showHeader() {
        var _a, _b;
        (_a = this.headerDesktop) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hiddenClassName);
        (_b = this.headerMobile) === null || _b === void 0 ? void 0 : _b.classList.remove(MarketingClientConstants.hiddenClassName);
    }
    showRegistrationAccountError() {
        var _a;
        (_a = this.registrationAccountError) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hiddenClassName);
    }
    hideRegistrationAccountError() {
        var _a;
        (_a = this.registrationAccountError) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
    }
    showResendEmailButton() {
        var _a;
        (_a = this.resendEmailButton) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hiddenClassName);
    }
    hideResendEmailButton() {
        var _a;
        (_a = this.resendEmailButton) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
    }
    showResendEmailError() {
        var _a;
        (_a = this.resendEmailError) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hiddenClassName);
    }
    hideResendEmailError() {
        var _a;
        (_a = this.resendEmailError) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
    }
    showEmailWrapper() {
        var _a;
        (_a = this.emailWrapper) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hiddenClassName);
    }
    hideEmailWrapper() {
        var _a;
        (_a = this.emailWrapper) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
    }
    showSocialWrapper() {
        var _a;
        (_a = this.socialWrapper) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hiddenClassName);
    }
    hideSocialWrapper() {
        var _a;
        (_a = this.socialWrapper) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
    }
    setStandardBackground() {
        var _a;
        (_a = this.section) === null || _a === void 0 ? void 0 : _a.classList.add(this.bgColorClass);
    }
}
;
export var SOCIAL;
(function (SOCIAL) {
    SOCIAL["GOOGLEPLUS"] = "social-media-google";
    SOCIAL["FACEBOOK"] = "social-media-fb";
    SOCIAL["APPLEID"] = "social-media-apple";
})(SOCIAL || (SOCIAL = {}));
