var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DOMHelper } from "./helpers/dom-helper";
import { MarketingClientConstants } from "./constants/marketing-client-constants";
import { ErrorHandler } from "./utils/error-handler-first-rw";
import { Utils } from "./utils/utils";
import { EventType } from "./constants/event-type-constants";
import { httpInstance } from "./http/http";
import { NEXT_STEP, OnboardingConstants, PREV_STEP, SubShareOnboardingConst, SubShareOnboardingErrorMessage, SubShareOnboardingSelector } from "./constants/onboarding-constants";
export class SubscriptionSharing {
    constructor(onboarding) {
        this.messages = SubShareOnboardingErrorMessage;
        this.isHarmonyTest = Utils.getURLParamsAsObject().enableHarmonyTest;
        this.path = this.isHarmonyTest
            ? "https://coreos07.wyborcza.pl:8094/squid-home/restricted/customers/familyV2"
            : "https://squid-api.wyborcza.pl/squid-home/restricted/customers/familyV2";
        this.orderId = null;
        this.giftSubscriptionURL = this.isHarmonyTest
            ? "https://coreos07.wyborcza.pl:8094/squid-home/restricted/customers/family/add-member"
            : "https://squid-api.wyborcza.pl/squid-home/restricted/customers/family/add-member";
        this.invitationSent = false;
        this.prevButton = DOMHelper.querySelector(SubShareOnboardingSelector.prevBtnSelector);
        this.nextButton = DOMHelper.querySelector(SubShareOnboardingSelector.nextBtnSelector);
        this.finishButton = DOMHelper.querySelector(SubShareOnboardingSelector.finishButtonSelector);
        this.prevStepEvent = new Event(PREV_STEP);
        this.nextStepEvent = new Event(NEXT_STEP);
        this.onboarding = onboarding;
    }
    init() {
        this.initializeDom();
        this.hideEmailContainers();
        this.hideAddButton();
        this.getFamilyData();
        this.bindEvents();
        this.bindFinishEvent();
    }
    initializeDom() {
        this.initializeSharesub();
        this.initializeDescriptions();
        this.initializeEmailInputs();
        this.initializeGiftButtons();
        this.initializeEmailContainers();
        this.initializeAddButton();
    }
    initializeSharesub() {
        this.sharesub = DOMHelper.querySelector(SubShareOnboardingSelector.sharesubSelector);
    }
    initializeDescriptions() {
        this.description = DOMHelper.querySelector(SubShareOnboardingSelector.descriptionSelector);
        this.sentTwoInvitesDesc = DOMHelper.querySelector(SubShareOnboardingSelector.sentTwoInvitesDescSelector);
    }
    initializeEmailInputs() {
        this.firstEmailInput = DOMHelper.querySelector(SubShareOnboardingSelector.firstEmailSelector, this.sharesub);
        this.secondEmailInput = DOMHelper.querySelector(SubShareOnboardingSelector.secondEmailSelector, this.sharesub);
        this.firstEmailErrorHandler = new ErrorHandler(this.firstEmailInput);
        this.secondEmailErrorHandler = new ErrorHandler(this.secondEmailInput);
    }
    initializeGiftButtons() {
        this.firstGiftButton = DOMHelper.querySelector(SubShareOnboardingSelector.firstGiftButtonSelector, this.sharesub);
        this.secondGiftButton = DOMHelper.querySelector(SubShareOnboardingSelector.secondGiftButtonSelector, this.sharesub);
    }
    initializeEmailContainers() {
        this.firstEmailContainer = DOMHelper.querySelector(SubShareOnboardingSelector.firstEmailContainerSelector);
        this.secondEmailContainer = DOMHelper.querySelector(SubShareOnboardingSelector.secondEmailContainerSelector);
        this.emailContainers = DOMHelper.querySelectorAsArray(SubShareOnboardingSelector.emailContainerSelectors);
    }
    initializeAddButton() {
        this.addButton = DOMHelper.querySelector(SubShareOnboardingSelector.addButtonSelector);
    }
    hideEmailContainers() {
        this.emailContainers.forEach((container) => {
            if (container) {
                container.classList.add(MarketingClientConstants.hiddenClassName);
            }
        });
    }
    hideAddButton() {
        if (this.addButton)
            this.addButton.classList.add(MarketingClientConstants.hiddenClassName);
    }
    getFamilyData() {
        return httpInstance
            .get({
            url: this.path,
            withCredentials: true
        })
            .then(this.successResponseFamilyData.bind(this));
    }
    successResponseFamilyData(response) {
        var _a, _b;
        const data = response.data;
        const numberOfRegistered = (_a = data === null || data === void 0 ? void 0 : data.registered) === null || _a === void 0 ? void 0 : _a.length;
        const numberOfInvited = (_b = data === null || data === void 0 ? void 0 : data.invited) === null || _b === void 0 ? void 0 : _b.length;
        this.orderId = data === null || data === void 0 ? void 0 : data.orderId;
        if (numberOfRegistered + numberOfInvited === 0) {
            this.showFirstEmailContainer();
            this.showSecondEmailContainer();
            this.showAddButton();
            return;
        }
        if (numberOfRegistered + numberOfInvited === 1) {
            this.showFirstEmailContainer();
            return;
        }
        this.showDescription();
    }
    showFirstEmailContainer() {
        if (this.firstEmailContainer) {
            this.firstEmailContainer.classList.remove(MarketingClientConstants.hiddenClassName);
        }
    }
    showSecondEmailContainer() {
        if (this.secondEmailContainer) {
            this.secondEmailContainer.classList.add(SubShareOnboardingConst.visibleDesktop);
        }
    }
    showAddButton() {
        if (this.addButton)
            this.addButton.classList.remove(MarketingClientConstants.hiddenClassName);
    }
    showDescription() {
        if (this.description) {
            this.description.classList.add(MarketingClientConstants.hiddenClassName);
        }
        if (this.sentTwoInvitesDesc) {
            this.sentTwoInvitesDesc.classList.remove(MarketingClientConstants.hiddenClassName);
        }
    }
    bindEvents() {
        this.bindEventToAddButton();
        this.bindEventsToGiftButtons();
        this.bindEventsToEmailInputs();
        this.bindNavigationEvents();
    }
    bindEventToAddButton() {
        if (this.addButton)
            this.addButton.addEventListener(EventType.CLICK, this.showSecondInput.bind(this));
    }
    bindEventsToGiftButtons() {
        this.bindEventToElement(this.firstGiftButton, this.firstEmailInput, this.firstEmailErrorHandler);
        this.bindEventToElement(this.secondGiftButton, this.secondEmailInput, this.secondEmailErrorHandler);
    }
    bindEventsToEmailInputs() {
        this.bindEventToInput(this.firstEmailInput, this.firstEmailErrorHandler);
        this.bindEventToInput(this.secondEmailInput, this.secondEmailErrorHandler);
    }
    bindNavigationEvents() {
        var _a, _b;
        (_a = this.nextButton) === null || _a === void 0 ? void 0 : _a.addEventListener(EventType.CLICK, () => {
            this.onboarding.sendBigDataEvent(OnboardingConstants.nextStepEvent);
            window.dispatchEvent(this.nextStepEvent);
        });
        (_b = this.prevButton) === null || _b === void 0 ? void 0 : _b.addEventListener(EventType.CLICK, () => {
            this.onboarding.sendBigDataEvent(OnboardingConstants.prevStepEvent);
            window.dispatchEvent(this.prevStepEvent);
        });
    }
    bindEventToElement(button, input, errorHandler) {
        if (button) {
            button.addEventListener(EventType.CLICK, (event) => {
                this.onboarding.sendBigDataEvent(OnboardingConstants.shareSubEvent);
                button.setAttribute(MarketingClientConstants.disabledClassName, "");
                event.preventDefault();
                errorHandler.hide();
                if (this.validateEmail(input, errorHandler)) {
                    this.giftSubscription(button, input);
                }
                else {
                    button.removeAttribute(MarketingClientConstants.disabledClassName);
                }
            });
        }
    }
    bindEventToInput(input, errorHandler) {
        if (input) {
            input.addEventListener(EventType.CHANGE, this.validateEmail.bind(this, input, errorHandler));
            input.addEventListener(EventType.INPUT, () => {
                return (input === null || input === void 0 ? void 0 : input.value.length) ? errorHandler.hide() : errorHandler.show(this.messages.fillToShare);
            });
        }
    }
    showSecondInput() {
        if (!this.invitationSent) {
            this.showInvitationNotSentError();
            return;
        }
        this.hideFirstEmailInput();
        this.showSecondEmailInput();
        this.disableAddButton();
    }
    showInvitationNotSentError() {
        this.firstEmailErrorHandler.show(this.messages.shareToAdd);
    }
    hideFirstEmailInput() {
        if (this.firstEmailContainer) {
            this.firstEmailContainer.classList.add(MarketingClientConstants.hiddenClassName);
        }
    }
    showSecondEmailInput() {
        if (this.secondEmailContainer) {
            this.secondEmailContainer.classList.remove(MarketingClientConstants.hiddenClassName);
            this.secondEmailContainer.classList.add(SubShareOnboardingConst.visible);
            this.secondEmailInput.placeholder = SubShareOnboardingConst.enterSecondEmail;
        }
    }
    disableAddButton() {
        if (this.addButton) {
            this.addButton.classList.add(MarketingClientConstants.disabledClassName);
        }
    }
    validateEmail(input, errorHandler) {
        const value = input ? input.value : "", emailIsValid = Utils.validateEmail(value);
        if (!value.length) {
            return errorHandler.show(this.messages.fillToShare);
        }
        else if (!emailIsValid) {
            return errorHandler.show(this.messages.banned);
        }
        else {
            return errorHandler.hide();
        }
    }
    giftSubscription(button, input) {
        return __awaiter(this, void 0, void 0, function* () {
            const email = input.value;
            const headers = { "Content-Type": "application/json" };
            const data = {
                email: email,
                orderId: this.orderId
            };
            return httpInstance
                .put({
                url: this.giftSubscriptionURL,
                data,
                headers,
                withCredentials: true
            })
                .then(this.successGiftSubscription.bind(this, button, input));
        });
    }
    successGiftSubscription(button, input) {
        button.textContent = SubShareOnboardingConst.invitationSent;
        input.disabled = true;
        this.invitationSent = true;
    }
    bindFinishEvent() {
        var _a;
        (_a = this.finishButton) === null || _a === void 0 ? void 0 : _a.addEventListener(EventType.CLICK, () => {
            this.onboarding.finishOnboarding();
        });
    }
}
