export const URLBase = {
    TEST: "https://coreos05.wyborcza.pl:9092/",
    PROD: "/"
};
export const RequestPaths = {
    TEMPLATE: "marketing-proxy/template/v2",
    AGREEMENT: "marketing-proxy/agreement"
};
export const SquidURLs = {
    https: "https://squid.gazeta.pl",
    http: "http://squid.gazeta.pl"
};
