import { MarketingClientSWFBase } from "./marketing-client-swf-base";
import { MarketingClientConstants } from "./constants/marketing-client-constants";
import { EventType } from "./constants/event-type-constants";
import { DOMHelper } from "./helpers/dom-helper";
import { LayerType } from "./helpers/swf-helper";
export class MarketingClientShareArticle extends MarketingClientSWFBase {
    constructor(utilizationObject) {
        super(utilizationObject);
        this.utilizationObject = utilizationObject;
        this.shareArticleLayersId = "shareArticleLayers";
        this.modalBoxClass = ".nw_2021_modal-box";
        this.modalButtonClass = ".button-wrapper button";
    }
    init() {
        this.initialize();
        this.appendLayersToBody();
        this.bindEvents();
    }
    appendLayersToBody() {
        const shareArticleLayers = DOMHelper.getElementById(this.shareArticleLayersId);
        if (!shareArticleLayers)
            return;
        document.body.appendChild(shareArticleLayers);
    }
    bindEvents() {
        this.bindEventsToModals();
        this.bindEventsToFormSubmit();
        this.bindEventsToEmailInput();
    }
    bindEventsToModals() {
        if (!this.form)
            return;
        DOMHelper.querySelectorAsArray(this.modalButtonClass).forEach((modalButton) => {
            modalButton === null || modalButton === void 0 ? void 0 : modalButton.addEventListener(EventType.CLICK, () => {
                if (this.form) {
                    this.form.reset();
                }
                this.errorHandler.hide();
                this.showLayer(LayerType.DEFAULT);
            });
        });
        window.addEventListener(EventType.CLICK, ({ target }) => {
            const elementClassList = Array.from(target === null || target === void 0 ? void 0 : target.classList);
            const isModalBox = elementClassList.includes(this.modalBoxClass.replace(".", ""));
            if (!isModalBox)
                return;
            this.hideLayers();
        });
    }
    showLayer(layer = LayerType.DEFAULT) {
        var _a;
        DOMHelper.querySelectorAsArray(this.modalBoxClass).forEach((modal) => {
            modal.classList.add(MarketingClientConstants.hiddenClassName);
        });
        (_a = this.layers[layer]) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hiddenClassName);
    }
    hideLayers() {
        DOMHelper.querySelectorAsArray(this.modalBoxClass).forEach((modal) => {
            modal.classList.add(MarketingClientConstants.hiddenClassName);
        });
    }
    successResponseHandler(response) {
        if (response.data.status) {
            this.showLayer(LayerType.SUCCESS);
        }
        else if (response.data.result === LayerType.BLOCKED.toUpperCase()) {
            this.showLayer(LayerType.BLOCKED);
        }
    }
    errorResponseHandler() {
        this.showLayer(LayerType.ERROR);
    }
    scrollToLayer(layer) {
        let layerTop = layer.offsetTop, cap = DOMHelper.querySelector(this.wyborczaCapClass), capHeight = (cap === null || cap === void 0 ? void 0 : cap.offsetHeight) || 0, scrollTop = window.scrollY + capHeight;
        if (layerTop < scrollTop) {
            window.scrollTo({
                top: layerTop - capHeight - 20,
                behavior: "smooth"
            });
        }
    }
}
