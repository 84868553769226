export const MarketingClientConstants = {
    placeholderClass: "mcBan",
    rootElement: "body",
    allowHarmonyTestingUrlParam: "enableHarmonyTest",
    allowHarmonyPreProductionUrlParam: "enableHarmonyPreProd",
    referrerParam: "referrer",
    refererParam: "referer",
    overwriteProfileUrlParam: "profileToOverwrite",
    dmParametersGlobalKey: "domain_marketing_params",
    dmBehavioralProfilesKey: "marketingDomainRequestParams",
    encodeHarmonyProfileHelperKey: "encodeHarmonyProfile",
    decodeHarmonyProfileHelperKey: "decodeHarmonyProfile",
    placeholderDataSetAttribute: "data-mc-placeholder-id",
    ddboxTemplateId: "17632443",
    plywakTemplateId: "17632437",
    hiddenClassName: "hidden",
    disabledClassName: "disabled",
    dHiddenClassName: "d-hidden",
    hasErrorClassName: "has-error",
    placeholderList: "domain_marketing",
    stopScrolling: "stop-scrolling",
    optanonAlertBoxClosed: "OptanonAlertBoxClosed=",
    noguid: "NOGUID",
};
export const OperatingSystems = {
    ANDROID: "ANDROID",
    IPHONE_OS: "IPHONE_OS"
};
export const Browsers = {
    CHROME: "Chrome",
    SAFARI: "Safari"
};
export const ConsentCookie = ["C0004", "V2STACK42"];
