const TimerConstants = {
    winterOffset: "01:00",
    summerOffset: "02:00",
};
export class TimerCountdown {
    constructor(endDate, selector) {
        this.element = null;
        this.endDate = endDate;
        this.selector = selector;
    }
    init() {
        this.initDomElement();
        this.startTimer();
        this.interval = setInterval(() => { this.startTimer(); }, 1000);
    }
    initDomElement() {
        this.element = document.querySelector(this.selector);
    }
    startTimer() {
        const { hours, minutes, seconds, theEnd } = this.getTimeUntil(this.endDate);
        if (theEnd)
            this.stopTimer();
        if (!this.element)
            return;
        this.element.innerHTML = `${hours}:${minutes}:${seconds}`;
    }
    stopTimer() {
        clearInterval(this.interval);
    }
    isWinterTime(today = new Date()) {
        const currentYear = today.getFullYear(), marchIndex = 2, octoberIndex = 9, lastSundayOfOctober = this.getLastSundayOfMonth(currentYear, octoberIndex + 1), lastSundayOfMarch = this.getLastSundayOfMonth(currentYear, marchIndex + 1);
        // Winter time starts in Central Europe on the last Sunday in October at 3:00 AM
        // Winter time ends in Central Europe on the last Sunday in March at 2:00 am
        const winterStart = new Date(currentYear, octoberIndex, lastSundayOfOctober, 3), winterEnd = new Date(currentYear, marchIndex, lastSundayOfMarch, 2);
        return (today >= winterStart || today < winterEnd);
    }
    getLastSundayOfMonth(year, month) {
        const date = new Date(year, month, 0);
        date.setDate(date.getDate() - date.getDay());
        return date.getDate();
    }
    getTimeUntil(date) {
        const offset = this.isWinterTime() ? TimerConstants.winterOffset : TimerConstants.summerOffset, endTime = new Date(new Date(`${date}+${offset}`).toUTCString()).getTime(), userTime = new Date().getTime();
        const timeRemaining = endTime - userTime; // remaining time in milliseconds
        if (timeRemaining <= 0)
            return { hours: '00', minutes: '00', seconds: '00', theEnd: true };
        const hours = Math.floor(timeRemaining / (1000 * 60 * 60)).toString().padStart(2, '0'), minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0'), seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000).toString().padStart(2, '0');
        return { hours, minutes, seconds, theEnd: false };
    }
}
