export const NewsletterSaverConstants = {
    newsSaverClass: ".mc_news_saver",
    emailClass: ".mc_news_email",
    moreButtonClass: ".mc_news_more-button",
    agreementTextClass: ".mc_news_agreement",
    buttonWrapperClass: ".mc_news_button",
    less: "Mniej",
    more: "Więcej",
    lineClamps: "moreLineClamps",
    step1Class: ".mc_news_step-1",
    step2Class: ".mc_news_step-2",
    step2EmailClass: ".mc_news_step-2-email"
};
