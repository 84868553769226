var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BigDataEventConstants } from "./constants/big-data-constants";
import { EventType } from "./constants/event-type-constants";
import { MarketingClientConstants } from "./constants/marketing-client-constants";
import { NewsSwimmerConstants } from "./constants/news-swimmer-constants";
import { NewsletterSaverConstants } from "./constants/newsletter-saver-constants";
import { RwErrorMessage } from "./constants/rw-object.constants";
import { BigDataHelper } from "./helpers/big-data-helper";
import { DeviceInformationHelper, DeviceType } from "./helpers/device-information-helper";
import { DOMHelper } from "./helpers/dom-helper";
import { httpInstance } from "./http/http";
import { WYBORCZA_PL } from "./marketing-client";
import { BigData } from "./marketing-client-bigdata";
import { ErrorHandler } from "./utils/error-handler-first-rw";
import { Utils } from "./utils/utils";
export class NewsletterSaver {
    constructor(capping, utilizationObject) {
        this.capping = capping;
        this.utilizationObject = utilizationObject;
        this.agreementsExtended = false;
        this.messages = RwErrorMessage;
        this.isHarmonyTest = Utils.getURLParamsAsObject().enableHarmonyTest;
        this.path = this.isHarmonyTest
            ? "https://coreos07.wyborcza.pl:8094/squid-news/profiles"
            : "https://squid-api.wyborcza.pl/squid-news/profiles";
        this.isMobile = DeviceInformationHelper.getDeviceType() === DeviceType.MOBILE;
    }
    init() {
        this.initializeNewsletterSaver();
        this.initializeNewsletterId();
        this.initializeEmailInput();
        this.initializeButtons();
        this.initializeParent();
        this.bindEvents();
    }
    initializeNewsletterSaver() {
        this.newsletterSaver = DOMHelper.querySelector(NewsletterSaverConstants.newsSaverClass);
    }
    initializeNewsletterId() {
        var _a, _b;
        if ((_b = (_a = this.newsletterSaver) === null || _a === void 0 ? void 0 : _a.dataset) === null || _b === void 0 ? void 0 : _b.newsletterId)
            this.newsletterId = this.newsletterSaver.dataset.newsletterId;
    }
    initializeEmailInput() {
        var _a;
        this.emailInput = DOMHelper.querySelector(NewsletterSaverConstants.emailClass, this.newsletterSaver);
        this.emailErrorHandler = new ErrorHandler(this.emailInput);
        if (((_a = WYBORCZA_PL === null || WYBORCZA_PL === void 0 ? void 0 : WYBORCZA_PL.userInfo) === null || _a === void 0 ? void 0 : _a.loggedIn) && this.emailInput)
            this.emailInput.value = WYBORCZA_PL.userInfo.email;
    }
    initializeButtons() {
        this.agreementsExtendButtons = DOMHelper.querySelectorAsArray(NewsletterSaverConstants.moreButtonClass, this.newsletterSaver);
        this.subscribeToNewsletterButton = DOMHelper.querySelector(NewsletterSaverConstants.buttonWrapperClass, this.newsletterSaver);
    }
    initializeParent() {
        var _a;
        this.parent = (_a = this.newsletterSaver) === null || _a === void 0 ? void 0 : _a.closest(`[${MarketingClientConstants.placeholderDataSetAttribute}]`);
    }
    bindEvents() {
        this.bindEventsToNewsletterSubscription();
        this.bindEventsToEmailInput();
        this.bindEventsToAgreementsExtendButton();
    }
    bindEventsToNewsletterSubscription() {
        if (this.subscribeToNewsletterButton) {
            this.subscribeToNewsletterButton.addEventListener(EventType.CLICK, (event) => {
                this.subscribeToNewsletterButton.setAttribute(MarketingClientConstants.disabledClassName, "");
                event.preventDefault();
                this.emailErrorHandler.hide();
                if (this.validateEmail()) {
                    this.utilize();
                    this.subscribe();
                }
                else {
                    this.subscribeToNewsletterButton.removeAttribute(MarketingClientConstants.disabledClassName);
                }
            });
        }
    }
    bindEventsToEmailInput() {
        if (this.emailInput) {
            this.emailInput.addEventListener(EventType.CHANGE, this.validateEmail.bind(this));
            this.emailInput.addEventListener(EventType.INPUT, () => {
                var _a;
                return ((_a = this.emailInput) === null || _a === void 0 ? void 0 : _a.value.length)
                    ? this.emailErrorHandler.hide()
                    : this.emailErrorHandler.show(this.messages.required);
            });
        }
    }
    bindEventsToAgreementsExtendButton() {
        this.agreementsExtendButtons.forEach(button => {
            button.addEventListener(EventType.CLICK, (event) => {
                this.agreementsExtended = !this.agreementsExtended;
                this.toggleAgreements(button);
            });
        });
    }
    toggleAgreements(button) {
        button.textContent = this.agreementsExtended ? NewsletterSaverConstants.less : NewsletterSaverConstants.more;
        const agreementTextFields = DOMHelper.querySelectorAsArray(NewsletterSaverConstants.agreementTextClass, this.newsletterSaver);
        agreementTextFields.forEach((agreementTextField) => {
            this.agreementsExtended ? agreementTextField.classList.add(NewsletterSaverConstants.lineClamps) : agreementTextField.classList.remove(NewsletterSaverConstants.lineClamps);
        });
    }
    validateEmail() {
        const value = DOMHelper.getEmailValue(this.emailInput), emailIsValid = Utils.validateEmail(value);
        if (!value.length) {
            return this.emailErrorHandler.show(this.messages.required);
        }
        else if (!emailIsValid) {
            return this.emailErrorHandler.show(this.messages.banned);
        }
        else {
            return this.emailErrorHandler.hide();
        }
    }
    utilize() {
        return __awaiter(this, arguments, void 0, function* (clickID = "", onlyBigData = false) {
            var _a, _b;
            const condition = (_b = (_a = this.newsletterSaver) === null || _a === void 0 ? void 0 : _a.dataset.utilization) !== null && _b !== void 0 ? _b : "brak";
            if (this.parent) {
                const dataToSend = this.utilizationObject.prepareDataToSend(this.parent, condition), attribute = BigDataHelper.createAttribute(this.parent, true, clickID);
                if (this.newsletterSaver) {
                    const { cta, ctaCategory } = BigDataHelper.createAttribute(this.newsletterSaver, true);
                    attribute.cta = cta;
                    attribute.ctaCategory = ctaCategory;
                }
                if (!onlyBigData) {
                    this.utilizationObject.utilize(dataToSend);
                }
                BigData.sendDataOceanEvent(BigDataEventConstants.onClick, Object.assign(Object.assign({}, dataToSend), attribute));
                BigData.sendDataOceanEvent(BigDataEventConstants.onAddingToNewsletter, {
                    hm: yield this.sha256(DOMHelper.getEmailValue(this.emailInput)),
                    newsletter: [this.newsletterId]
                });
            }
        });
    }
    sha256(message) {
        return __awaiter(this, void 0, void 0, function* () {
            // encode as UTF-8
            const msgBuffer = new TextEncoder().encode(message);
            // hash the message
            const hashBuffer = yield crypto.subtle.digest("SHA-256", msgBuffer);
            // convert ArrayBuffer to Array
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            // convert bytes to hex string
            const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
            return hashHex;
        });
    }
    subscribe() {
        return __awaiter(this, void 0, void 0, function* () {
            const data = {
                email: DOMHelper.getEmailValue(this.emailInput),
                newsletters: [this.newsletterId],
                confirmed: false
            };
            yield httpInstance
                .post({
                url: this.path,
                data: data,
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "*/*"
                }
            })
                .then(this.successNewsletterSubscription.bind(this), this.errorNewsletterSubscription.bind(this));
        });
    }
    successNewsletterSubscription() {
        const step1 = DOMHelper.querySelector(NewsletterSaverConstants.step1Class, this.newsletterSaver), step2 = DOMHelper.querySelector(NewsletterSaverConstants.step2Class, this.newsletterSaver);
        this.hideNewsSwimmerButtonCloseStep2();
        step1.classList.toggle(MarketingClientConstants.hiddenClassName);
        step2.classList.toggle(MarketingClientConstants.hiddenClassName);
        this.appendUserEmail(step2);
    }
    hideNewsSwimmerButtonCloseStep2() {
        const newsSwimmerButtonCloseStep2 = DOMHelper.querySelector(NewsSwimmerConstants.newsSwimmerWrapperClass);
        newsSwimmerButtonCloseStep2 === null || newsSwimmerButtonCloseStep2 === void 0 ? void 0 : newsSwimmerButtonCloseStep2.classList.add(MarketingClientConstants.hiddenClassName);
    }
    appendUserEmail(step2) {
        const mailBox = step2.querySelector(NewsletterSaverConstants.step2EmailClass);
        if (mailBox)
            mailBox.textContent = this.emailInput.value;
    }
    errorNewsletterSubscription() {
        this.emailErrorHandler.show(this.messages.subscriptionError);
        this.subscribeToNewsletterButton.removeAttribute(MarketingClientConstants.disabledClassName);
    }
}
