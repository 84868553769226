import { HatConstants } from "./constants/hat-constants";
import { EventType } from "./constants/event-type-constants";
import { DOMHelper } from "./helpers/dom-helper";
export class Hat {
    constructor(isMobile) {
        this.isMobile = isMobile;
        this.prevAnimationAPosY = window.scrollY;
        this.prevPosY = window.scrollY;
        this.marginOfError = 9;
    }
    init() {
        var _a, _b;
        if (this.isMobile) {
            const hat = DOMHelper.querySelector(HatConstants.hatSelector), hatWrapper = DOMHelper.querySelector(HatConstants.harWrapperSelector), scrollDown = (_a = hat === null || hat === void 0 ? void 0 : hat.getAttribute(HatConstants.dataScrollDown)) !== null && _a !== void 0 ? _a : "0", scrollUp = (_b = hat === null || hat === void 0 ? void 0 : hat.getAttribute(HatConstants.dataScrollUp)) !== null && _b !== void 0 ? _b : "0";
            this.scrollAnimation(hatWrapper, scrollDown, scrollUp);
        }
    }
    scrollAnimation(hatWrapper, scrollDown, scrollUp) {
        window.addEventListener(EventType.SCROLL, () => {
            const currentPosition = this.prevPosY >= window.scrollY - this.marginOfError ? HatConstants.top : HatConstants.bottom;
            if (currentPosition === HatConstants.bottom && this.prevPosY > Number(scrollDown)) {
                hatWrapper === null || hatWrapper === void 0 ? void 0 : hatWrapper.classList.add(HatConstants.scrollDownClass);
                this.prevAnimationAPosY = window.scrollY;
            }
            else if (currentPosition === HatConstants.top && this.prevPosY < this.prevAnimationAPosY - Number(scrollUp)) {
                hatWrapper === null || hatWrapper === void 0 ? void 0 : hatWrapper.classList.remove(HatConstants.scrollDownClass);
            }
            this.prevPosY = window.scrollY;
        }, { passive: true });
    }
}
