var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventType } from "./constants/event-type-constants";
import { DOMHelper } from "./helpers/dom-helper";
import { BigData } from "./marketing-client-bigdata";
import { BigDataHelper } from "./helpers/big-data-helper";
import { DeviceInformationHelper, DeviceType } from "./helpers/device-information-helper";
import { WYBORCZA_PL } from "./marketing-client";
import { BigDataEventConstants } from "./constants/big-data-constants";
import { Utils } from "./utils/utils";
import { MarketingClientConstants } from "./constants/marketing-client-constants";
import { ObjectConstants, ErrorMessage, Selector, HtmlElements, ProhibitedDomains } from "./constants/one-day-pass-old-constants";
import { CtaObjectConstants } from "./constants/cta-object-constants";
import { UtilizationConstants } from "./constants/marketing-client-utilization-constants";
export class OneDayPassOld {
    constructor(capping, utilizationObject) {
        this.capping = capping;
        this.utilizationObject = utilizationObject;
        this.isMobile = DeviceInformationHelper.getDeviceType() === DeviceType.MOBILE;
    }
    init() {
        this.module = DOMHelper.querySelector(Selector.closeActivation);
        this.form = DOMHelper.querySelector(`${Selector.closeActivation} ${HtmlElements.form}`);
        this.tokenIframe = DOMHelper.querySelectorAsArray(HtmlElements.iframe)[0];
        this.ctaObject = Utils.prepareCtaObject(this.module);
        this.collectionIdsObject = Utils.prepareCollectionIdsObject(this.module);
        if (!this.module) {
            return;
        }
        this.hideEmailField();
        this.bindActionsForLoginAndLogout();
        this.getTokenAndListenIframe();
        this.bindSubmitForm();
    }
    hideEmailField() {
        var _a;
        if ((_a = WYBORCZA_PL === null || WYBORCZA_PL === void 0 ? void 0 : WYBORCZA_PL.userInfo) === null || _a === void 0 ? void 0 : _a.loggedIn) {
            this.hideLogoutElements();
            this.showLoginElements();
            this.setLoginChannel();
            this.setUserEmail();
        }
        else {
            this.hideLoginElements();
        }
    }
    hideLogoutElements() {
        const elements = this.module ? DOMHelper.querySelectorAsArray(Selector.isLogout) : [];
        elements.forEach((element) => element.classList.add(MarketingClientConstants.hiddenClassName));
        const footerElements = DOMHelper.querySelectorAsArray(`${Selector.footer} ${Selector.loggedHide}`);
        footerElements.forEach((element) => element.classList.add(MarketingClientConstants.hiddenClassName));
    }
    showLoginElements() {
        const isLoginElements = this.module ? DOMHelper.querySelectorAsArray(Selector.isLogin) : [];
        isLoginElements.forEach((element) => element.classList.remove(MarketingClientConstants.hiddenClassName));
    }
    setLoginChannel() {
        const loginChannelElements = this.module ? DOMHelper.querySelectorAsArray(Selector.loginChannel) : [];
        loginChannelElements.forEach((element) => { var _a; return element.classList.add((_a = WYBORCZA_PL === null || WYBORCZA_PL === void 0 ? void 0 : WYBORCZA_PL.userInfo) === null || _a === void 0 ? void 0 : _a.channel); });
    }
    setUserEmail() {
        const userEmailElements = this.module ? DOMHelper.querySelectorAsArray(Selector.userEmail) : [];
        userEmailElements.forEach((element) => { var _a; return (element.textContent = (_a = WYBORCZA_PL === null || WYBORCZA_PL === void 0 ? void 0 : WYBORCZA_PL.userInfo) === null || _a === void 0 ? void 0 : _a.email); });
    }
    hideLoginElements() {
        const isLoginElements = this.module ? DOMHelper.querySelectorAsArray(Selector.isLogin) : [];
        isLoginElements.forEach((element) => element.classList.add(MarketingClientConstants.hiddenClassName));
    }
    bindActionsForLoginAndLogout() {
        const loginBtn = DOMHelper.querySelectorAsArray(`${Selector.closeActivation} ${Selector.loginAction}`);
        const logoutBtn = DOMHelper.querySelector(`${Selector.closeActivation} ${Selector.logoutAction}`);
        loginBtn === null || loginBtn === void 0 ? void 0 : loginBtn.forEach((btn) => {
            btn.addEventListener(EventType.CLICK, () => {
                this.goToLogin();
            });
        });
        if (logoutBtn) {
            logoutBtn === null || logoutBtn === void 0 ? void 0 : logoutBtn.addEventListener(EventType.CLICK, (e) => {
                this.logout(e);
            });
        }
    }
    logout(e) {
        e.preventDefault();
        let ssoCookieValue = (RegExp(ObjectConstants.ssoSessionCookieName + "=([^;]+)").exec(document.cookie) || [])[1] || "";
        let url = ObjectConstants.locationLogin + "/services/logout/jsonp";
        if (location.host.indexOf(Selector.rootHostname) === -1) {
            url += "?SsoSessionPermanent=" + ssoCookieValue;
        }
        const script = document.createElement("script");
        const callbackName = "logoutCallback";
        window[callbackName] = this.logoutCallback.bind(this);
        script.src = url + `?callback=${callbackName}`;
        document.body.appendChild(script);
    }
    logoutCallback() {
        document.cookie = `${ObjectConstants.cookieName}=; expires=${new Date(0).toUTCString()}; path=/;`;
        document.location.href = ObjectConstants.visitedUrl;
    }
    goToLogin() {
        window.location.href = ObjectConstants.locationLogin;
    }
    getTokenAndListenIframe() {
        var _a, _b, _c;
        if ((_a = this.tokenIframe) === null || _a === void 0 ? void 0 : _a.contentDocument) {
            if (((_b = this.tokenIframe) === null || _b === void 0 ? void 0 : _b.contentDocument.readyState) === "complete") {
                this.getToken(ObjectConstants.tokenUrl);
            }
        }
        else {
            (_c = this.tokenIframe) === null || _c === void 0 ? void 0 : _c.addEventListener("load", (event) => {
                this.getToken(ObjectConstants.tokenUrl);
            });
        }
    }
    bindSubmitForm() {
        var _a;
        (_a = this.form) === null || _a === void 0 ? void 0 : _a.addEventListener(EventType.SUBMIT, (event) => {
            var _a;
            if (this.token) {
                this.validRequiredCondition(this.form);
                this.validOneClickCondition(this.form);
                if (!((_a = WYBORCZA_PL === null || WYBORCZA_PL === void 0 ? void 0 : WYBORCZA_PL.userInfo) === null || _a === void 0 ? void 0 : _a.loggedIn)) {
                    this.validEmail(this.form);
                }
                else {
                    this.formSubmit();
                }
            }
            event.preventDefault();
        });
    }
    validEmail(form) {
        return __awaiter(this, void 0, void 0, function* () {
            const emailInput = form.querySelector(ObjectConstants.emailInput);
            const emailValue = emailInput === null || emailInput === void 0 ? void 0 : emailInput.value.trim();
            const errors = {
                emailUsed: ErrorMessage.used,
                invalidEmail: ErrorMessage.invalid,
                prohibitedDomain: ErrorMessage.prohibitedDomain
            };
            emailInput === null || emailInput === void 0 ? void 0 : emailInput.classList.remove(ObjectConstants.error);
            if (!this.isValidEmail(emailValue)) {
                this.displayEmailError(emailInput, errors.invalidEmail);
                return false;
            }
            if (this.isProhibitedDomain(emailValue)) {
                this.displayEmailError(emailInput, errors.prohibitedDomain);
                return false;
            }
            this.isEmailUsed(emailValue, emailInput, errors);
        });
    }
    isValidEmail(email) {
        const emailRegexp = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/gim;
        return emailRegexp.test(email);
    }
    isProhibitedDomain(email) {
        const emailSplit = email.split("@");
        const emailDomain = emailSplit[1];
        return ProhibitedDomains.indexOf(emailDomain) > -1;
    }
    isEmailUsed(email, emailInput, errors) {
        fetch(`/kontowyborcza/services/check-if-email-used?email=${email}&api=ajax2`)
            .then((response) => response.json())
            .then((data) => {
            if (data.used || data.usedGazeta) {
                this.displayEmailError(emailInput, errors.emailUsed);
            }
            else {
                this.formSubmit();
            }
        })
            .catch((error) => {
            console.error(error);
        });
    }
    displayEmailError(emailInput, text) {
        emailInput === null || emailInput === void 0 ? void 0 : emailInput.classList.add(ObjectConstants.error);
        const errorContainer = emailInput === null || emailInput === void 0 ? void 0 : emailInput.nextElementSibling;
        const errorMessage = errorContainer === null || errorContainer === void 0 ? void 0 : errorContainer.querySelector(Selector.errorMessage);
        if (errorMessage) {
            errorMessage.textContent = text;
        }
    }
    validOneClickCondition(form) {
        const oneClickElements = form.querySelectorAll(Selector.oneClickRequired);
        oneClickElements.forEach((element) => {
            this.validCondition({
                form,
                type: Selector.oneClickRequired,
                unCheckCallback: (obj) => {
                    obj.removeAttribute(ObjectConstants.dataRequired);
                }
            });
        });
    }
    validRequiredCondition(form) {
        this.validCondition({ form, type: ObjectConstants.requiredSelector });
    }
    validCondition(options) {
        options.unCheckCallback = options.unCheckCallback || (() => { });
        const requiredConditions = options.form.querySelectorAll(`${ObjectConstants.checkboxConditions}${options.type}`);
        requiredConditions.forEach((obj) => {
            const input = obj;
            this.removeInputError(input);
            if (!input.checked && input.getAttribute(ObjectConstants.dataRequired)) {
                this.addInputError(input);
                if (options.unCheckCallback)
                    options.unCheckCallback(input, input.nextElementSibling);
            }
        });
    }
    addInputError(input) {
        const label = input.nextElementSibling;
        label.classList.add(ObjectConstants.error);
    }
    removeInputError(input) {
        const label = input.nextElementSibling;
        label.classList.remove(ObjectConstants.error);
    }
    formSubmit() {
        const errors = document.querySelectorAll(`${Selector.closeActivation} ${HtmlElements.form} ${Selector.error} `);
        let utilizationData = this.collectUtilizationData();
        if (errors.length <= 0) {
            if (utilizationData) {
                this.sendBigDataUtilization();
                this.utilizationObject.utilize(utilizationData);
                this.sendFormData();
            }
            else {
                this.sendFormData();
            }
        }
    }
    addLazyLoader(element) {
        const dimensions = element === null || element === void 0 ? void 0 : element.getBoundingClientRect(), scrollTop = document.body.scrollTop || document.documentElement.scrollTop, scrollLeft = document.body.scrollLeft || document.documentElement.scrollLeft, top = (dimensions === null || dimensions === void 0 ? void 0 : dimensions.top) + scrollTop, left = (dimensions === null || dimensions === void 0 ? void 0 : dimensions.left) + scrollLeft, width = dimensions === null || dimensions === void 0 ? void 0 : dimensions.width, height = dimensions === null || dimensions === void 0 ? void 0 : dimensions.height, loader = document.createElement("div");
        loader.classList.add(ObjectConstants.utilizationLoader);
        loader.style.cssText = `width: ${width}px; height: ${height}px; top: ${top}px; left: ${left}px;`;
        document.body.appendChild(loader);
        return {
            remove: () => {
                if (loader) {
                    document.body.removeChild(loader);
                }
            }
        };
    }
    sendFormData() {
        var _a;
        const formData = this.getFormData();
        const dataString = this.appendDataToUrl(formData);
        this.fetchJSONP((_a = formData.form) === null || _a === void 0 ? void 0 : _a.action, dataString);
    }
    getFormData() {
        var _a;
        const form = (_a = this.module) === null || _a === void 0 ? void 0 : _a.querySelector(HtmlElements.form);
        const customerProperties = form === null || form === void 0 ? void 0 : form.querySelectorAll(ObjectConstants.customerPropertiesSelector);
        const data = {
            form,
            customerProperties: {}
        };
        this.loader = this.addLazyLoader(form);
        customerProperties === null || customerProperties === void 0 ? void 0 : customerProperties.forEach((property) => {
            const customerProperty = property.getAttribute(ObjectConstants.customerPropertiesSelector);
            if (customerProperty) {
                data.customerProperties[customerProperty] = property.value;
            }
        });
        return data;
    }
    appendDataToUrl(data) {
        var _a;
        const dataString = new URLSearchParams();
        for (const customerProperty in data.customerProperties) {
            dataString.append(`customerProperties[${customerProperty}]`, data.customerProperties[customerProperty]);
        }
        Array.from(((_a = data.form) === null || _a === void 0 ? void 0 : _a.elements) || []).forEach((input) => {
            if (input instanceof HTMLInputElement || input instanceof HTMLSelectElement) {
                if (input.name && input.value) {
                    dataString.append(input.name, input.value);
                }
            }
        });
        return dataString.toString();
    }
    fetchJSONP(url, dataString) {
        const params = new URLSearchParams(dataString);
        const callbackName = "jsonpCallback";
        window[callbackName] = this.jsonpCallback.bind(this);
        const script = document.createElement("script");
        script.src = `${url}?callback=${callbackName}&${params}`;
        document.body.appendChild(script);
    }
    jsonpCallback(data) {
        var _a;
        const promoCodeError = (_a = this.module) === null || _a === void 0 ? void 0 : _a.querySelector(Selector.promoCodeError);
        if (data.status === "ok") {
            location.reload();
        }
        else if (promoCodeError) {
            promoCodeError.classList.add(ObjectConstants.isVisible);
        }
        if (this.loader) {
            this.loader.remove();
        }
    }
    sendBigDataUtilization() {
        var _a;
        const parent = (_a = this.module) === null || _a === void 0 ? void 0 : _a.closest(UtilizationConstants.classMcBan);
        const elementCtaCategory = parent === null || parent === void 0 ? void 0 : parent.querySelector(`[${CtaObjectConstants.dataCtaCategory}]`);
        if (elementCtaCategory) {
            BigData.sendDataOceanEvent(BigDataEventConstants.onClick, BigData.prepareDataOceanAttribute(this.ctaObject, this.collectionIdsObject));
        }
    }
    collectUtilizationData() {
        var _a, _b, _c;
        let parent = (_a = this.module) === null || _a === void 0 ? void 0 : _a.closest(UtilizationConstants.classMcBan), utilizeButton = (_b = this.module) === null || _b === void 0 ? void 0 : _b.querySelector(`[${ObjectConstants.manualUtilizeAttr}]`), utilizationData;
        if (!parent || !utilizeButton) {
            return;
        }
        utilizationData = {
            campaignId: parent.getAttribute(UtilizationConstants.dataCampaignId),
            actionId: parent.getAttribute(UtilizationConstants.dataActionId),
            guid: BigDataHelper.getBigDataGUID(),
            condition: (_c = utilizeButton === null || utilizeButton === void 0 ? void 0 : utilizeButton.getAttribute(ObjectConstants.manualUtilizeAttr)) !== null && _c !== void 0 ? _c : ""
        };
        if (utilizationData.campaignId && utilizationData.actionId && utilizationData.guid && utilizationData.condition) {
            return utilizationData;
        }
        else {
            return null;
        }
    }
    getToken(tokenUrl) {
        if (window["squid"] === undefined) {
            const callbackName = "setTokenCallback";
            window[callbackName] = this.setToken.bind(this);
            const script = document.createElement("script");
            script.src = `${tokenUrl}?callback=${callbackName}`;
            script.async = true;
            document.body.appendChild(script);
        }
    }
    setToken(tokenObj) {
        const formcInput = DOMHelper.querySelector(ObjectConstants.formc);
        if (formcInput && formcInput) {
            formcInput.value = tokenObj.value;
            this.token = tokenObj.value;
        }
    }
}
