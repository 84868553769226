export class DOMHelper {
    static querySelector(selector, element) {
        const sourceElement = element || document;
        return sourceElement.querySelector(selector);
    }
    static querySelectorAsArray(selector, element) {
        const sourceElement = element || document;
        if (sourceElement) {
            return Array.from(sourceElement.querySelectorAll(selector));
        }
        return [];
    }
    static forEachQuerySelector(selector, handler, element) {
        const elementArray = DOMHelper.querySelectorAsArray(selector, element);
        if (elementArray) {
            elementArray.forEach(handler);
        }
    }
    static mapQuerySelector(selector, handler, element) {
        const elementArray = DOMHelper.querySelectorAsArray(selector, element);
        if (elementArray) {
            return elementArray.map(handler);
        }
        return null;
    }
    static getElementById(selector) {
        return document.getElementById(selector);
    }
    static elementIsVisible(element) {
        return window.getComputedStyle(element).visibility !== "hidden";
    }
    static getEmailValue(email) {
        var _a;
        return String((_a = email === null || email === void 0 ? void 0 : email.value) !== null && _a !== void 0 ? _a : "").trim().toLowerCase();
    }
}
