var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventType } from "../../constants/event-type-constants";
import ReadLaterService from "./service";
import ReadLaterView from "./view";
import Tooltip from "../../tooltip/module";
import { ReadLaterConstants } from "../../constants/read-later-constants";
import { DOMHelper } from "../../helpers/dom-helper";
export default class ReadLater {
    constructor(readLaterButtonsClass) {
        this.articleIdList = [];
        this.articleTotal = 0;
        this.readLaterButtonsClass = readLaterButtonsClass;
        this.isLoggedIn = Boolean(document.body.dataset.logged);
    }
    init() {
        if (this.isLoggedIn) {
            this.capUserElement = DOMHelper.querySelector(ReadLaterConstants.capUserSelector);
            this.countArticleElement = DOMHelper.querySelector(ReadLaterConstants.countArticleSelector);
        }
    }
    initializeReadLaterButton(buttonParams, button) {
        if (button && this.isLoggedIn) {
            this.handleButtonClick(button, buttonParams);
        }
        else {
            this.hideButtons(button);
        }
    }
    bindReadLaterEvents(boxes) {
        boxes.forEach((box) => {
            this.bindBox(box);
        });
    }
    bindBox(box) {
        const button = box.elements.readLaterButton;
        this.initializeReadLaterButton(box.articleParameters, button);
        this.setButtonState(button);
        this.addDatasetXX(box.elements.anchors[0], button);
    }
    hideButtons(button) {
        if (button) {
            button.style.display = ReadLaterConstants.displayNone;
            button.innerHTML = ReadLaterConstants.emptyString;
        }
    }
    handleButtonClick(button, buttonParams) {
        button.addEventListener(EventType.CLICK, (e) => {
            e.preventDefault();
            this.toggle(button, e, buttonParams.articleId, buttonParams.pageUrl);
        });
    }
    toggle(button, event, xx, url) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.isLoggedIn || !button) {
                return;
            }
            const index = this.articleIdList.indexOf(xx);
            event.preventDefault();
            if (index >= 0 && (yield ReadLaterService.remove(xx))) {
                this.articleIdList.splice(index, 1);
                button.classList.remove(ReadLaterConstants.buttonInListClass);
                Tooltip.show(button, ReadLaterConstants.articleDeletedFromStorage);
                localStorage.setItem(ReadLaterConstants.localStorageKey, JSON.stringify(this.articleIdList));
                this.countArticleElement.dataset.chip = this.articleIdList.length.toString();
            }
            else if (yield ReadLaterService.add(xx, url)) {
                this.articleIdList.push(xx);
                button.classList.add(ReadLaterConstants.buttonInListClass);
                Tooltip.show(button, ReadLaterConstants.articleAddedToStorage);
                localStorage.setItem(ReadLaterConstants.localStorageKey, JSON.stringify(this.articleIdList));
                this.countArticleElement.dataset.chip = this.articleIdList.length.toString();
                ReadLaterView.doAnimation(button, this.capUserElement);
            }
        });
    }
    addDatasetXX(anchor, button) {
        var _a;
        if (anchor && button) {
            button.dataset.xx = (_a = anchor === null || anchor === void 0 ? void 0 : anchor.href) === null || _a === void 0 ? void 0 : _a.split(",")[2];
        }
    }
    setButtonState(button) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.isLoggedIn || !button) {
                return;
            }
            this.getLocalStorageData(button);
            this.getServerData(button);
        });
    }
    getLocalStorageData(button) {
        try {
            this.articleIdList = JSON.parse(localStorage.getItem(ReadLaterConstants.localStorageKey));
            this.articleTotal = this.articleIdList.length;
            button.classList.toggle(ReadLaterConstants.buttonInListClass, this.articleIdList.indexOf(Number(button.dataset.xx)) >= 0);
            this.countArticleElement.dataset.chip = this.articleIdList.length.toString();
        }
        catch (e) {
            console.log("%cReadLater", "color: #faa", "no local saved articles");
        }
    }
    getServerData(button) {
        return __awaiter(this, void 0, void 0, function* () {
            const { list, total } = yield ReadLaterService.list(Number(document.body.dataset.xx));
            this.articleIdList = list;
            this.articleTotal = total;
            button.classList.toggle(ReadLaterConstants.buttonInListClass, this.articleIdList.indexOf(Number(button.dataset.xx)) >= 0);
            this.countArticleElement.dataset.chip = this.articleTotal.toString();
        });
    }
}
