import { DOMHelper } from "./helpers/dom-helper";
import { Utils } from "./utils/utils";
import { EventType } from "./constants/event-type-constants";
export class MarketingClientDataLayer {
    constructor() {
        this.dataLayer = "dataLayer";
        this.dataset = "dataset";
        this.hrefAttribute = "href";
        this.ctaAttribute = "cta";
        this.ctaCategoryAttribute = "ctaCategory";
        this.ctaContainerAttribute = "[data-cta]";
        this.ctaCategoryContainerAttribute = "[data-cta-category]";
        this.paywallEventName = "paywallEvent";
    }
    init() {
        this.bindClickEventToElementsWithDataCtaAttribute();
        this.bindDisplayEventToElementsWithDataCtaAttribute();
    }
    bindClickEventToElementsWithDataCtaAttribute() {
        DOMHelper.querySelectorAsArray(this.ctaContainerAttribute).forEach(ctaContainer => {
            ctaContainer.removeEventListener(EventType.CLICK, (event) => { this.onClick(event); });
            ctaContainer.addEventListener(EventType.CLICK, (event) => { this.onClick(event); }, true);
        });
    }
    bindDisplayEventToElementsWithDataCtaAttribute() {
        DOMHelper.querySelectorAsArray(this.ctaCategoryContainerAttribute).forEach(marketingElement => {
            this.onDisplay(marketingElement);
        });
    }
    onClick(event) {
        let clickedElement = event.target;
        this.pushEventToDataLayer(this.prepareOnClickEventDataToSend(clickedElement));
    }
    prepareOnClickEventDataToSend(clickedElement) {
        var _a;
        let gtmLabel = "", gtmCategory = "";
        if (clickedElement) {
            let clickedElementHref = (_a = clickedElement.getAttribute(this.hrefAttribute)) !== null && _a !== void 0 ? _a : "", clickedElementClosestDataCtaCategory = clickedElement.closest(this.ctaCategoryContainerAttribute);
            gtmCategory = clickedElementClosestDataCtaCategory ? clickedElementClosestDataCtaCategory[this.dataset][this.ctaCategoryAttribute] : "";
            gtmLabel = Utils.getURLParamFromEventTarget(clickedElementHref, this.ctaAttribute);
            if (!Utils.stringNotEmpty(gtmLabel)) {
                const clickedElementClosestCta = clickedElement.closest(this.ctaContainerAttribute);
                gtmLabel = (clickedElementClosestCta === null || clickedElementClosestCta === void 0 ? void 0 : clickedElementClosestCta[this.dataset][this.ctaAttribute]) || "";
            }
        }
        return {
            gtmAction: EventType.CLICK,
            gtmLabel,
            gtmCategory
        };
    }
    onDisplay(element) {
        this.pushEventToDataLayer({
            gtmAction: EventType.DISPLAY,
            gtmLabel: element.dataset.cta || "",
            gtmCategory: element.dataset.ctaCategory || ""
        });
    }
    pushEventToDataLayer(config) {
        window[this.dataLayer] = window[this.dataLayer] || [];
        const { gtmCategory, gtmAction, gtmLabel } = config;
        window[this.dataLayer].push({
            gtmAction,
            gtmLabel,
            gtmCategory,
            "event": this.paywallEventName
        });
    }
}
export const marketingClientDataLayer = new MarketingClientDataLayer();
