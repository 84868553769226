export const HpNltObjectConstants = {
    newsletterId: "data-nltId",
};
export const HpNltSelector = {
    hpNewsletterInput: ".saver-newsletter-hp__content__box__item__bottom__container__email-form--wrapper--input",
    hpNewsletterButton: ".saver-newsletter-hp__content__box__item__bottom__container__email-form--button",
    hpNewsletterSlider: ".saver-newsletter-hp__glide",
    hpNewsletterExpandBtn: ".saver-newsletter-hp__content__agreements__form-expand--button",
    hpNewsletterAgreements: ".saver-newsletter-hp__content__agreements-text"
};
export const WoHpNltSelector = {
    hpNewsletterContainer: ".wo-saver-newsletter-hp",
    hpNewsletterInput: ".wo-saver-newsletter-hp__content__box__item__bottom__container__email-form--wrapper--input",
    hpNewsletterButton: ".wo-saver-newsletter-hp__content__box__item__bottom__container__email-form--button",
    hpNewsletterSlider: ".wo-saver-newsletter-hp__glide",
    hpNewsletterExpandBtn: ".wo-saver-newsletter-hp__content__agreements__form-expand--button",
    hpNewsletterAgreements: ".wo-saver-newsletter-hp__content__agreements-text"
};
export const HpNltClassName = {
    hidden: "invisible",
    collapseAgreements: "collapse-text-agreements",
    expandButtonActive: "active",
};
export const HpNltrrorMessage = {
    required: "Pole e-mail jest wymagane!",
    banned: "Podany e-mail jest błędny, wpisz poprawny adres",
    accountExists: "Masz już konto w Wyborczej",
    accountUnconfirmed: `Proces zakładania konta już rozpoczęty. Nie masz e-maila aktywacyjnego? <a style="text-decoration: underline;">Wyślij go ponownie.</a>`,
    subscriptionError: 'Błąd! Nie udało się zapisać na newsletter. Spróbuj ponownie za chwilę.'
};
export const HpNltSliderOptions = {
    startAt: 0,
    perView: 2,
    gap: 0,
    peek: { before: 0, after: 0 },
    bound: true,
    breakpoints: {
        680: {
            perView: 1,
            peek: { before: 0, after: 40 },
            focusAt: "center"
        }
    }
};
export const WoHpNltSliderOptions = {
    startAt: 0,
    perView: 1,
    gap: 0,
    peek: { before: 0, after: 144 },
    bound: true,
    autoplay: false,
    breakpoints: {
        1439: {
            perView: 1,
            peek: { before: 0, after: 94 },
        },
        679: {
            perView: 1,
            peek: { before: 0, after: 40 },
        }
    }
};
