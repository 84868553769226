export class CookieHelper {
    static set(name, value, days) {
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = `; expires=${date.toUTCString()}`;
        }
        document.cookie = `${name}=${value}${expires}; path=/`;
    }
    static get(name) {
        var _a, _b, _c;
        const nameEQ = `${name}=`;
        return ((_c = (_b = (_a = document.cookie
            .split(";")
            .map((cookie) => cookie.trimStart())
            .find((cookie) => cookie.startsWith(nameEQ))) === null || _a === void 0 ? void 0 : _a.split("=")) === null || _b === void 0 ? void 0 : _b[1]) !== null && _c !== void 0 ? _c : null);
    }
    static destroy(name) {
        CookieHelper.set(name, "", -1);
    }
}
