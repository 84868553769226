import { BigDataClickID, BigDataEventConstants } from "./constants/big-data-constants";
import { EventType } from "./constants/event-type-constants";
import { MarketingClientConstants } from "./constants/marketing-client-constants";
import { SwimmerConstants } from "./constants/swimmer-constants";
import { BigDataHelper } from "./helpers/big-data-helper";
import { DeviceInformationHelper, DeviceType } from "./helpers/device-information-helper";
import { DOMHelper } from "./helpers/dom-helper";
import { BigData } from "./marketing-client-bigdata";
import { Utils } from "./utils/utils";
export class MarketingClientRWBase {
    constructor(capping, utilizationObject, classSection) {
        this.capping = capping;
        this.utilizationObject = utilizationObject;
        this.classSection = classSection;
        this.swimmerWasVisible = false;
        this.idSwimmer = "rw-swimmer";
        this.classSwimmerButton = ".rw_swimmer_button";
        this.ddboxSelector = "[data-ddbox-with-rw=true]";
        this.classDdboxButton = ".rw-ddbox-area";
        this.mobileCapHeight = 250;
        this.desktopCapHeight = 350;
        this.isHarmonyTest = Utils.getURLParamsAsObject().enableHarmonyTest;
        this.squidHomeUrl = this.isHarmonyTest ? "https://coreos07.wyborcza.pl:8094/" : "https://squid-api.wyborcza.pl/";
        this.isMobile = DeviceInformationHelper.getDeviceType() === DeviceType.MOBILE;
    }
    initialize() {
        this.initializeDdbox();
        this.initializeSwimmer();
        this.initializeSection();
    }
    initializeSection() {
        this.section = DOMHelper.querySelector(`section${this.classSection}`);
        this.hideSection();
    }
    initializeSwimmer() {
        this.swimmer = DOMHelper.getElementById(this.idSwimmer);
        this.swimmerButton = DOMHelper.querySelector(this.classSwimmerButton, this.swimmer);
        this.closeButton = DOMHelper.querySelector(SwimmerConstants.closeButtonSelector, this.swimmer);
        this.hideSwimmer();
    }
    initializeDdbox() {
        this.ddbox = DOMHelper.querySelector(this.ddboxSelector);
        this.ddboxButton = DOMHelper.querySelector(this.classDdboxButton, this.ddbox);
    }
    bindSwimmerEvents() {
        if (this.ddbox) {
            this.bindEventsDdboxScrollToPadlock();
        }
        else {
            this.bindEventsScrollToPadlock();
            this.bindEventsHideSwimmerAtHeightOfPadlock();
            this.bindEventCloseSwimmerManually();
        }
    }
    bindEventsDdboxScrollToPadlock() {
        if (this.ddboxButton && this.ddbox) {
            this.ddboxButton.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.utilizeElement(this.ddbox, BigDataClickID.createAccount, false);
                this.scrollToPadlockForDdbox();
            });
        }
    }
    bindEventsScrollToPadlock() {
        if (this.swimmerButton) {
            this.swimmerButton.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.utilize(BigDataClickID.createAccount, false, true);
                this.scrollToElement();
            });
        }
    }
    bindEventsHideSwimmerAtHeightOfPadlock() {
        if (this.swimmer) {
            document.addEventListener(EventType.SCROLL, () => {
                this.toggleSwimmer();
            });
        }
    }
    toggleSwimmer() {
        var _a, _b;
        const swimmerTop = (_a = this.swimmer) === null || _a === void 0 ? void 0 : _a.offsetTop, sectionTop = (_b = this.section) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().top, swimmerHeight = this.swimmerHeight || 250, height = this.getScreenHeight();
        if (sectionTop && swimmerTop && sectionTop <= swimmerTop && this.swimmerIsVisible) {
            this.hideSwimmer();
        }
        else if (sectionTop && (window.scrollY + (height - swimmerHeight) + 20) < sectionTop && !this.swimmerIsVisible) {
            this.showSwimmer();
        }
    }
    bindEventCloseSwimmerManually() {
        if (this.closeButton) {
            this.closeButton.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.closeSwimmerManually();
            });
        }
    }
    closeSwimmerManually() {
        var _a;
        (_a = this.closeButton) === null || _a === void 0 ? void 0 : _a.classList.add(SwimmerConstants.closedManuallyClass);
    }
    scrollToPadlockForDdbox() {
        this.scrollToElement();
        this.closeDdbox();
    }
    scrollToElement() {
        if (this.section) {
            const top = this.section.offsetTop - window.innerHeight / 3;
            Utils.smoothScrollTo(top);
        }
    }
    getParentElement(isSwimmer = false) {
        const element = isSwimmer ? this.swimmer : this.section;
        return element === null || element === void 0 ? void 0 : element.closest(`[${MarketingClientConstants.placeholderDataSetAttribute}]`);
    }
    utilize(clickID = "", onlyBigData = false, isSwimmer = false) {
        const parent = this.getParentElement(isSwimmer);
        if (parent) {
            this.utilizeElement(parent, clickID, onlyBigData);
        }
    }
    utilizeElement(element, clickID, onlyBigData) {
        const condition = "brak", dataToSend = this.utilizationObject.prepareDataToSend(element, condition), attribute = BigDataHelper.createAttribute(element, true, clickID);
        if (!onlyBigData) {
            this.utilizationObject.utilize(dataToSend);
        }
        BigData.sendDataOceanEvent(BigDataEventConstants.onClick, Object.assign(Object.assign({}, dataToSend), attribute));
    }
    sendBigDataDisplay(ctaCategoryExtend) {
        const parent = this.getParentElement(), condition = "brak";
        if (parent) {
            const dataToSend = this.utilizationObject.prepareDataToSend(parent, condition), attribute = BigDataHelper.createAttribute(parent);
            attribute.ctaCategory = `${attribute.ctaCategory} ${ctaCategoryExtend}`;
            BigData.sendDataOceanEvent(BigDataEventConstants.onDisplay, Object.assign(Object.assign({}, dataToSend), attribute));
        }
    }
    sendCapping(element, onDisplay = false) {
        this.capping.sendCappingEvent(element, onDisplay);
    }
    showSection() {
        var _a;
        (_a = this.section) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hiddenClassName);
    }
    hideSection() {
        var _a;
        (_a = this.section) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
    }
    hideSwimmer() {
        var _a;
        this.swimmerIsVisible = false;
        (_a = this.swimmer) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
    }
    showSwimmer() {
        var _a, _b;
        this.swimmerIsVisible = true;
        (_a = this.swimmer) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hiddenClassName);
        this.swimmerHeight = (_b = this.swimmer) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().height;
        this.sendDisplay();
    }
    showDdbox() {
        var _a, _b, _c, _d;
        if (this.ddbox) {
            (_d = (_c = (_b = (_a = window["wyborcza_pl"]) === null || _a === void 0 ? void 0 : _a.MarketingClient) === null || _b === void 0 ? void 0 : _b.bannersObject) === null || _c === void 0 ? void 0 : _c.ddbox) === null || _d === void 0 ? void 0 : _d.showDdbox(this.ddbox);
        }
    }
    closeDdbox() {
        var _a, _b, _c, _d;
        if (this.ddbox) {
            (_d = (_c = (_b = (_a = window["wyborcza_pl"]) === null || _a === void 0 ? void 0 : _a.MarketingClient) === null || _b === void 0 ? void 0 : _b.bannersObject) === null || _c === void 0 ? void 0 : _c.ddbox) === null || _d === void 0 ? void 0 : _d.closeDdbox(this.ddbox);
        }
    }
    sendDisplay() {
        if (this.swimmer && !this.swimmerWasVisible) {
            this.swimmerWasVisible = true;
            this.sendCapping(this.swimmer, true);
        }
    }
    getScreenHeight() {
        return window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;
    }
}
