var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DeviceType } from "./device-information-helper";
export function generateOnboardingCtaCategory(ctaCategory, viewName, isMobile) {
    return `${ctaCategory}-${viewName}-${isMobile ? DeviceType.MOBILE : DeviceType.DESKTOP}`;
}
export function sha256(message) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        // encode as UTF-8
        const msgBuffer = new TextEncoder().encode(message);
        // hash the message
        const hashBuffer = yield ((_a = crypto.subtle) === null || _a === void 0 ? void 0 : _a.digest("SHA-256", msgBuffer));
        // convert ArrayBuffer to Array
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        // convert bytes to hex string
        return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    });
}
