export const DdboxConstants = {
    class: ".mod_ddbox",
    delay: 8e3,
    closeButton: ".close",
    editorWrapper: "__editorTarget",
    visible: "is-visible",
    locked: "is-locked",
    coords: "coords",
    dataCoords: "data-coords",
    tooBig: "is-too-big",
    mobile: "mobile_client",
    desktop: "desktop_client",
    timerSelector: ".ddbox-timer-countdown",
    articleVisited: "mc_onboarding_has_visited",
    wyborczaArticleBody: "#wyborcza_article_body",
    bdScrollPt: "bd-scroll-pt",
    mutationValue: {
        "25": "[25,50,75,100]",
        "50": "[50,75,100]",
        "75": "[75,100]",
        "100": "[100]",
    },
    defaultMutationValue: "25",
    defaultScrollUpDistance: 50
};
export const ZcpClickIds = {
    acceptChangesAndConsent: "klik akceptuje zmiany i wyrazam zgode",
    acceptChanges: "klik akceptuje zmiany",
    back: "klik wroc",
    doNotConsent: "klik nie wyrazam zgody",
    inAnyCaseDoNotConsent: "klik mimo to nie wyrazam zgody",
    close: "klik x",
    helpLink: "klik w pomoc",
    closeInModal: "klik w zamknij",
    openModal: "klik w przejdz do zgody",
    displayedModal: "wyswietlono ddbox",
    closeSwimmer: "klik x na plywaku",
};
