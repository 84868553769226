import { DOMHelper } from "./dom-helper";
import { NEXT_STEP, PREV_STEP } from "../constants/onboarding-constants";
export class Wizard {
    constructor(selector, activeStepClass, dotsSelector, activeDotClassName, nextStepEvent = NEXT_STEP, prevStepEvent = PREV_STEP) {
        this.currentStepIndex = 0;
        this.stepsSelector = selector;
        this.activeStepClassName = activeStepClass;
        this.dotsSelector = dotsSelector;
        this.activeDotClassName = activeDotClassName;
        this.steps = DOMHelper.querySelectorAsArray(this.stepsSelector);
        this.dots = DOMHelper.querySelectorAsArray(this.dotsSelector);
        this.prevStepEvent = prevStepEvent;
        this.nextStepEvent = nextStepEvent;
    }
    init() {
        this.steps = this.sortElementsByIndex();
        this.setFirstStep();
        this.setFirstDot();
        this.bindEvents();
    }
    setFirstStep() {
        const [firstStep] = this.steps;
        firstStep === null || firstStep === void 0 ? void 0 : firstStep.classList.add(this.activeStepClassName);
        this.currentStepElement = firstStep;
    }
    setFirstDot() {
        const [firstDot] = this.dots;
        firstDot === null || firstDot === void 0 ? void 0 : firstDot.classList.add(this.activeDotClassName);
        this.currentDotElement = firstDot;
    }
    bindEvents() {
        window.addEventListener(this.nextStepEvent, () => { this.nextStep(); });
        window.addEventListener(this.prevStepEvent, () => { this.previousStep(); });
    }
    nextStep() {
        if (this.currentStepIndex === this.steps.length - 1)
            return;
        this.toggleActiveClass();
        this.currentStepIndex += 1;
        this.currentStepElement = this.steps[this.currentStepIndex];
        this.currentDotElement = this.dots[this.currentStepIndex];
        this.toggleActiveClass();
    }
    previousStep() {
        if (this.currentStepIndex === 0)
            return;
        this.toggleActiveClass();
        this.currentStepIndex -= 1;
        this.currentStepElement = this.steps[this.currentStepIndex];
        this.currentDotElement = this.dots[this.currentStepIndex];
        this.toggleActiveClass();
    }
    toggleActiveClass() {
        var _a, _b;
        (_a = this.currentStepElement) === null || _a === void 0 ? void 0 : _a.classList.toggle(this.activeStepClassName);
        (_b = this.currentDotElement) === null || _b === void 0 ? void 0 : _b.classList.toggle(this.activeDotClassName);
    }
    sortElementsByIndex() {
        var _a;
        const elementsArray = [...this.steps];
        elementsArray.sort((a, b) => {
            const indexA = Number(a.dataset.stepIndex);
            const indexB = Number(b.dataset.stepIndex);
            return indexA - indexB;
        });
        const parentElement = (_a = elementsArray[this.currentStepIndex]) === null || _a === void 0 ? void 0 : _a.parentNode;
        elementsArray.forEach(element => {
            parentElement === null || parentElement === void 0 ? void 0 : parentElement.appendChild(element);
        });
        return elementsArray;
    }
}
