import { Wizard } from "./helpers/wizard";
import { OnboardingNewsletter } from "./marketing-client-onboarding-newsletter";
import { UtilizationConstants } from "./constants/marketing-client-utilization-constants";
import { BigDataHelper } from "./helpers/big-data-helper";
import { BigData } from "./marketing-client-bigdata";
import { BigDataEventConstants } from "./constants/big-data-constants";
import { DOMHelper } from "./helpers/dom-helper";
import { DdboxConstants } from "./constants/ddbox-constants";
import { Welcome } from "./marketing-client-welcome";
import { AppDownloadDdbox } from "./marketing-client-app-download";
import { EventType } from "./constants/event-type-constants";
import { generateOnboardingCtaCategory } from "./helpers/onboarding-helper";
import RecommendationOnboarding from "./utils/recommendations-cop/onboarding";
import { OnboardingConstants } from "./constants/onboarding-constants";
import { SubscriptionSharing } from "./marketing-client-subscription-sharing";
export class Onboarding {
    constructor(ddbox, utilization) {
        this.containerSelector = ".ddbox-onboarding";
        this.stepsSelector = ".ddbox-onboarding__step";
        this.activeStepClassName = "ddbox-onboarding__step--active";
        this.dotsSelector = ".ddbox-onboarding__dot";
        this.activeDotClassName = "ddbox-onboarding__dot--active";
        this.closeDdboxSelector = ".ddbox_onboarding__close-btn";
        this.ddbox = ddbox;
        this.utilization = utilization;
        this.wizard = new Wizard(this.stepsSelector, this.activeStepClassName, this.dotsSelector, this.activeDotClassName);
        this.welcome = new Welcome(this);
        this.newsletter = new OnboardingNewsletter(this);
        this.appDownloadDdbox = new AppDownloadDdbox(this);
        this.recomendation = new RecommendationOnboarding(this, this.ddbox);
        this.subscriptionSharing = new SubscriptionSharing(this);
    }
    init() {
        if (!DOMHelper.querySelector(this.containerSelector))
            return;
        this.wizard.init();
        this.welcome.init();
        this.newsletter.init();
        this.appDownloadDdbox.init();
        this.recomendation.init();
        this.subscriptionSharing.init();
        this.box = DOMHelper.querySelector(DdboxConstants.class);
        this.initializeCancelButton();
        this.bindEventsToCloseDdbox();
    }
    initializeCancelButton() {
        this.cancelModalButton = DOMHelper.querySelector(this.closeDdboxSelector);
    }
    bindEventsToCloseDdbox() {
        if (!this.cancelModalButton)
            return;
        this.cancelModalButton.addEventListener(EventType.CLICK, () => {
            this.closeDdbox();
        });
    }
    utilize() {
        var _a;
        if (this.box) {
            const condition = (_a = this.box.getAttribute(UtilizationConstants.dataUtilize)) !== null && _a !== void 0 ? _a : "brak", dataToSend = this.utilization.prepareDataToSend(this.box, condition);
            this.utilization.utilize(dataToSend);
        }
    }
    sendBigDataEvent(clickID) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (!this.box)
            return;
        this.ctaCategory = generateOnboardingCtaCategory((_c = (_b = (_a = this === null || this === void 0 ? void 0 : this.ddbox) === null || _a === void 0 ? void 0 : _a.ctaObject) === null || _b === void 0 ? void 0 : _b.ctaCategory) !== null && _c !== void 0 ? _c : "", (_f = (_e = (_d = this.wizard.currentStepElement) === null || _d === void 0 ? void 0 : _d.dataset) === null || _e === void 0 ? void 0 : _e.viewName) !== null && _f !== void 0 ? _f : "", (_g = this.ddbox) === null || _g === void 0 ? void 0 : _g.isMobile);
        const condition = (_h = this.box.getAttribute(UtilizationConstants.dataUtilize)) !== null && _h !== void 0 ? _h : "brak", dataToSend = this.utilization.prepareDataToSend(this.box, condition), attribute = BigDataHelper.createAttribute(this.box, true, clickID, this.ctaCategory);
        BigData.sendDataOceanEvent(BigDataEventConstants.onClick, Object.assign(Object.assign({}, dataToSend), attribute));
    }
    closeDdbox() {
        if (!this.box)
            return;
        this.ddbox.closeDdbox(this.box);
        this.sendBigDataEvent(OnboardingConstants.closeEvent);
    }
    finishOnboarding() {
        if (!this.box)
            return;
        this.ddbox.closeDdbox(this.box);
        this.sendBigDataEvent(OnboardingConstants.finishEvent);
        this.utilize();
    }
}
