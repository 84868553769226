import { BigDataObjectConstants } from "../constants/big-data-object-constants";
import { BigData } from "../marketing-client-bigdata";
import { Utils } from "../utils/utils";
import { CookieHelper } from "./cookie-helper";
export class BigDataHelper {
    static getBigDataObject() {
        var _a, _b, _c;
        const [mainObjectKey, portalObjectKey, blueWhaleWrapperKey] = BigDataObjectConstants.bigDataBlueWhaleObject.split(".");
        return (_c = (_b = (_a = window[mainObjectKey]) === null || _a === void 0 ? void 0 : _a[portalObjectKey]) === null || _b === void 0 ? void 0 : _b[blueWhaleWrapperKey]) !== null && _c !== void 0 ? _c : null;
    }
    static getBigDataGUID() {
        var _a, _b;
        const bigDataVariable = BigDataHelper.getBigDataObject();
        if (!bigDataVariable) {
            return null;
        }
        const bigDataGUID = bigDataVariable[BigDataObjectConstants.bigDataGUID];
        return (_b = (_a = CookieHelper.get(BigDataObjectConstants.bigDataGUIDCookie)) !== null && _a !== void 0 ? _a : bigDataGUID) !== null && _b !== void 0 ? _b : null;
    }
    static getBigDataGWGUID() {
        if (window[BigDataObjectConstants.bigDataSiteDetails]) {
            return window[BigDataObjectConstants.bigDataSiteDetails].gwguid;
        }
        return "";
    }
    static getBigDataGWEMID() {
        if (window[BigDataObjectConstants.bigDataSiteDetails]) {
            return window[BigDataObjectConstants.bigDataSiteDetails].gwemid;
        }
        return "";
    }
    static getBigDataOrCookieValue(cookieKey, bigDataKey) {
        var _a;
        const cookieValue = CookieHelper.get(cookieKey), bigDataObject = BigDataHelper.getBigDataObject(), bigDataValue = bigDataObject === null || bigDataObject === void 0 ? void 0 : bigDataObject[bigDataKey];
        return (_a = cookieValue !== null && cookieValue !== void 0 ? cookieValue : bigDataValue) !== null && _a !== void 0 ? _a : "";
    }
    static getVisitId() {
        return BigDataHelper.getBigDataOrCookieValue(BigDataObjectConstants.visitIdCookie, BigDataObjectConstants.visitIdBigData);
    }
    static getPageViewId() {
        return BigDataHelper.getBigDataOrCookieValue(BigDataObjectConstants.pageViewCookie, BigDataObjectConstants.pageViewBigData);
    }
    static createAttribute(element, isUtilization = false, clickID = "", customCtaCategory) {
        const htmlElement = isUtilization ? element : element.parentElement, collectionIdsObject = (htmlElement && Utils.prepareCollectionIdsObject(htmlElement)) || {}, ctaObject = (htmlElement && Utils.prepareCtaObject(htmlElement)) || {}, rwObject = (htmlElement && Utils.prepareRegistrationWallObject(htmlElement)) || {};
        if (customCtaCategory && ctaObject.ctaCategory) {
            ctaObject.ctaCategory = customCtaCategory;
        }
        return BigData.prepareDataOceanAttribute(ctaObject, collectionIdsObject, rwObject, clickID);
    }
}
