var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BigDataObjectConstants } from "./constants/big-data-object-constants";
import { BigDataHelper } from "./helpers/big-data-helper";
import { DeviceInformationHelper } from "./helpers/device-information-helper";
import { Utils } from "./utils/utils";
export class BigData {
    static sendDataOceanEvent(eventId, attributes) {
        var _a;
        const dataOceanEvent = {
            e: eventId,
            a: attributes
        };
        if ((_a = window["DataOcean"]) === null || _a === void 0 ? void 0 : _a.userEventCache) {
            window["DataOcean"].userEventCache.push(dataOceanEvent);
        }
    }
    static prepareDataOceanAttribute(dataCta, dataId, dataRW = {}, clickID = "") {
        const guid = BigDataHelper.getBigDataGUID(), pageViewId = BigDataHelper.getBigDataOrCookieValue(BigDataObjectConstants.pageViewCookie, BigDataObjectConstants.pageViewBigData), visitId = BigDataHelper.getBigDataOrCookieValue(BigDataObjectConstants.visitIdCookie, BigDataObjectConstants.visitIdBigData), externalId = BigData.getHashedEmail(), clickObj = clickID.length ? { clickID } : {}, dataOceanAttribute = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ visitId, pageViewId, guid }, dataId), dataCta), dataRW), externalId), clickObj);
        return dataOceanAttribute;
    }
    static getHashedEmail() {
        const dmParams = window["domain_marketing_params"];
        if (dmParams === null || dmParams === void 0 ? void 0 : dmParams.hashedEmail) {
            return { externalId: dmParams.hashedEmail };
        }
        else {
            return {};
        }
    }
    static prepareBlackList() {
        const objectId = "1001", homePageId = 0, articleId = Utils.attributeToNumber(DeviceInformationHelper.getXX()), blackList = [
            {
                definitionOuterId: homePageId,
                objectId
            }
        ];
        if (articleId) {
            blackList.push({
                definitionOuterId: articleId,
                objectId
            });
        }
        return blackList;
    }
    static prepareRequestDto(widgetId, params) {
        const data = {
            blackList: BigData.prepareBlackList(),
            widgetId,
            userIds: {
                guid: BigDataHelper.getBigDataGUID(),
                gwemid: BigDataHelper.getBigDataGWEMID(),
                gwguid: BigDataHelper.getBigDataGWGUID()
            }
        };
        return params ? Object.assign(data, { params }) : data;
    }
    static getRecommendations(widgetId, params) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch("https://recommendation.wyborcza.pl/recommendations/v1/bigdata/widget?localTopicDayResponse", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(BigData.prepareRequestDto(widgetId, params))
            });
            return yield response.json();
        });
    }
}
