import { MarketingClientConstants } from "../constants/marketing-client-constants";
import { BannedEmailDomains } from "../constants/banned-email-domains";
import { validEmailRegexp } from "../constants/utils-constants";
import { IdObjectConstants } from "../constants/id-object-constants";
import { CtaObjectConstants } from "../constants/cta-object-constants";
import { DOMHelper } from "../helpers/dom-helper";
import { EventType } from "../constants/event-type-constants";
import { RwObjectConstants } from "../constants/rw-object.constants";
import { DdboxConstants } from "../constants/ddbox-constants";
export class Utils {
    static isNullOrUndefined(value) {
        return value === null || value === undefined;
    }
    static isObject(value) {
        return !Utils.isNullOrUndefined(value) && typeof value === "object" && !(value instanceof Array);
    }
    static isArray(value) {
        return !Utils.isNullOrUndefined(value) && value instanceof Array;
    }
    static stringNotEmpty(value) {
        return (!Utils.isNullOrUndefined(value) &&
            typeof value === "string" &&
            value.length > 0);
    }
    static getURLParamsAsArray() {
        const searchString = window.location.search.substring(1), searchStringSplit = searchString.split("&");
        return searchStringSplit.map(keyValuePair => {
            const [rawKey, rawValue] = keyValuePair.split("="), output = {
                key: rawKey,
                value: null
            };
            switch (rawValue) {
                case "true":
                    output.value = true;
                    break;
                case "false":
                    output.value = false;
                    break;
                case "null":
                    output.value = null;
                    break;
                case "undefined":
                case "":
                    output.value = undefined;
                    break;
                default:
                    if (isNaN(Number(rawValue))) {
                        try {
                            output.value = decodeURIComponent(rawValue);
                        }
                        catch (error) {
                            output.value = rawValue;
                        }
                    }
                    else {
                        output.value = Number(rawValue);
                    }
                    break;
            }
            return output;
        });
    }
    static getURLParamsAsObject() {
        const urlParamsArray = Utils.getURLParamsAsArray(), output = {};
        urlParamsArray.forEach((param) => {
            output[param.key] = param.value;
        });
        return output;
    }
    static getDMParams() {
        const supposedDMParameters = window[MarketingClientConstants.dmParametersGlobalKey], resultDMParameters = {};
        if (Utils.isNullOrUndefined(supposedDMParameters)) {
            return null;
        }
        Object.keys(supposedDMParameters).forEach(key => {
            if (!Utils.isObject(supposedDMParameters[key]) && supposedDMParameters[key] !== undefined) {
                resultDMParameters[key] = supposedDMParameters[key];
            }
        });
        return resultDMParameters;
    }
    static getURLParamFromEventTarget(urlWithParams, name) {
        var _a;
        if (urlWithParams) {
            const url = new URL(urlWithParams), urlParams = new URLSearchParams(url.search || "");
            if (urlParams.has(name)) {
                return (_a = urlParams.get(name)) !== null && _a !== void 0 ? _a : "";
            }
        }
        return "";
    }
    static validateEmail(email) {
        const trimmedEmail = email.trim();
        const domain = trimmedEmail.split("@")[1];
        if (!trimmedEmail.length || !validEmailRegexp.test(trimmedEmail.toLowerCase()) || BannedEmailDomains.indexOf(domain) !== -1) {
            return false;
        }
        return true;
    }
    static showContainer(element, className = MarketingClientConstants.hiddenClassName) {
        if (element) {
            element.classList.remove(className);
        }
    }
    static hideContainer(element, className = MarketingClientConstants.hiddenClassName) {
        if (element) {
            element.classList.add(className);
        }
    }
    static prepareCtaObject(element) {
        var _a, _b, _c;
        const data = {}, ctaElement = DOMHelper.querySelector(`[${CtaObjectConstants.dataCta}], [${CtaObjectConstants.dataCtaCategory}]`, element), cta = (_a = ctaElement === null || ctaElement === void 0 ? void 0 : ctaElement.getAttribute(CtaObjectConstants.dataCta)) !== null && _a !== void 0 ? _a : element.getAttribute(CtaObjectConstants.dataCta), ctaCategory = (_b = ctaElement === null || ctaElement === void 0 ? void 0 : ctaElement.getAttribute(CtaObjectConstants.dataCtaCategory)) !== null && _b !== void 0 ? _b : element.getAttribute(CtaObjectConstants.dataCtaCategory), customCtaCategory = (_c = ctaElement === null || ctaElement === void 0 ? void 0 : ctaElement.getAttribute(CtaObjectConstants.dataCustomCtaCategory)) !== null && _c !== void 0 ? _c : element.getAttribute(CtaObjectConstants.dataCustomCtaCategory);
        if (cta && Utils.stringNotEmpty(cta)) {
            data.cta = cta;
        }
        if (ctaCategory && Utils.stringNotEmpty(ctaCategory)) {
            data.ctaCategory = ctaCategory;
        }
        if (customCtaCategory && Utils.stringNotEmpty(customCtaCategory)) {
            data.customCtaCategory = customCtaCategory;
        }
        return data;
    }
    static prepareCollectionIdsObject(element) {
        const data = {}, elementCampaignId = element.getAttribute(IdObjectConstants.dataCampaignId), elementActionId = element.getAttribute(IdObjectConstants.dataActionId), elementTouchPointId = element.getAttribute(IdObjectConstants.dataTouchPointId);
        if (elementCampaignId) {
            data.campaignId = elementCampaignId;
        }
        if (elementActionId) {
            data.actionId = elementActionId;
        }
        if (elementTouchPointId) {
            data.touchPointId = elementTouchPointId;
        }
        return data;
    }
    static prepareRegistrationWallObject(element) {
        const data = {}, rwElement = DOMHelper.querySelector(`[${RwObjectConstants.dataArticleCounter}], [${RwObjectConstants.dataStage}], [${RwObjectConstants.dataStageEndDate}]`, element), articleCounter = rwElement === null || rwElement === void 0 ? void 0 : rwElement.getAttribute(RwObjectConstants.dataArticleCounter), stage = rwElement === null || rwElement === void 0 ? void 0 : rwElement.getAttribute(RwObjectConstants.dataStage), stageEndDate = rwElement === null || rwElement === void 0 ? void 0 : rwElement.getAttribute(RwObjectConstants.dataStageEndDate);
        if (articleCounter) {
            data.articleCounter = articleCounter;
        }
        if (stage) {
            data.stage = stage;
        }
        if (stageEndDate) {
            data.stageEndDate = stageEndDate;
        }
        return data;
    }
    static smoothScrollTo(top) {
        window.scrollTo({
            top,
            behavior: "smooth"
        });
    }
    static attributeToNumber(attribute) {
        if (!attribute) {
            return undefined;
        }
        const value = parseInt(attribute, 10);
        return !Number.isNaN(value) ? value : undefined;
    }
    static showElementAfterTimeout(doAfter, timeout) {
        let showBoxTimeout, boxIsVisible = false, timeoutInMs = 0;
        if (timeout) {
            timeoutInMs = timeout * 1000;
        }
        function setTimeout() {
            showBoxTimeout = window.setTimeout(function () {
                showElement();
            }, timeoutInMs);
        }
        function resetTimeout() {
            window.clearTimeout(showBoxTimeout);
        }
        function activeListener() {
            if (!boxIsVisible) {
                resetTimeout();
                setTimeout();
            }
        }
        function showElement() {
            boxIsVisible = true;
            doAfter();
            document.removeEventListener(EventType.MOUSEWHEEL, activeListener);
            document.removeEventListener(EventType.MOUSEMOVE, activeListener);
            document.removeEventListener(EventType.TOUCHMOVE, activeListener);
            document.removeEventListener(EventType.SCROLL, activeListener);
        }
        document.addEventListener(EventType.MOUSEWHEEL, activeListener);
        document.addEventListener(EventType.MOUSEMOVE, activeListener);
        document.addEventListener(EventType.TOUCHMOVE, activeListener);
        document.addEventListener(EventType.SCROLL, activeListener);
        setTimeout();
    }
    static showElementAfterMutation(callback, mutationValue = DdboxConstants.mutationValue["75"]) {
        function activeListener(mutationsList) {
            for (const mutation of mutationsList) {
                if (mutation.oldValue === mutationValue) {
                    callback();
                    return;
                }
            }
        }
        return activeListener;
    }
    static detectScrollUp(callback, distance = DdboxConstants.defaultScrollUpDistance) {
        if (distance === 0) {
            callback();
            return;
        }
        let lastScrollPosition = window.scrollY || document.documentElement.scrollTop;
        const onScroll = () => {
            let currentScrollPosition = window.scrollY || document.documentElement.scrollTop;
            // scroll up more than {distance} in px
            if (lastScrollPosition - currentScrollPosition > distance) {
                window.removeEventListener("scroll", onScroll);
                callback();
            }
            // scroll down
            if (currentScrollPosition > lastScrollPosition) {
                lastScrollPosition = currentScrollPosition;
            }
        };
        window.addEventListener("scroll", onScroll);
    }
    static changeFormatImg(element, type) {
        if (element) {
            return element.replace("X", `${type}`);
        }
    }
    static addReferrerAndLpParams(url) {
        try {
            const { origin, pathname } = window.location, referrer = `${origin}${pathname}`, newUrl = new URL(url);
            newUrl.searchParams.set("LP", "false");
            newUrl.searchParams.set("articleReferer", referrer);
            return newUrl.href;
        }
        catch (e) {
            console.warn(e);
            return url;
        }
    }
}
