import ls from "@livesession/sdk";
export class LiveSession {
    constructor(element) {
        var _a;
        this.trackId = (_a = process.env["LIVE_SESSION_TRACK_ID"]) !== null && _a !== void 0 ? _a : '';
        this.intersectionObserverOptions = {
            rootMargin: '0px',
            threshold: 0,
        };
        this.rootHostname = '.wyborcza.pl';
        this.element = element;
    }
    init() {
        if (!this.element || !this.trackId)
            return;
        this.observer = new IntersectionObserver((entries) => this.onIntersect(entries), this.intersectionObserverOptions);
        this.observer.observe(this.element);
    }
    onIntersect(entries) {
        entries.forEach((entry) => {
            this.liveSessionInit(entry);
        });
    }
    liveSessionInit(entry) {
        if (!entry.isIntersecting)
            return;
        ls.init(this.trackId, { keystrokes: false, rootHostname: this.rootHostname });
        ls.newPageView();
        this.unobserve();
    }
    unobserve() {
        this.observer.unobserve(this.element);
    }
}
