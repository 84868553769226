var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DOMHelper } from "./helpers/dom-helper";
import { NEXT_STEP, OnboardingConstants, PREV_STEP } from "./constants/onboarding-constants";
import { Slider } from "./utils/slider";
import { EventType } from "./constants/event-type-constants";
import { httpInstance } from "./http/http";
import { WYBORCZA_PL } from "./marketing-client";
import { sha256 } from "./helpers/onboarding-helper";
import { BigData } from "./marketing-client-bigdata";
import { BigDataEventConstants } from "./constants/big-data-constants";
export class OnboardingNewsletter {
    constructor(onboarding) {
        var _a;
        this.agreements = [];
        this.email = ((_a = WYBORCZA_PL === null || WYBORCZA_PL === void 0 ? void 0 : WYBORCZA_PL.userInfo) === null || _a === void 0 ? void 0 : _a.email) || "";
        this.path = "https://api-newsletter.wyborcza.pl/cors/profiles";
        this.checkboxSelector = ".ddbox-onboarding__newsletters__checkbox";
        this.idPrefixSelector = "ddboxOnboardingNewsletterCheckbox";
        this.prevBtnSelector = ".mc-nlt-back-btn";
        this.nextBtnSelector = ".mc-nlt-forward-btn";
        this.finishButtonSelector = ".mc-nlt-save-btn";
        this.slideItemSelector = ".ddbox-onboarding__newsletter-item";
        this.slidePrevBtn = ".ddbox-onboarding__newsletter-slider-arrow-left";
        this.slideNextBtn = ".ddbox-onboarding__newsletter-slider-arrow-right";
        this.isCheckedClassName = "is-checked";
        this.prevButton = DOMHelper.querySelector(this.prevBtnSelector);
        this.nextButton = DOMHelper.querySelector(this.nextBtnSelector);
        this.finishButton = DOMHelper.querySelector(this.finishButtonSelector);
        this.prevStepEvent = new Event(PREV_STEP);
        this.nextStepEvent = new Event(NEXT_STEP);
        this.slider = new Slider({
            slidesSelector: this.slideItemSelector,
            prevButtonSelector: this.slidePrevBtn,
            nextButtonSelector: this.slideNextBtn
        });
        this.onboarding = onboarding;
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            this.initializeCheckboxes();
            this.bindCheckboxEvents();
            this.bindNavigationEvents();
            this.bindFinishEvent();
            yield this.getAgreements();
        });
    }
    initializeCheckboxes() {
        this.checkboxes = DOMHelper.querySelectorAsArray(this.checkboxSelector);
    }
    bindCheckboxEvents() {
        var _a;
        (_a = this.checkboxes) === null || _a === void 0 ? void 0 : _a.forEach((checkbox, index) => {
            checkbox === null || checkbox === void 0 ? void 0 : checkbox.addEventListener(EventType.CLICK, () => {
                var _a;
                this.agreements = this.toggleAgreements(Number(checkbox.value));
                (_a = DOMHelper.querySelectorAsArray(this.slideItemSelector)[index]) === null || _a === void 0 ? void 0 : _a.classList.toggle(this.isCheckedClassName);
            });
        });
    }
    bindNavigationEvents() {
        var _a, _b;
        (_a = this.nextButton) === null || _a === void 0 ? void 0 : _a.addEventListener(EventType.CLICK, () => __awaiter(this, void 0, void 0, function* () {
            this.onboarding.sendBigDataEvent(OnboardingConstants.nextStepEvent);
            window.dispatchEvent(this.nextStepEvent);
            yield this.subscribe();
        }));
        (_b = this.prevButton) === null || _b === void 0 ? void 0 : _b.addEventListener(EventType.CLICK, () => {
            this.onboarding.sendBigDataEvent(OnboardingConstants.prevStepEvent);
            window.dispatchEvent(this.prevStepEvent);
        });
    }
    bindFinishEvent() {
        var _a;
        (_a = this.finishButton) === null || _a === void 0 ? void 0 : _a.addEventListener(EventType.CLICK, () => {
            this.onboarding.finishOnboarding();
        });
    }
    toggleAgreements(val) {
        return this.agreements.includes(val) ? this.agreements.filter(el => el !== val) : [...this.agreements, val];
    }
    getActiveAgreements() {
        return this.checkboxes.reduce((agreements, checkbox) => checkbox.checked ? [...agreements, checkbox.value] : agreements, []);
    }
    getAgreements() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.email)
                return;
            yield httpInstance
                .get({
                url: `${this.path}/${this.email}`,
                withCredentials: false,
            })
                .then(({ data }) => {
                var _a;
                this.agreements = data;
                (_a = this.checkboxes) === null || _a === void 0 ? void 0 : _a.forEach((checkbox, index) => {
                    var _a;
                    if (this.agreements.includes(Number(checkbox.value))) {
                        checkbox.checked = true;
                        (_a = DOMHelper.querySelectorAsArray(this.slideItemSelector)[index]) === null || _a === void 0 ? void 0 : _a.classList.toggle(this.isCheckedClassName);
                    }
                });
            });
        });
    }
    subscribe() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.email)
                return;
            const data = {
                email: this.email,
                newsletters: this.agreements,
                confirmed: false
            };
            yield httpInstance
                .post({
                url: `${this.path}/override`,
                data: data,
                withCredentials: false,
            })
                .then(() => __awaiter(this, void 0, void 0, function* () {
                var _a;
                if (!((_a = this.getActiveAgreements()) === null || _a === void 0 ? void 0 : _a.length))
                    return;
                BigData.sendDataOceanEvent(BigDataEventConstants.onAddingToNewsletter, {
                    hm: yield sha256(this.email),
                    newsletter: this.getActiveAgreements()
                });
            }));
        });
    }
}
