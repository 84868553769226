export const BigDataEventConstants = {
    onDisplay: "470",
    onLoad: "471",
    onClick: "472",
    onAddingToNewsletter: "373",
    onLoadingHardClosing: "380"
};
export const BigDataClickID = {
    createAccount: "klik załóż konto",
    purchaseSubscription: "klik kup prenumeratę",
    login: "klik zaloguj przez",
    logout: "klik wyloguj",
    unlockAndRead: "klik odblokuj i czytaj",
    resendEmail: "klik wyślij ponownie email",
    startProcessAgain: "klik rozpocznij proces jeszcze raz",
    helpContact: "klik kontakt pomoc",
    unlockArticle: "klik w odblokuj artykuł",
    signupAndContinue: "klik zapisz się i przejdź dalej",
    confirmationEmailAddress: "Potwierdzenie adersu email",
    checkMailbox: "Sprawdź skrzynkę"
};
