import { EventType } from "./constants/event-type-constants";
import { SwimmerConstants } from "./constants/swimmer-constants";
import { MarketingClientAttributesConstants } from "./constants/marketing-client-attributes-constants";
import { MarketingClientConstants } from "./constants/marketing-client-constants";
import { DOMHelper } from "./helpers/dom-helper";
import { Utils } from "./utils/utils";
import { Utilization } from "./marketing-client-utilization";
import { httpInstance } from "./http/http";
import { TimerCountdown } from "./helpers/timer-countdown";
import { NewsSwimmerConstants } from "./constants/news-swimmer-constants";
import { BigData } from "./marketing-client-bigdata";
import { BigDataEventConstants } from "./constants/big-data-constants";
import { BigDataHelper } from "./helpers/big-data-helper";
import { DdboxConstants, ZcpClickIds } from "./constants/ddbox-constants";
export class Swimmer {
    constructor(isMobile, capping) {
        this.isMobile = isMobile;
        this.capping = capping;
        this.hiding = false;
        this.rwSwimmerID = "rw-swimmer";
        this.copSwimmerID = "content-onexit-popup-swimmer";
        this.currentBoxToDisplay = 0;
        this.utilizationObject = new Utilization(httpInstance);
        this.classSwimmerPromoBoxButton = ".nw_2021_swimmer-button-wrapper";
    }
    init() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        const swimmerPlaceholders = DOMHelper.querySelectorAsArray(SwimmerConstants.swimmerSelector), placecholderHP = swimmerPlaceholders.find(swimmerPlaceholder => this.placecholderIsSwimmerHP(swimmerPlaceholder)), swimmerHPIsNotEmpty = !!placecholderHP && !!((_a = DOMHelper.querySelector("section", placecholderHP)) === null || _a === void 0 ? void 0 : _a.innerHTML.length), ddboxIsVisible = this.ddboxIsVisible();
        this.initializePadlock();
        this.initializeWidgetHp();
        this.initializeSwimmerPromoBox();
        const fullContentVisibleForUser = (_e = (_d = (_c = (_b = window["wyborcza_pl"]) === null || _b === void 0 ? void 0 : _b.MarketingClient) === null || _c === void 0 ? void 0 : _c.userData) === null || _d === void 0 ? void 0 : _d.profile) === null || _e === void 0 ? void 0 : _e.fullContentVisibleForUser;
        const linkToPadlock = (_g = (_f = this.swimmerPromoBox) === null || _f === void 0 ? void 0 : _f.dataset) === null || _g === void 0 ? void 0 : _g.linkPadlock;
        const hideSwimmerMobile = (_j = (_h = this.swimmerPromoBox) === null || _h === void 0 ? void 0 : _h.dataset) === null || _j === void 0 ? void 0 : _j.hideSwimmermobile;
        const isSwimmerVisible = !(linkToPadlock === "true" && (fullContentVisibleForUser === true || fullContentVisibleForUser === undefined));
        swimmerPlaceholders.forEach(swimmerPlaceholder => {
            const swimmer = DOMHelper.querySelector("section", swimmerPlaceholder), isSwimmerHp = !!placecholderHP && this.placecholderIsSwimmerHP(swimmerPlaceholder), isSwimmerShouldBeVisible = swimmerHPIsNotEmpty ? isSwimmerHp : !ddboxIsVisible;
            if (isSwimmerShouldBeVisible && !!swimmer && isSwimmerVisible) {
                this.sendCapping(swimmer);
                this.prepareSwimmer(swimmer, isSwimmerHp);
            }
            else {
                swimmer === null || swimmer === void 0 ? void 0 : swimmer.classList.remove(SwimmerConstants.visibleClass);
                Utils.hideContainer(swimmer, MarketingClientConstants.dHiddenClassName);
            }
        });
        this.initializeNewsSwimmer();
        if (!ddboxIsVisible) {
            const swimmer = DOMHelper.querySelector(SwimmerConstants.timerDateSelector);
            if (linkToPadlock === "true") {
                this.bindEventsHideSwimmerAtHeightOfPlaceholderId();
                this.bindEventsSwimmerPromoBoxScrollToPadlock();
            }
            if (hideSwimmerMobile === "true" && this.isMobile) {
                this.bindEventsHideSwimmerAtHeightOfPlaceholderId();
            }
            this.initializeTimer((_k = swimmer === null || swimmer === void 0 ? void 0 : swimmer.dataset) === null || _k === void 0 ? void 0 : _k.timerSwimmer);
        }
    }
    initializeNewsSwimmer() {
        this.initializeNewsSwimmerElements();
        this.bindNewsSwimmerEvents();
    }
    initializeNewsSwimmerElements() {
        this.newsSwimmer = DOMHelper.querySelector(NewsSwimmerConstants.newsSwimmerClass);
        this.newsButtonClose = DOMHelper.querySelector(NewsSwimmerConstants.newsButtonCloseClass);
        this.newsSwimmerWrapper = DOMHelper.querySelector(NewsSwimmerConstants.newsSwimmerWrapperClass);
        this.newsSwimmerCollapsed = DOMHelper.querySelector(NewsSwimmerConstants.newsSwimmerCollapsedClass);
        this.newsSwimmerArrowSvg = DOMHelper.querySelector(NewsSwimmerConstants.newsSwimmerArrowSvg);
        this.newsSwimmerStep2Close = DOMHelper.querySelector(NewsSwimmerConstants.newsSwimmerStep2Close);
    }
    bindNewsSwimmerEvents() {
        this.bindNewsSwimmerStep2CloseEvent();
        this.bindNewsButtonCloseEvent();
        this.bindNewsSwimmerArrowSvgEvent();
    }
    bindNewsSwimmerStep2CloseEvent() {
        if (this.newsSwimmerStep2Close) {
            this.newsSwimmerStep2Close.addEventListener(EventType.CLICK, (event) => {
                var _a;
                (_a = this.newsSwimmer) === null || _a === void 0 ? void 0 : _a.classList.remove(SwimmerConstants.visibleClass);
            });
        }
    }
    bindNewsButtonCloseEvent() {
        if (this.newsButtonClose) {
            this.newsButtonClose.addEventListener(EventType.CLICK, (event) => {
                var _a, _b, _c;
                event.preventDefault();
                (_a = this.newsSwimmerWrapper) === null || _a === void 0 ? void 0 : _a.classList.add(SwimmerConstants.hiddenClass);
                (_b = this.newsSwimmer) === null || _b === void 0 ? void 0 : _b.classList.add(SwimmerConstants.visibleClass);
                (_c = this.newsSwimmerCollapsed) === null || _c === void 0 ? void 0 : _c.classList.remove(SwimmerConstants.hiddenClass);
            });
        }
    }
    bindNewsSwimmerArrowSvgEvent() {
        if (this.newsSwimmerArrowSvg) {
            this.newsSwimmerArrowSvg.addEventListener(EventType.CLICK, (event) => {
                var _a, _b;
                (_a = this.newsSwimmerWrapper) === null || _a === void 0 ? void 0 : _a.classList.remove(SwimmerConstants.hiddenClass);
                (_b = this.newsSwimmerCollapsed) === null || _b === void 0 ? void 0 : _b.classList.add(SwimmerConstants.hiddenClass);
            });
        }
    }
    initializeSwimmerPromoBox() {
        this.swimmerPromoBox = DOMHelper.querySelector(SwimmerConstants.timerDateSelector);
        this.swimmerPromoBoxButton = DOMHelper.querySelector(this.classSwimmerPromoBoxButton, this.swimmerPromoBox);
    }
    initializePadlock() {
        this.padlockElement = DOMHelper.querySelector(SwimmerConstants.placeholderPadlock);
    }
    initializeWidgetHp() {
        this.widgetHpElement = DOMHelper.querySelector(SwimmerConstants.placeholderWidgetHp);
    }
    bindEventsSwimmerPromoBoxScrollToPadlock() {
        if (this.swimmerPromoBoxButton) {
            this.swimmerPromoBoxButton.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.scrollToPadlockForSwimmerPromoBox();
            });
        }
    }
    bindEventsHideSwimmerAtHeightOfPlaceholderId() {
        if (this.swimmerPromoBox) {
            document.addEventListener(EventType.SCROLL, () => {
                this.toggleSwimmer();
            });
        }
    }
    toggleSwimmer() {
        var _a, _b;
        const element = this.padlockElement || this.widgetHpElement;
        const swimmerTop = (_a = this.swimmerPromoBox) === null || _a === void 0 ? void 0 : _a.offsetTop, sectionTop = element === null || element === void 0 ? void 0 : element.getBoundingClientRect().top, swimmerHeight = ((_b = this.swimmerPromoBox) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().height) || 250, height = this.getScreenHeight();
        if (sectionTop && swimmerTop && sectionTop <= swimmerTop) {
            this.closeSwimmerPromoBox();
        }
        else if (sectionTop && (window.scrollY + (height - swimmerHeight) + 20) < sectionTop) {
            this.showSwimmerPromoBox();
        }
    }
    getScreenHeight() {
        return window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;
    }
    scrollToPadlockForSwimmerPromoBox() {
        if (this.padlockElement) {
            requestAnimationFrame(() => {
                setTimeout(() => {
                    this.scrollToElement();
                    this.closeSwimmerPromoBox();
                }, 100);
            });
        }
    }
    scrollToElement() {
        if (this.padlockElement) {
            const top = this.padlockElement.offsetTop - window.innerHeight / 3;
            Utils.smoothScrollTo(top);
        }
    }
    closeSwimmerPromoBox() {
        var _a;
        (_a = this.swimmerPromoBox) === null || _a === void 0 ? void 0 : _a.classList.remove(SwimmerConstants.visibleClass);
    }
    showSwimmerPromoBox() {
        var _a, _b;
        if (!((_a = this.swimmerPromoBox) === null || _a === void 0 ? void 0 : _a.classList.contains(SwimmerConstants.closedManuallyClass))) {
            (_b = this.swimmerPromoBox) === null || _b === void 0 ? void 0 : _b.classList.add(SwimmerConstants.visibleClass);
        }
    }
    sendCapping(swimmer, onDisplay = false) {
        this.capping.sendCappingEvent(swimmer, onDisplay);
    }
    ddboxIsVisible() {
        var _a, _b, _c;
        const mainObjectKey = "wyborcza_pl", mainObject = window[mainObjectKey];
        return !!((_c = (_b = (_a = mainObject === null || mainObject === void 0 ? void 0 : mainObject.MarketingClient) === null || _a === void 0 ? void 0 : _a.bannersObject) === null || _b === void 0 ? void 0 : _b.ddbox) === null || _c === void 0 ? void 0 : _c.ddboxIsVisible);
    }
    placecholderIsSwimmerHP(swimmer) {
        return swimmer.getAttribute(MarketingClientAttributesConstants.placeholderId) === SwimmerConstants.swimmerHPSelector;
    }
    prepareSwimmer(swimmer, isSwimmerHP) {
        const padlock = document.querySelector(SwimmerConstants.padlockSelector), isOnExitPopUp = !!swimmer.getAttribute(SwimmerConstants.dataOnExitPopUp) && this.isMobile, isUserInactivityTimeout = !!swimmer.getAttribute(SwimmerConstants.dataUserInactivityTimeout) && this.isMobile, isPromoboxDelay = !!swimmer.getAttribute(SwimmerConstants.dataPromoboxDelay), isZCP = !!swimmer.getAttribute(SwimmerConstants.dataIsZcp), isOnScroll = !!swimmer.getAttribute(SwimmerConstants.dataArticleScroll);
        this.swimmerRW = DOMHelper.getElementById(this.rwSwimmerID);
        this.swimmerCOP = DOMHelper.getElementById(this.copSwimmerID);
        if (this.swimmerCOP) {
            this.initializeCopSwimmerButtons();
            this.initializeCopSwimmerBoxes();
        }
        this.bindActions(swimmer, isZCP);
        if (padlock) {
            this.bindAutoClosing(swimmer);
        }
        if (isOnExitPopUp) {
            this.onExitPopUp(swimmer, isSwimmerHP);
            return;
        }
        if (isPromoboxDelay) {
            this.bindVisibilityForPromoboxDelay(swimmer, isSwimmerHP);
            return;
        }
        if (isUserInactivityTimeout) {
            this.onUserInactivityTimeout(swimmer, isSwimmerHP);
            return;
        }
        if (isOnScroll) {
            this.onScrollActivity(swimmer);
            return;
        }
        if (isSwimmerHP) {
            this.bindAutoClosingForSwimmerHP(swimmer);
            return;
        }
        if (!this.swimmerRW && !isZCP) {
            this.sendCapping(swimmer, true);
        }
    }
    initializeCopSwimmerButtons() {
        this.copButtonCollaps = DOMHelper.getElementById(SwimmerConstants.collapsButtonID);
        this.copButtonArrowUp = DOMHelper.getElementById(SwimmerConstants.arrowUpID);
        this.copButtonArrowLeft = DOMHelper.getElementById(SwimmerConstants.arrowLeftID);
        this.copButtonArrowRight = DOMHelper.getElementById(SwimmerConstants.arrowRightID);
    }
    initializeCopSwimmerBoxes() {
        this.copArticleBoxes = [];
        for (let boxID of SwimmerConstants.articleBoxesID) {
            const box = DOMHelper.getElementById(boxID);
            if (box)
                this.copArticleBoxes.push(box);
        }
    }
    initializeTimer(dateEnd) {
        var _a;
        if (!dateEnd)
            return;
        this.timerCountdown = new TimerCountdown(dateEnd, SwimmerConstants.timerSelector);
        (_a = this.timerCountdown) === null || _a === void 0 ? void 0 : _a.init();
    }
    bindActions(swimmer, isZcp = false) {
        const closeButton = swimmer.querySelector(SwimmerConstants.closeButtonSelector);
        if (closeButton) {
            closeButton.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.closeSwimmerManually(swimmer);
                if (isZcp) {
                    const attribute = BigDataHelper.createAttribute(swimmer, false, ZcpClickIds.closeSwimmer);
                    BigData.sendDataOceanEvent(BigDataEventConstants.onClick, attribute);
                }
            });
        }
        if (this.swimmerCOP)
            this.bindCopSwimmerEvents(swimmer);
    }
    bindCopSwimmerEvents(swimmer) {
        this.bindCopCollaps();
        this.bindCopArrowUp();
        this.bindCopArrowLeft();
        this.bindCopArrowRight();
        this.bindCopAutoRotation(swimmer);
    }
    bindCopCollaps() {
        if (this.copButtonCollaps) {
            this.copButtonCollaps.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.toggleCarousel();
            });
        }
    }
    bindCopArrowUp() {
        if (this.copButtonArrowUp) {
            this.copButtonArrowUp.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.toggleCarousel();
            });
        }
    }
    bindCopArrowLeft() {
        if (this.copButtonArrowLeft) {
            this.copButtonArrowLeft.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                clearInterval(this.copInterval);
                this.previousBox();
            });
        }
    }
    bindCopArrowRight() {
        if (this.copButtonArrowRight) {
            this.copButtonArrowRight.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                clearInterval(this.copInterval);
                this.nextBox();
            });
        }
    }
    bindCopAutoRotation(swimmer) {
        const autoRotate = swimmer.getAttribute(SwimmerConstants.dataAutoRotation), rotationInterval = swimmer.getAttribute(SwimmerConstants.dataRotationInterval), intervalInMiliseconds = (rotationInterval && +rotationInterval > 0) ? (+rotationInterval * 1000) : 4000;
        if (autoRotate === 'true') {
            this.copInterval = setInterval(() => {
                this.nextBox();
            }, intervalInMiliseconds);
        }
    }
    closeSwimmerManually(swimmer) {
        swimmer.classList.remove(SwimmerConstants.visibleClass);
        swimmer.classList.add(SwimmerConstants.closedManuallyClass);
    }
    bindAutoClosing(swimmer) {
        window.addEventListener(EventType.CLICK, () => {
            if (!swimmer.classList.contains(SwimmerConstants.closedManuallyClass)) {
                this.checkSwimmerAutoVisibility(swimmer);
            }
        });
    }
    checkSwimmerAutoVisibility(swimmer) {
        var _a;
        const padlockTop = (_a = document.querySelector(SwimmerConstants.padlockSelector)) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().top, swimmerTop = swimmer.getBoundingClientRect().top, windowHeight = window.innerHeight;
        if (padlockTop && padlockTop <= swimmerTop) {
            swimmer.classList.remove(SwimmerConstants.visibleClass);
        }
        if (padlockTop && padlockTop > windowHeight) {
            swimmer.classList.add(SwimmerConstants.visibleClass);
        }
    }
    onExitPopUp(swimmer, isSwimmerHP) {
        const attribute = swimmer.getAttribute(SwimmerConstants.dataOnExitPopUpValue), minWindowScroll = Utils.attributeToNumber(attribute);
        this.bindAutoClosingForExitPopUp(swimmer, isSwimmerHP, minWindowScroll);
    }
    onUserInactivityTimeout(swimmer, isSwimmerHP) {
        const attribute = swimmer.getAttribute(SwimmerConstants.dataUserInactivityTimeoutValue), timeout = Utils.attributeToNumber(attribute), showDdboxAndSendCapping = () => {
            swimmer.classList.add(SwimmerConstants.visibleClass);
            this.sendCapping(swimmer, true);
            if (isSwimmerHP) {
                this.bindAutoClosingForSwimmerHP(swimmer);
            }
        };
        swimmer.classList.remove(SwimmerConstants.visibleClass);
        Utils.showElementAfterTimeout(showDdboxAndSendCapping, timeout);
    }
    onScrollActivity(swimmer) {
        swimmer.classList.remove(SwimmerConstants.visibleClass);
        const elementToObserve = DOMHelper.querySelector(`[${DdboxConstants.bdScrollPt}]`);
        const scrollValue = swimmer.getAttribute(SwimmerConstants.dataArticleScrollValue) || "25";
        if (!elementToObserve) {
            return;
        }
        const observer = new MutationObserver(Utils.showElementAfterMutation(() => Utils.detectScrollUp(() => {
            swimmer.classList.add(SwimmerConstants.visibleClass);
            this.sendCapping(swimmer, true);
        }), DdboxConstants.mutationValue[scrollValue]));
        observer.observe(elementToObserve, {
            attributes: true,
            attributeFilter: [DdboxConstants.bdScrollPt],
            attributeOldValue: true,
        });
    }
    bindVisibilityForPromoboxDelay(swimmer, isSwimmerHP) {
        const showAndSendCapping = () => {
            swimmer.classList.add(SwimmerConstants.visibleClass);
            ;
            this.sendCapping(swimmer, true);
            if (isSwimmerHP) {
                this.bindAutoClosingForSwimmerHP(swimmer);
            }
        }, attribute = swimmer.getAttribute(SwimmerConstants.dataPromoboxDelayValue), attributeDelay = Utils.attributeToNumber(attribute), delay = attributeDelay && attributeDelay > 0 ? attributeDelay * 1000 : SwimmerConstants.promoboxDelay;
        swimmer.classList.remove(SwimmerConstants.visibleClass);
        setTimeout(() => {
            showAndSendCapping();
        }, delay);
    }
    bindAutoClosingForExitPopUp(swimmer, isSwimmerHP, minWindowScrollY = 800) {
        const screenHeight = window.innerHeight, minHeightForChangeVisibility = (screenHeight / 3);
        let lastScroll = 0, windowScrollY = 0, hasBeenChangedVisibility = false, canChangeVisibility = false, swimmerIsVisible = false;
        minWindowScrollY = minWindowScrollY - minHeightForChangeVisibility;
        if (minWindowScrollY < 1) {
            minWindowScrollY = 1;
        }
        swimmer.classList.remove(SwimmerConstants.visibleClass);
        window.addEventListener(EventType.SCROLL, () => {
            if (window.scrollY > minWindowScrollY) {
                if (window.scrollY > windowScrollY) {
                    swimmerIsVisible = false;
                    lastScroll = window.scrollY;
                }
                else {
                    swimmerIsVisible = true;
                }
                windowScrollY = window.scrollY;
            }
            else {
                swimmerIsVisible = false;
            }
            canChangeVisibility = Math.abs(lastScroll - window.scrollY) > minHeightForChangeVisibility;
            if (swimmerIsVisible && canChangeVisibility && !swimmer.classList.contains(SwimmerConstants.visibleClass) && !hasBeenChangedVisibility) {
                swimmer.classList.add(SwimmerConstants.visibleClass);
                this.sendCapping(swimmer, true);
                hasBeenChangedVisibility = true;
            }
        });
        if (isSwimmerHP) {
            const newMinWindowScrollY = minWindowScrollY + minHeightForChangeVisibility;
            this.bindAutoClosingForSwimmerHP(swimmer, newMinWindowScrollY);
        }
    }
    bindAutoClosingForSwimmerHP(swimmer, minWindowScrollY = 800) {
        let windowScrollY = 0, swimmerHPisVisible = false, canChangeVisibility = false, lastScroll = 0, cappingIsSend = false;
        swimmer.classList.remove(SwimmerConstants.visibleClass);
        window.addEventListener(EventType.SCROLL, () => {
            if (window.scrollY > minWindowScrollY) {
                swimmerHPisVisible = window.scrollY <= windowScrollY;
                windowScrollY = window.scrollY;
            }
            else {
                swimmerHPisVisible = false;
            }
            canChangeVisibility = !this.hiding && Math.abs(lastScroll - window.scrollY) > 40;
            if (swimmerHPisVisible && canChangeVisibility && !swimmer.classList.contains(SwimmerConstants.visibleClass)) {
                this.hiding = true;
                lastScroll = window.scrollY;
                swimmer.classList.add(SwimmerConstants.visibleClass);
                swimmer.classList.remove(SwimmerConstants.animationClass);
                if (!cappingIsSend) {
                    cappingIsSend = true;
                    this.sendCapping(swimmer, true);
                }
                setTimeout(() => {
                    this.hiding = false;
                }, 1000);
            }
            else if (canChangeVisibility && swimmer.classList.contains(SwimmerConstants.visibleClass)) {
                this.hiding = true;
                lastScroll = window.scrollY;
                swimmer.classList.add(SwimmerConstants.animationClass);
                setTimeout(() => {
                    swimmer.classList.remove(SwimmerConstants.visibleClass);
                    this.hiding = false;
                }, 1000);
            }
        });
    }
    toggleCarousel() {
        var _a, _b;
        const copSwimmerCarousel = DOMHelper.querySelector(SwimmerConstants.copSwimmerCarouselSelector);
        copSwimmerCarousel === null || copSwimmerCarousel === void 0 ? void 0 : copSwimmerCarousel.classList.toggle(SwimmerConstants.hiddenClass);
        (_a = this.copButtonCollaps) === null || _a === void 0 ? void 0 : _a.classList.toggle(SwimmerConstants.hiddenClass);
        (_b = this.copButtonArrowUp) === null || _b === void 0 ? void 0 : _b.classList.toggle(SwimmerConstants.hiddenClass);
    }
    nextBox() {
        this.copArticleBoxes[this.currentBoxToDisplay].classList.toggle(SwimmerConstants.hiddenClass);
        if (this.currentBoxToDisplay === this.copArticleBoxes.length - 1) {
            this.copArticleBoxes[0].classList.toggle(SwimmerConstants.hiddenClass);
            this.currentBoxToDisplay = 0;
        }
        else {
            this.copArticleBoxes[this.currentBoxToDisplay + 1].classList.toggle(SwimmerConstants.hiddenClass);
            this.currentBoxToDisplay += 1;
        }
    }
    previousBox() {
        this.copArticleBoxes[this.currentBoxToDisplay].classList.toggle(SwimmerConstants.hiddenClass);
        if (this.currentBoxToDisplay === 0) {
            this.copArticleBoxes[2].classList.toggle(SwimmerConstants.hiddenClass);
            this.currentBoxToDisplay = 2;
        }
        else {
            this.copArticleBoxes[this.currentBoxToDisplay - 1].classList.toggle(SwimmerConstants.hiddenClass);
            this.currentBoxToDisplay -= 1;
        }
    }
}
