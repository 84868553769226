import { ThemeConstants } from "./constants/color-theme-constants";
import { DOMHelper } from "./helpers/dom-helper";
export class Theme {
    init() {
        const element = DOMHelper.querySelector(ThemeConstants.selector), themeName = element === null || element === void 0 ? void 0 : element.dataset.mcThemeName;
        if (themeName && themeName.length > 0) {
            this.setTheme(themeName);
        }
    }
    setTheme(name) {
        const html = DOMHelper.querySelector("html");
        if (html) {
            html.className = html.className.replace(/theme_(\w*)/g, "");
            html.classList.add(name);
        }
    }
}
