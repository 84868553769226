export const ReadLaterConstants = {
    buttonInListClass: "read-later--added",
    capUserSelector: ".cap-account--icon",
    countArticleSelector: ".cap-account--menu-level1-chip",
    localStorageKey: "read-later",
    displayNone: "none",
    emptyString: "",
    articleAddedToStorage: `Artyku\u0142 dodany do schowka`,
    articleDeletedFromStorage: `Artyku\u0142 usuni\u0119ty ze schowka`
};
