import { Ddbox } from "./ddbox";
import { Swimmer } from "./swimmer";
import { MarketingClientShareArticleOld } from "./marketing-client-share-article-old";
import { MarketingClientShareArticle } from "./marketing-client-share-article";
import { Hat } from "./marketing-client-hat";
import { MarketingClientRWFirstPromotion } from "./marketing-client-rw-first-promotion";
import { MarketingClientRWSecondPromotion } from "./marketing-client-rw-second-promotion";
import { MarketingClientRWOwner } from "./marketing-client-rw-owner";
import { OneDayPass } from "./marketing-client-one-day-pass";
import { OneDayPassOld } from "./marketing-client-one-day-pass-old";
import { DOMHelper } from "./helpers/dom-helper";
import { RwSelector } from "./constants/rw-object.constants";
import { SwfSelector } from "./constants/swf-constants";
import RecommendationsCop from "./utils/recommendations-cop/recommendations-cop";
import { Theme } from "./marketing-client-theme";
import { Selector } from "./constants/one-day-pass-old-constants";
import { ClosingWithoutLp } from "./marketing-client-closing-without-lp";
import { AdsRewarded } from "./utils/ads-rewarded";
import { HardClosing } from "./marketing-client-hard-closing";
import { NewsletterSaver } from "./marketing-client-newsletter-saver";
import { Padlock } from "./marketing-client-padlock";
import { ZCPDdbox } from "./marketing-client-zcp";
import { Onboarding } from "./marketing-client-onboarding";
import { ZCPHat } from "./marketing-client-zcp-hat";
import { ZCPSwimmer } from "./marketing-client-zcp-swimmer";
import { NewsletterSaverHp } from "./marketing-client-newsletter-saver-hp";
import { WidgetHpPhoto } from "./marketing-client-widget-hp-photo";
export class MarketingClientBanners {
    constructor(capping, utilization, isMobile) {
        this.colotTheme = new Theme();
        this.ddbox = new Ddbox(isMobile, capping);
        this.hat = new Hat(isMobile);
        this.swimmer = new Swimmer(isMobile, capping);
        this.marketingClientShareArticleOld = new MarketingClientShareArticleOld(utilization);
        this.marketingClientShareArticle = new MarketingClientShareArticle(utilization);
        this.marketingClientRWFirstPromotion = new MarketingClientRWFirstPromotion(capping, utilization);
        this.marketingClientRWSecondPromotion = new MarketingClientRWSecondPromotion(capping, utilization);
        this.marketingClientRWOwner = new MarketingClientRWOwner(capping, utilization);
        this.oneDayPass = new OneDayPass(capping, utilization);
        this.oneDayPassOld = new OneDayPassOld(capping, utilization);
        this.recommendationsCop = new RecommendationsCop(isMobile);
        this.closingWithoutLp = new ClosingWithoutLp(isMobile);
        this.hardClosing = new HardClosing(isMobile);
        this.adsRewarded = new AdsRewarded();
        this.newsletterSaver = new NewsletterSaver(capping, utilization);
        this.padlock = new Padlock();
        this.zcp = new ZCPDdbox(this.ddbox, utilization);
        this.zcpHat = new ZCPHat(this.ddbox, utilization);
        this.zcpSwimmer = new ZCPSwimmer(this.ddbox, utilization, capping);
        this.onboarding = new Onboarding(this.ddbox, utilization);
        this.newsletterSaverHp = new NewsletterSaverHp();
        this.widgetHpPhoto = new WidgetHpPhoto();
    }
    init() {
        this.colotTheme.init();
        this.ddbox.init();
        this.swimmer.init();
        this.recommendationsCop.init(this.ddbox.ddboxIsVisible);
        this.hat.init();
        this.closingWithoutLp.init();
        this.initSwf();
        this.initRw();
        this.initOneDayPass();
        this.initHardClosing();
        this.adsRewarded.init();
        this.newsletterSaver.init();
        this.padlock.init();
        this.zcpSwimmer.init();
        this.zcpHat.init();
        this.zcp.init();
        this.onboarding.init();
        this.newsletterSaverHp.init();
        this.widgetHpPhoto.init();
    }
    initRw() {
        const rwFirstPromotionDom = DOMHelper.querySelector(RwSelector.rwFirstPromotionSelector), rwSecondPromotionDom = DOMHelper.querySelector(RwSelector.rwSecondPromotionSelector);
        if (rwFirstPromotionDom) {
            this.marketingClientRWFirstPromotion.init();
        }
        else if (rwSecondPromotionDom) {
            this.marketingClientRWSecondPromotion.init();
        }
        else {
            this.marketingClientRWOwner.init();
        }
    }
    initSwf() {
        const swfOld = DOMHelper.querySelector(SwfSelector.oldSwfSelector);
        if (swfOld) {
            this.marketingClientShareArticleOld.init();
        }
        else {
            this.marketingClientShareArticle.init();
        }
    }
    initOneDayPass() {
        const oneDayPassOld = DOMHelper.querySelector(Selector.closeActivation);
        if (oneDayPassOld) {
            this.oneDayPassOld.init();
        }
        else {
            this.oneDayPass.init();
        }
    }
    initHardClosing() {
        const section = DOMHelper.querySelector(".nw_2023_hard_closing");
        section === null || section === void 0 ? void 0 : section.classList.add("hidden");
        this.hardClosing.init();
    }
}
