import { BigDataEventConstants } from "./constants/big-data-constants";
import { DdboxConstants, ZcpClickIds } from "./constants/ddbox-constants";
import { EventType } from "./constants/event-type-constants";
import { MarketingClientConstants } from "./constants/marketing-client-constants";
import { UtilizationConstants } from "./constants/marketing-client-utilization-constants";
import { RequestPaths, URLBase } from "./constants/url-constants";
import { BigDataHelper } from "./helpers/big-data-helper";
import { DOMHelper } from "./helpers/dom-helper";
import { httpInstance } from "./http/http";
import { BigData } from "./marketing-client-bigdata";
import { Checkbox } from "./utils/checkbox";
import { LiveSession } from "./utils/livesession";
import { Utils } from "./utils/utils";
export class ZCPDdbox {
    constructor(ddbox, utilization) {
        this.agreementFormId = 87;
        this.isHarmonyTest = Utils.getURLParamsAsObject().enableHarmonyTest;
        this.squidHomeHost = this.isHarmonyTest ? "https://coreos07.wyborcza.pl:8094/" : "https://squid-api.wyborcza.pl/";
        this.squidHomeUrl = `${this.squidHomeHost}squid-home/open/accounts/agreements?formId=${this.agreementFormId}`;
        this.agreementUrl = `${this.isHarmonyTest ? URLBase.TEST : URLBase.PROD}${RequestPaths.AGREEMENT}`;
        this.classPrefix = ".ddbox-zcp";
        this.classSuffix = {
            modalOpen: "-modal-open-button",
            modalClose: "-modal-close",
            modal: "-modal",
            ddboxClose: "-ddbox-close",
            accept: "-accept-button",
            textarea: "-textarea",
            helpLink: "__right-box__help"
        };
        this.idCheckbox = "ddbox-zcp-checkbox";
        this.Constants = {
            acceptChangesAndConsent: "AKCEPTUJĘ ZMIANY I WYRAŻAM ZGODĘ",
            acceptChanges: "AKCEPTUJĘ ZMIANY",
            close: "zamknij",
            dontAgree: "Mimo to nie wyrażam zgody"
        };
        this.isZcp = false;
        this.isLoop = false;
        this.clickFromX = false;
        this.ddbox = ddbox;
        this.utilization = utilization;
    }
    init() {
        this.initializeDom();
        if (!this.isZcp) {
            return;
        }
        this.getAgreementForm();
        this.bindEvents();
        this.initializeLiveSession();
    }
    initializeDom() {
        this.initializeDdbox();
        this.initializeTextArea();
        this.initializeCheckbox();
        this.initializeAcceptButton();
        this.initializeCancelButton();
        this.initializeModal();
        this.initializeBackModalButton();
        this.initializeCancelModalButton();
        this.initializeHelpLink();
    }
    initializeDdbox() {
        var _a, _b;
        this.box = DOMHelper.querySelector(DdboxConstants.class);
        this.closeButton = this.box ? DOMHelper.querySelector(DdboxConstants.closeButton, this.box) : null;
        this.isZcp = !!((_a = this.box) === null || _a === void 0 ? void 0 : _a.dataset.ddboxWithZcp);
        this.isLoop = !!((_b = this.box) === null || _b === void 0 ? void 0 : _b.dataset.ddboxLoop);
    }
    initializeTextArea() {
        this.agreementTextArea = DOMHelper.querySelector(`${this.classPrefix}${this.classSuffix.textarea}`);
    }
    initializeCheckbox() {
        this.checkbox = new Checkbox(this.idCheckbox, this.toggleAcceptButtonText.bind(this));
    }
    initializeAcceptButton() {
        this.acceptButton = DOMHelper.querySelector(`${this.classPrefix}${this.classSuffix.accept}`);
    }
    initializeModal() {
        this.modal = DOMHelper.querySelector(`${this.classPrefix}${this.classSuffix.modal}`);
    }
    initializeCancelButton() {
        this.cancelButton = DOMHelper.querySelector(`${this.classPrefix}${this.classSuffix.modalOpen}`);
    }
    initializeBackModalButton() {
        this.backModalButton = DOMHelper.querySelector(`${this.classPrefix}${this.classSuffix.modalClose}`);
    }
    initializeHelpLink() {
        this.helpLink = DOMHelper.querySelector(`${this.classPrefix}${this.classSuffix.helpLink} a`);
    }
    initializeCancelModalButton() {
        this.cancelModalButton = DOMHelper.querySelector(`${this.classPrefix}${this.classSuffix.ddboxClose}`);
    }
    initializeLiveSession() {
        if (!this.box)
            return;
        new LiveSession(this.box).init();
    }
    bindEvents() {
        this.bindEventToAcceptAgreements();
        this.bindEventsToShowModal();
        this.bindEventsToHideModal();
        this.bindEventsToCloseDdbox();
        this.bindEventsToCloseDdboxFromX();
        this.bindEventsToHelpLink();
    }
    bindEventToAcceptAgreements() {
        if (this.acceptButton) {
            this.acceptButton.addEventListener(EventType.CLICK, () => {
                this.setAgreement();
            });
        }
    }
    bindEventsToShowModal() {
        if (this.cancelButton) {
            this.cancelButton.addEventListener(EventType.CLICK, () => {
                this.clickFromX = false;
                this.setCancelModalButtonText(this.Constants.dontAgree);
                this.showModal();
                this.sendBigDataEvent(ZcpClickIds.doNotConsent);
            });
        }
    }
    bindEventsToHideModal() {
        if (this.backModalButton) {
            this.backModalButton.addEventListener(EventType.CLICK, () => {
                this.hideModal();
                this.sendBigDataEvent(ZcpClickIds.back);
            });
        }
    }
    bindEventsToCloseDdbox() {
        if (this.cancelModalButton) {
            this.cancelModalButton.addEventListener(EventType.CLICK, () => {
                this.closeDdbox();
                this.sendBigDataEvent(this.clickFromX ? ZcpClickIds.closeInModal : ZcpClickIds.inAnyCaseDoNotConsent);
            });
        }
    }
    bindEventsToCloseDdboxFromX() {
        if (this.closeButton) {
            this.closeButton.addEventListener(EventType.CLICK, () => {
                this.clickFromX = true;
                this.setCancelModalButtonText(this.Constants.close);
                this.isLoop ? this.showModal() : this.closeDdbox();
                this.sendBigDataEvent(ZcpClickIds.close);
            });
        }
    }
    bindEventsToHelpLink() {
        var _a;
        if (this.helpLink) {
            const href = (_a = this.helpLink) === null || _a === void 0 ? void 0 : _a.getAttribute("href");
            this.helpLink.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.sendBigDataEvent(ZcpClickIds.helpLink);
                if (href) {
                    window.open(href, "_blank");
                }
            });
        }
    }
    prepareDataToSetAgreement() {
        const ids = [this.agreementRequire.idAgreement];
        if (this.checkbox.getValue()) {
            ids.push(this.agreementOptional.idAgreement);
        }
        return {
            agreements: ids
        };
    }
    utilize() {
        var _a;
        if (this.box) {
            const condition = (_a = this.box.getAttribute(UtilizationConstants.dataUtilize)) !== null && _a !== void 0 ? _a : "brak", dataToSend = this.utilization.prepareDataToSend(this.box, condition);
            this.utilization.utilize(dataToSend);
        }
    }
    sendBigDataEvent(clickID) {
        var _a;
        if (this.box) {
            const condition = (_a = this.box.getAttribute(UtilizationConstants.dataUtilize)) !== null && _a !== void 0 ? _a : "brak", dataToSend = this.utilization.prepareDataToSend(this.box, condition), attribute = BigDataHelper.createAttribute(this.box, true, clickID);
            BigData.sendDataOceanEvent(BigDataEventConstants.onClick, Object.assign(Object.assign({}, dataToSend), attribute));
        }
    }
    setAgreement() {
        this.closeDdbox();
        this.utilize();
        this.sendBigDataEvent(this.checkbox.getValue() ? ZcpClickIds.acceptChangesAndConsent : ZcpClickIds.acceptChanges);
        return httpInstance.post({
            url: this.agreementUrl,
            data: this.prepareDataToSetAgreement()
        });
    }
    getAgreementForm() {
        return httpInstance
            .get({
            url: this.squidHomeUrl
        })
            .then(this.successResponseAgreementForm.bind(this));
    }
    successResponseAgreementForm(response) {
        var _a;
        const data = response.data;
        if (((_a = data === null || data === void 0 ? void 0 : data.agreements) === null || _a === void 0 ? void 0 : _a.length) >= 2) {
            const agreements = response.data.agreements;
            this.setAgreementsAndShowDdbox(agreements);
        }
    }
    setAgreementsAndShowDdbox(agreements) {
        const agreementRequire = agreements.find((agreement) => agreement.optional === false), agreementOptional = agreements.find((agreement) => agreement.optional === true);
        if (agreementRequire && agreementOptional) {
            this.agreementRequire = agreementRequire;
            this.agreementOptional = agreementOptional;
            this.setAgreementsText();
            this.showDdbox();
        }
    }
    setAgreementsText() {
        this.checkbox.setText(this.agreementOptional.agreementText);
        this.setRequireAgreementText();
    }
    showModal() {
        if (this.modal) {
            this.modal.classList.remove(MarketingClientConstants.hiddenClassName);
        }
    }
    hideModal() {
        if (this.modal) {
            this.modal.classList.add(MarketingClientConstants.hiddenClassName);
        }
    }
    closeDdbox() {
        this.hideModal();
        if (this.box) {
            this.ddbox.closeDdbox(this.box);
        }
        this.enableScroll();
    }
    showDdbox() {
        if (this.box) {
            this.ddbox.showDdbox(this.box);
            this.disableScroll();
        }
    }
    setRequireAgreementText() {
        if (this.agreementTextArea) {
            this.agreementTextArea.innerHTML = this.agreementRequire.agreementText;
        }
    }
    toggleAcceptButtonText() {
        if (this.acceptButton) {
            this.acceptButton.innerHTML = this.checkbox.getValue()
                ? this.Constants.acceptChangesAndConsent
                : this.Constants.acceptChanges;
        }
    }
    setCancelModalButtonText(text) {
        if (this.cancelModalButton) {
            this.cancelModalButton.innerHTML = text;
        }
    }
    disableScroll() {
        document.body.classList.add(MarketingClientConstants.stopScrolling);
    }
    enableScroll() {
        document.body.classList.remove(MarketingClientConstants.stopScrolling);
    }
}
