var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { httpInstance } from "../http/http";
export class AppleId {
    appleSignIn(callback) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const data = yield this.signIn(), email = yield this.getEmailFromAppleId(data);
                if (email) {
                    callback(email);
                }
                else {
                    console.error("AppleId: brak emaila");
                }
            }
            catch (errorObj) {
                console.error("AppleID:", errorObj);
            }
        });
    }
    signIn() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield AppleID.auth.signIn();
        });
    }
    getEmailFromAppleId(data) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            return (_b = (_a = data.user) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : yield this.getUserEmailFromAWS(data.authorization);
        });
    }
    getUserEmailFromAWS(authorization) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            try {
                const userFromAWS = yield httpInstance
                    .get({
                    url: `https://aws.squid.wyborcza.pl/services/pub/rest/appleId/getEmailByIdentityToken?identityToken=${authorization.id_token}`
                });
                return (_b = (_a = userFromAWS === null || userFromAWS === void 0 ? void 0 : userFromAWS.data) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : null;
            }
            catch (error) {
                return null;
            }
        });
    }
    initAuth() {
        try {
            AppleID.auth.init({
                clientId: "pl.wyborcza",
                scope: "email",
                redirectURI: window.location.origin,
                state: window.location.href,
                nonce: "[NONCE]",
                usePopup: true
            });
        }
        catch (error) {
            console.error("AppleID.auth.init", error);
        }
    }
    bindFunctionToWindow() {
        window["social-media-apple"] = {
            login: (callback) => {
                this.appleSignIn(callback);
            },
            logout: () => { return undefined; }
        };
    }
    init() {
        this.initAuth();
        this.bindFunctionToWindow();
    }
}
