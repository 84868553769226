import { MarketingClientConstants, ConsentCookie } from "./constants/marketing-client-constants";
import { Utils } from "./utils/utils";
import { httpInstance } from "./http/http";
import { UserData } from "./user-data/user-data";
import { BigDataHelper } from "./helpers/big-data-helper";
import { RequestPaths, SquidURLs, URLBase } from "./constants/url-constants";
import { BigData } from "./marketing-client-bigdata";
import { Capping } from "./marketing-client-capping";
import { MarketingClientCustomDataLayer } from "./marketing-client-custom-datalayer";
import { Utilization } from "./marketing-client-utilization";
import { MarketingClientBanners } from "./marketing-client-banners";
import { DOMHelper } from "./helpers/dom-helper";
import { MarketingClientAttributesConstants } from "./constants/marketing-client-attributes-constants";
import { BigDataObjectConstants } from "./constants/big-data-object-constants";
import { PredefinedIcons } from "./constants/predefined-icons-constants";
import { DeviceInformationHelper, DeviceType } from "./helpers/device-information-helper";
import { marketingClientDataLayer } from "./marketing-client-datalayer";
import { BigDataEventConstants } from "./constants/big-data-constants";
import { EventType } from "./constants/event-type-constants";
export const WYBORCZA_PL = window["wyborcza_pl"] || {};
export const GAZETA_PL = window["gazeta_pl"] || {};
export class MarketingClient {
    constructor() {
        this.bigDataObject = new BigData();
        this.dataLayerObject = new MarketingClientCustomDataLayer();
        this.utilizationObject = new Utilization(httpInstance);
        this.capping = new Capping(httpInstance);
        this.userData = new UserData();
        this.started = false;
        this.placeholders = [];
        this.isMobile = false;
        this.dmElements = [];
    }
    init() {
        setTimeout(() => {
            if (!this.checkConsent(ConsentCookie)) {
                const interval = setInterval(() => {
                    if (this.checkOptanonAlertBoxClosedExist()) {
                        clearInterval(interval);
                        this.startIfGuidExists();
                    }
                }, 500);
            }
            else {
                this.startIfGuidExists();
            }
        }, 200);
    }
    startIfGuidExists() {
        const bigDataGUID = BigDataHelper.getBigDataGUID();
        this.isMobile = DeviceInformationHelper.getDeviceType() === DeviceType.MOBILE;
        if (bigDataGUID) {
            this.start();
            return;
        }
        this.onMessageReceived();
    }
    checkOptanonAlertBoxClosedExist() {
        return document.cookie.includes(MarketingClientConstants.optanonAlertBoxClosed);
    }
    checkConsent(flags) {
        const optanonConsentCookie = document.cookie.split("; ").find((row) => row.startsWith("OptanonConsent="));
        if (!optanonConsentCookie)
            return false;
        const consentedGroups = decodeURIComponent(optanonConsentCookie.split("groups=")[1]);
        const allFlagsConsented = flags.every((flag) => consentedGroups.includes(`${flag}:`));
        return allFlagsConsented;
    }
    start() {
        var _a;
        this.userData.getProfile();
        this.bigDataGUID = (_a = BigDataHelper.getBigDataGUID()) !== null && _a !== void 0 ? _a : "";
        this.pushPlaceholders();
        if (!this.bigDataGUID) {
            this.bigDataGUID = MarketingClientConstants.noguid;
            this.userData.profile.anonimowy = true;
        }
        this.utilizationObject.setGUID(this.bigDataGUID);
        this.capping.setGUID(this.bigDataGUID);
        if (this.placeholders.length > 0 && !this.userData.profile.disable) {
            this.getActionsFromDomain().then(() => {
                console.info("Successfully downloaded actions from domain");
            }, () => {
                console.warn("Failed to load actions from domain");
            });
        }
        else {
            this.initDefaultAdds();
        }
        marketingClientDataLayer.init();
    }
    pushPlaceholders() {
        const templareIds = window[MarketingClientConstants.placeholderList].templateIds
            .slice(1, -1)
            .split(",")
            .map((templateId) => templateId.trim());
        this.placeholders = templareIds.map((templateId) => {
            return { element: null, templateId, isSwimmer: false, isDDBox: false };
        });
    }
    refreshPlaceholdersReferences() {
        this.placeholders = this.placeholders.map((placeholder) => {
            return this.refreshPlaceholderReference(placeholder);
        });
    }
    refreshPlaceholderReference(placeholder) {
        const element = DOMHelper.querySelector(`[${MarketingClientAttributesConstants.placeholderId}="${placeholder.templateId}"]`), isSwimmerAttribute = element === null || element === void 0 ? void 0 : element.getAttribute(MarketingClientAttributesConstants.isSwimmer), isDDBoxAttribute = element === null || element === void 0 ? void 0 : element.getAttribute(MarketingClientAttributesConstants.isDDBox);
        let isSwimmer = false, isDDBox = false;
        if (Utils.stringNotEmpty(isSwimmerAttribute)) {
            isSwimmer = isSwimmerAttribute === "true";
        }
        if (Utils.stringNotEmpty(isDDBoxAttribute)) {
            isDDBox = isDDBoxAttribute === "true";
        }
        placeholder.element = element;
        placeholder.isDDBox = isDDBox;
        placeholder.isSwimmer = isSwimmer;
        return placeholder;
    }
    prepareDTOForMarketingProxy(isHarmonyTest) {
        const urlParams = Utils.getURLParamsAsObject(), pageViewId = BigDataHelper.getBigDataOrCookieValue(BigDataObjectConstants.pageViewCookie, BigDataObjectConstants.pageViewBigData), visitId = BigDataHelper.getBigDataOrCookieValue(BigDataObjectConstants.visitIdCookie, BigDataObjectConstants.visitIdBigData);
        let profile = this.userData.profile;
        if (isHarmonyTest) {
            let profileFromUrl = urlParams[MarketingClientConstants.overwriteProfileUrlParam];
            if (!Utils.isNullOrUndefined(profileFromUrl)) {
                profileFromUrl = profileFromUrl;
                if (Utils.stringNotEmpty(profileFromUrl)) {
                    try {
                        profile = JSON.parse(atob(decodeURIComponent(profileFromUrl)));
                    }
                    catch (e) {
                        console.warn("URL profile is empty or cannot be converted -> cannot overwrite base profile");
                    }
                }
            }
            // @ts-ignore
            if (!window[MarketingClientConstants.encodeHarmonyProfileHelperKey]) {
                // @ts-ignore
                window[MarketingClientConstants.encodeHarmonyProfileHelperKey] = (profile) => {
                    return encodeURIComponent(btoa(JSON.stringify(profile)));
                };
            }
            // @ts-ignore
            if (!window[MarketingClientConstants.decodeHarmonyProfileHelperKey]) {
                // @ts-ignore
                window[MarketingClientConstants.decodeHarmonyProfileHelperKey] = (profile) => {
                    return JSON.parse(atob(decodeURIComponent(profile)));
                };
            }
        }
        const template = {
            guid: this.bigDataGUID,
            visitId: visitId,
            params: {
                [BigDataObjectConstants.artPvidParam]: pageViewId
            },
            profiles: profile,
            templates: [...this.getTemplateIdForPlaceholders()]
        };
        return template;
    }
    getTemplateIdForPlaceholders() {
        return this.placeholders.map((placeholder) => placeholder.templateId);
    }
    getActionsFromDomain() {
        const isHarmonyTest = Utils.getURLParamsAsObject()[MarketingClientConstants.allowHarmonyTestingUrlParam], payload = this.prepareDTOForMarketingProxy(isHarmonyTest);
        let timeoutFired = false, timeoutID;
        timeoutID = window.setTimeout(() => {
            timeoutFired = true;
            this.initDefaultAdds();
        }, 3000);
        const resultantPromise = httpInstance.post({
            url: `${isHarmonyTest ? URLBase.TEST : URLBase.PROD}${RequestPaths.TEMPLATE}`,
            data: payload
        });
        resultantPromise.then((response) => {
            clearTimeout(timeoutID);
            if (!Utils.isNullOrUndefined(response.data)) {
                const parsedResponse = response.data;
                this.initAdds(parsedResponse);
            }
        }, (error) => {
            if (!timeoutFired) {
                this.initDefaultAdds();
            }
        });
        return resultantPromise;
    }
    initAdds(adsObject) {
        this.placeAdds(adsObject.templates);
        this.utilizationObject.init();
        // add referers ?? -> Wawa czy my? Utils.addReferrer();
        this.bannersObject = new MarketingClientBanners(this.capping, this.utilizationObject, this.isMobile);
        this.bannersObject.init();
        // tracker parameters, bind login ?
        // init activation modules ?
        // init newsletters ?
    }
    initDefaultAdds() {
        this.refreshPlaceholdersReferences();
        this.placeholders.forEach((placeholder) => {
            if (placeholder.element) {
                this.initDefaultAdd(placeholder.element);
            }
        });
    }
    initDefaultAdd(placeholder) {
        placeholder.classList.add(MarketingClientAttributesConstants.initializedClass);
    }
    placeAdds(ads) {
        const isVideoAd = document.querySelector(MarketingClientAttributesConstants.videoClassSelector) !== null;
        if (this.placeholders.length === ads.length) {
            this.refreshPlaceholdersReferences();
            this.placeholders.forEach((placeholder, index) => {
                this.placeAdd(ads[index], placeholder, isVideoAd);
            });
        }
        this.sendDisplayEventOnScroll();
        this.dataLayerObject.init();
    }
    placeAdd(ad, placeholder, isVideoAd) {
        const { campaignId = "", actionId = "", touchPointId = "", html = "" } = ad, isSwimmerOrDDbox = placeholder.isSwimmer || placeholder.isDDBox, isNotSwimmerOrDDboxAndVideoAd = !(isSwimmerOrDDbox && isVideoAd), dataId = {
            campaignId: campaignId,
            actionId: actionId,
            touchPointId: touchPointId
        }, dataToSend = dataId;
        let dmElement = null;
        if (!placeholder.element) {
            return;
        }
        if (html && html !== "" && isNotSwimmerOrDDboxAndVideoAd) {
            this.addContent(placeholder, html, campaignId !== null && campaignId !== void 0 ? campaignId : "", actionId !== null && actionId !== void 0 ? actionId : "", touchPointId !== null && touchPointId !== void 0 ? touchPointId : "");
            this.addPredefinedIcon(placeholder.element);
            if (!isSwimmerOrDDbox) {
                this.capping.sendCapping(dataToSend);
                dmElement = this.prepareDmElement(placeholder, false, dataId);
            }
        }
        else {
            this.initDefaultAdd(placeholder.element);
            dmElement = this.createDmElementForPlanB(placeholder);
        }
        if (dmElement) {
            this.sendBigDataEvent(BigDataEventConstants.onLoad, dmElement);
            this.sendDisplayEventIfElementIsVisible(dmElement, dataToSend);
        }
    }
    createDmElementForPlanB(placeholder) {
        var _a;
        return ((_a = placeholder.element) === null || _a === void 0 ? void 0 : _a.children.length) ? this.prepareDmElement(placeholder, true, {}) : null;
    }
    sendDisplayEventIfElementIsVisible(dmElement, dataToSend) {
        const dmElementIsVisibleByUser = DOMHelper.elementIsVisible(dmElement.element) &&
            dmElement.element.getBoundingClientRect().top <= window.innerHeight;
        if (dmElementIsVisibleByUser) {
            this.sendBigDataEvent(BigDataEventConstants.onDisplay, dmElement);
            if (!dmElement.isPlanB) {
                this.capping.sendCapping(dataToSend, true);
            }
        }
        else {
            this.dmElements.push(dmElement);
        }
    }
    sendDisplayEventOnScroll() {
        if (this.dmElements.length) {
            window.addEventListener(EventType.SCROLL, () => {
                this.dmElements.forEach((dmElement) => {
                    const scroll = window.scrollY || 0;
                    if (!dmElement.visible &&
                        scroll + window.innerHeight * 0.7 >= dmElement.element.getBoundingClientRect().top + scroll) {
                        dmElement.visible = true;
                        this.sendBigDataEvent(BigDataEventConstants.onDisplay, dmElement);
                        if (!dmElement.isPlanB) {
                            this.capping.sendCapping(dmElement.dataId, true);
                        }
                    }
                });
            });
        }
    }
    addContent(placeholder, targetHTML, parsedCampaignId, parsedActionId, parsedTouchPointId) {
        if (!placeholder.element) {
            return;
        }
        placeholder.element.innerHTML = targetHTML;
        placeholder.element.setAttribute(MarketingClientAttributesConstants.campaignId, parsedCampaignId);
        placeholder.element.setAttribute(MarketingClientAttributesConstants.actionId, parsedActionId);
        placeholder.element.setAttribute(MarketingClientAttributesConstants.touchPointId, parsedTouchPointId);
        placeholder.element.classList.add(MarketingClientAttributesConstants.initializedClass);
    }
    sendBigDataEvent(eventName, dmElement) {
        const attribute = BigData.prepareDataOceanAttribute(dmElement.dataCta, dmElement.dataId, dmElement.dataRW);
        BigData.sendDataOceanEvent(eventName, attribute);
    }
    prepareDmElement(placeholder, isPlanB, dataId) {
        const planB = "planB", element = placeholder.element, dataCta = Utils.prepareCtaObject(element), dataRW = Utils.prepareRegistrationWallObject(element);
        if (isPlanB) {
            dataId = {
                campaignId: planB,
                actionId: planB,
                touchPointId: planB
            };
        }
        return {
            element,
            visible: false,
            dataId,
            dataCta,
            dataRW,
            isPlanB
        };
    }
    addPredefinedIcon(placeholder) {
        const useElements = placeholder.querySelectorAll("use");
        let attribute = "", predefinedIconElement = Array.from(useElements).find((element) => {
            attribute = element.getAttributeNS("http://www.w3.org/1999/xlink", "href");
            return attribute && Object.keys(PredefinedIcons).includes(attribute);
        });
        const parent = predefinedIconElement === null || predefinedIconElement === void 0 ? void 0 : predefinedIconElement.parentElement;
        if (attribute && predefinedIconElement && parent) {
            parent.setAttribute("viewBox", PredefinedIcons[attribute].viewBox);
            parent.innerHTML = PredefinedIcons[attribute].content;
        }
    }
    onMessageReceived() {
        window.addEventListener("message", (event) => {
            const baseDomain = document.location.protocol === "https:" ? SquidURLs.https : SquidURLs.http, origin = event.origin, message = event.data;
            if (origin && origin === baseDomain && (message === null || message === void 0 ? void 0 : message.includes("IFRAMELOADED"))) {
                try {
                    JSON.parse(message);
                    this.start();
                }
                catch (e) { }
            }
        }, false);
    }
}
let mc = new MarketingClient();
mc.init();
WYBORCZA_PL.MarketingClient = mc;
