import { DeviceInformationHelper, DeviceType } from "./helpers/device-information-helper";
import { BigDataEventConstants } from "./constants/big-data-constants";
import { EventType } from "./constants/event-type-constants";
import { MarketingClientConstants } from "./constants/marketing-client-constants";
import { DOMHelper } from "./helpers/dom-helper";
import { PageInformationHelper } from "./helpers/page-information-helper";
import { httpInstance } from "./http/http";
import { BigData } from "./marketing-client-bigdata";
import { Spinner } from "./utils/spinner";
import { Utils } from "./utils/utils";
import { LayerType, ShareArticleErrorHandler, WYBORCZA_PL } from "./helpers/swf-helper";
export class MarketingClientSWFBase {
    constructor(utilizationObject) {
        this.utilizationObject = utilizationObject;
        this.layers = {};
        this.mkdmContainerClass = ".mkdm-container";
        this.loggedHideClass = ".logged-hide";
        this.shareArticleFormId = "shareArticleForm";
        this.emailClass = ".share-article-email";
        this.spinnerClass = ".share-article-spinner";
        this.messages = {
            required: "Pole e-mail jest wymagane"
        };
        this.manualUtilizeAttr = "data-utilize-manual";
        this.baseURL = "/share/sender/sendEmail";
        this.isMobile = DeviceInformationHelper.getDeviceType() === DeviceType.MOBILE;
    }
    initialize() {
        this.form = DOMHelper.getElementById(this.shareArticleFormId);
        if (!this.form) {
            return;
        }
        this.email = this.form ? DOMHelper.querySelector(this.emailClass, this.form) : null;
        this.spinner = new Spinner(this.spinnerClass);
        this.errorHandler = new ShareArticleErrorHandler(this.email);
        this.initializeLayers();
        this.hideContainersForLoggedInUser();
    }
    initializeLayers() {
        this.layers[LayerType.DEFAULT] = DOMHelper.getElementById("shareArticleDefaultLayer");
        this.layers[LayerType.ERROR] = DOMHelper.getElementById("shareArticleErrorLayer");
        this.layers[LayerType.SUCCESS] = DOMHelper.getElementById("shareArticleSuccessLayer");
        this.layers[LayerType.BLOCKED] = DOMHelper.getElementById("shareArticleBlockedLayer");
    }
    hideContainersForLoggedInUser() {
        var _a;
        if ((_a = WYBORCZA_PL === null || WYBORCZA_PL === void 0 ? void 0 : WYBORCZA_PL.userInfo) === null || _a === void 0 ? void 0 : _a.loggedIn) {
            const mkdmContainer = DOMHelper.querySelector(this.mkdmContainerClass), mkdmContainerLoggedHide = mkdmContainer && DOMHelper.querySelector(this.loggedHideClass, mkdmContainer);
            if (mkdmContainer && mkdmContainerLoggedHide) {
                mkdmContainerLoggedHide.classList.add(MarketingClientConstants.hiddenClassName);
            }
        }
    }
    bindEventsToFormSubmit() {
        if (this.form) {
            this.form.addEventListener(EventType.SUBMIT, (e) => {
                e.preventDefault();
                if (this.validateEmail()) {
                    this.utilizeShareArticle();
                    this.sendData();
                }
            });
        }
    }
    bindEventsToEmailInput() {
        if (this.email) {
            this.email.addEventListener(EventType.CHANGE, this.validateEmail.bind(this));
            this.email.addEventListener(EventType.INPUT, () => {
                var _a;
                return ((_a = this.email) === null || _a === void 0 ? void 0 : _a.value.length) ? this.errorHandler.hide() : this.errorHandler.show(this.messages.required);
            });
        }
    }
    validateEmail() {
        const value = DOMHelper.getEmailValue(this.email), emailIsValid = Utils.validateEmail(value);
        if (!value.length) {
            return this.errorHandler.show(this.messages.required);
        }
        else if (!emailIsValid) {
            return this.errorHandler.show();
        }
        else {
            return this.errorHandler.hide();
        }
    }
    utilizeShareArticle() {
        const link = DOMHelper.querySelector(`[${this.manualUtilizeAttr}]`, this.form), parent = link === null || link === void 0 ? void 0 : link.closest(`[${MarketingClientConstants.placeholderDataSetAttribute}]`), condition = link === null || link === void 0 ? void 0 : link.getAttribute(this.manualUtilizeAttr);
        if (link && parent && condition) {
            const dataToSend = this.utilizationObject.prepareDataToSend(parent, condition), ctaObject = Utils.prepareCtaObject(parent), attribute = BigData.prepareDataOceanAttribute(ctaObject, dataToSend);
            BigData.sendDataOceanEvent(BigDataEventConstants.onClick, attribute);
            this.utilizationObject.utilize(dataToSend);
        }
    }
    sendData() {
        var _a, _b;
        let data = {
            senderEmail: (_b = (_a = WYBORCZA_PL.userInfo) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : "",
            recipientEmail: DOMHelper.getEmailValue(this.email),
            articleId: Number(PageInformationHelper.getArticleId())
        };
        this.spinner.show();
        return httpInstance
            .post({
            url: this.baseURL,
            data,
            withCredentials: true
        })
            .then(this.successResponseHandler.bind(this), this.errorResponseHandler.bind(this))
            .then(() => {
            this.spinner.hide();
        });
    }
}
