var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BigDataObjectConstants } from "../../constants/big-data-object-constants";
import { CopConstants } from "../../constants/cop-constants";
import { BigDataHelper } from "../../helpers/big-data-helper";
import { DOMHelper } from "../../helpers/dom-helper";
import { BigData } from "../../marketing-client-bigdata";
import { Utils } from "../utils";
import CopBox from "./cop-box";
import ReadLater from "./read-later";
export default class RecommendationsCop {
    constructor(isMobile) {
        this.ddboxIsVisible = false;
        this.boxes = [];
        this.scriptParameters = {};
        this.isMobile = isMobile;
        this.readLater = new ReadLater(CopConstants.boxButtonsWrap);
    }
    init(ddboxIsVisible) {
        this.cop = DOMHelper.querySelector(CopConstants.cop);
        this.ddboxIsVisible = ddboxIsVisible;
        if (!this.cop)
            return;
        this.getAnalyticalParams();
        this.hideFirstBox();
        this.prepareDataToGetReco();
        this.initializeScriptParameters();
        this.addDomainKeyToParams();
        this.pullRecommendations();
        this.readLater.init();
    }
    getAnalyticalParams() {
        var _a;
        if ((_a = this.cop) === null || _a === void 0 ? void 0 : _a.parentElement) {
            this.collectionIdsObject = Utils.prepareCollectionIdsObject(this.cop.parentElement);
            this.ctaObject = Utils.prepareCtaObject(this.cop.parentElement);
            this.pageViewId = BigDataHelper.getBigDataOrCookieValue(BigDataObjectConstants.pageViewCookie, BigDataObjectConstants.pageViewBigData);
        }
    }
    prepareDataToGetReco() {
        var _a;
        this.boxesToDownload = JSON.parse(this.cop.getAttribute(CopConstants.dataDownloadArticle));
        this.widgetId = this.cop.getAttribute(CopConstants.dataWidgetId);
        this.domainKey = this.cop.getAttribute(CopConstants.dataDomainKey);
        this.dmParameter = (_a = this.cop.getAttribute(CopConstants.dataDmParameter)) !== null && _a !== void 0 ? _a : "";
    }
    initializeScriptParameters() {
        const dataKeyValue = JSON.parse(this.cop.getAttribute(CopConstants.dataKeyValue));
        if (dataKeyValue) {
            this.scriptParameters = dataKeyValue.reduce((m, v) => {
                m[v.key] = v.value;
                return m;
            }, {});
        }
    }
    addDomainKeyToParams() {
        if (this.domainKey)
            this.scriptParameters[this.domainKey] = window.location.hostname;
    }
    pullRecommendations() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.widgetId)
                return;
            try {
                const recommendations = yield BigData.getRecommendations(this.widgetId, this.scriptParameters);
                this.recommendations = [...recommendations];
                this.prepareBoxes(recommendations);
            }
            catch (_a) {
                console.log(CopConstants.fetchFailedMessage);
                this.prepareBoxes(null);
            }
        });
    }
    prepareBoxes(recommendations) {
        let recoToUseIndex = 0;
        for (let i = 0; i < this.boxesToDownload.length; i++) {
            const box = new CopBox(i, this.boxesToDownload[i]);
            const recommendation = recommendations ? recommendations[recoToUseIndex] : null;
            this.createBox(box, recommendation);
            recoToUseIndex++;
        }
        this.showFirstBox();
        this.readLater.bindReadLaterEvents(this.boxes);
    }
    createBox(box, recommendation) {
        box.init();
        box.imageFormat = box.assignImageFormat(this.ddboxIsVisible, this.isMobile);
        box.prepareBox(recommendation, this.ctaObject, this.collectionIdsObject, this.pageViewId, this.dmParameter);
        this.boxes.push(box);
    }
    hideFirstBox() {
        var _a;
        this.articleLoader = DOMHelper.querySelector(CopConstants.swimmerArticleLoader);
        (_a = this.articleLoader) === null || _a === void 0 ? void 0 : _a.classList.add(CopConstants.visible);
        this.toggleContentVisibility(`${CopConstants.box}${0}`);
    }
    showFirstBox() {
        var _a;
        (_a = this.articleLoader) === null || _a === void 0 ? void 0 : _a.classList.remove(CopConstants.visible);
        this.toggleContentVisibility(`${CopConstants.box}${0}`);
    }
    toggleContentVisibility(boxClass) {
        const el = DOMHelper.querySelector(boxClass);
        el === null || el === void 0 ? void 0 : el.classList.toggle(CopConstants.hidden);
    }
}
