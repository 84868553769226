export const BannedEmailDomains = [
    "mailinator.com",
    "sharklasers.com",
    "guerillamail.info",
    "grr.la",
    "guerillamail.biz",
    "guerillamail.com",
    "guerillamail.de",
    "guerillamail.net",
    "guerillamail.org",
    "guerillamailblock.com",
    "pokemail.net",
    "spam4.me",
    "nwytg.net",
    "armyspy.com",
    "cuvox.de",
    "dayrep.com",
    "einrot.com",
    "fleckens.hu",
    "gustr.com",
    "jourrapide.com",
    "rhyta.com",
    "superrito.com",
    "teleworm.us",
    "clrmail.com",
    "eatlogs.com",
    "dispostable.com",
    "cd.mintemail.com",
    "maildrop.cc",
    "yopmail.com",
    "spamgourmet.com",
    "harakirimail.com",
    "mailnesia.com",
    "mt2015.com",
    "33mail.com",
    "tempr.email",
    "discard.email",
    "discardmail.com",
    "discardmail.de",
    "spambog.com",
    "spambog.de",
    "spambog.ru",
    "0815.ru",
    "hulapla.de",
    "pfui.ru",
    "0815.su",
    "sweetxxx.de",
    "zaktouni.fr",
    "btcmail.pw",
    "knol-power.nl",
    "hartbot.de",
    "freundin.ru",
    "smashmail.de",
    "s0ny.net",
    "sohai.ml",
    "wikidocuslava.ru",
    "jumonji.tk",
    "i6.cloudns.cc",
    "i6.cloudns.cx",
    "posta.store",
    "milk.gage.ga",
    "smap.4nmv.ru",
    "spam.2012-2016.ru",
    "pecinan.com",
    "pecinan.org",
    "budayationghoa.com",
    "pecinan.net",
    "budaya-tionghoa.com",
    "meltedbrownies.com",
    "larisia.com",
    "starpower.space",
    "thunderbolt.science",
    "lajoska.pe.hu",
    "hotmailproduct.com",
    "yahooproduct.net",
    "attnetwork.com",
    "freelance-france.eu",
    "webcontact-france.eu",
    "fast-mail.fr",
    "mail-easy.fr",
    "instantmail.fr",
    "cyber-phone.eu",
    "premium-mail.fr",
    "disign-concept.eu",
    "ecolo-online.fr",
    "photo-impact.eu",
    "web-ideal.fr",
    "wazabi.club",
    "used-product.fr",
    "cyber-innovation.club",
    "reality-concept.club",
    "last-chance.pro",
    "disign-revelation.com",
    "art-en-ligne.pro",
    "solar-impact.pro",
    "jet-renovation.fr",
    "estate-invest.fr",
    "klick-tipp.us",
    "6-6-6.nut.cc",
    "6-6-6.flu.cc",
    "6-6-6.usa.cc",
    "6-6-6.igg.biz",
    "s-s.usa.cc",
    "s-s.flu.cc",
    "s-s.nut.cc",
    "s-s.igg.biz",
    "emailfake.usa.cc",
    "ti.igg.biz",
    "tx.igg.biz",
    "txt.flu.cc",
    "s0ny.usa.cc",
    "s0ny.flu.cc",
    "s0ny.nut.cc",
    "s0ny.igg.biz",
    "m5s.flu.cc",
    "m5s.nut.cc",
    "m5s.igg.biz",
    "level3.usa.cc",
    "level3.flu.cc",
    "level3.nut.cc",
    "level3.igg.biz",
    "0x01.tk",
    "0x01.gq",
    "j7.cloudns.cx",
    "pw.j7.cloudns.cx",
    "txpwg.usa.cc",
    "skypaluten.de",
    "pw.mymy.cf",
    "a.safe-mail.gq",
    "x.0x01.tk",
    "rr.0x01.gq",
    "s.0x01.gq",
    "ss.0x01.tk",
    "tt.0x01.tk",
    "mbox.0x01.tk",
    "yop.0x01.gq",
    "spam.0x01.tk",
    "dm.j7.cloudns.cx",
    "vw.0x01.tk",
    "vw.0x01.gq",
    "jj.0x01.tk",
    "ths.mymy.cf",
    "mbx.mysafe.ml",
    "b.safe-mail.gq",
    "c.safe-mail.gq",
    "p-w.mysafe.ml",
    "free.mysafe.ml",
    "t4.0x01.tk",
    "t4.0x01.gq",
    "app.mymy.cf",
    "ew.mysafe.ml",
    "we.mysafe.ml",
    "i.safe-mail.gq",
    "j.safe-mail.gq",
    "k.safe-mail.gq",
    "l.safe-mail.gq",
    "m.safe-mail.gq",
    "n.safe-mail.gq",
    "o.safe-mail.gq",
    "p.safe-mail.gq",
    "you.mymy.cf",
    "i.mymy.cf",
    "2.mymy.cf",
    "msw.mymy.cf",
    "yh.mysafe.ml",
    "es.mysafe.ml",
    "1.mysafe.ml",
    "ap.mysafe.ml",
    "m-x.mysafe.ml",
    "on-off.cloudns.cx",
    "3.mymy.cf",
    "mail707.com",
    "cano.cl",
    "te.pecinan.org",
    "te.budayationghoa.com",
    "mail.ru",
    "star.0x01.tk",
    "star.0x01.gq",
    "games4free.flu.cc",
    "retkesbusz.nut.cc",
    "asiarap.usa.cc",
    "ay33rs.flu.cc",
    "hunrap.usa.cc",
    "44556677.igg.biz",
    "my.safe-mail.gq"
];
