var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BigDataEventConstants } from "./constants/big-data-constants";
import { EventType } from "./constants/event-type-constants";
import { MarketingClientConstants } from "./constants/marketing-client-constants";
import { RwErrorMessage } from "./constants/rw-object.constants";
import { BigDataHelper } from "./helpers/big-data-helper";
import { DeviceInformationHelper, DeviceType } from "./helpers/device-information-helper";
import { DOMHelper } from "./helpers/dom-helper";
import { BigData } from "./marketing-client-bigdata";
import { Checkbox } from "./utils/checkbox";
import { ErrorHandler } from "./utils/error-handler-first-rw";
import { Spinner } from "./utils/spinner";
import { Utils } from "./utils/utils";
import { requiredOrderConstants } from "./constants/order-constants";
import { httpInstance } from "./http/http";
export class OneDayPass {
    constructor(capping, utilizationObject) {
        this.capping = capping;
        this.utilizationObject = utilizationObject;
        this.oneDayPassID = "nw_one_day_pass";
        this.formID = "one-day-pass-form";
        this.oneDayPassClass = ".nw_2022_odp";
        this.classSuffix = {
            email: "-form-email",
            emailError: "-form-email-error",
            checkbox: "-checkbox",
            createAccountError: "-form-create-account-error",
            button: "-button",
            spinner: "-form-spinner"
        };
        this.messages = RwErrorMessage;
        this.isHarmonyTest = Utils.getURLParamsAsObject().enableHarmonyTest;
        this.path = this.isHarmonyTest ? "https://coreos05.wyborcza.pl:9443/" : "https://api-prenumerata.wyborcza.pl/";
        this.isMobile = DeviceInformationHelper.getDeviceType() === DeviceType.MOBILE;
    }
    init() {
        this.initializeOneDayPass();
        this.initializeForm();
        if (!this.form)
            return;
        this.initializeSpinner();
        this.initializeCheckbox();
        this.initializePromocode();
        this.initializeParent();
        this.bindEvents();
    }
    initializeOneDayPass() {
        this.oneDayPass = DOMHelper.getElementById(this.oneDayPassID);
    }
    initializeForm() {
        this.form = DOMHelper.getElementById(this.formID);
        if (!this.form)
            return;
        this.email = DOMHelper.querySelector(`${this.oneDayPassClass}${this.classSuffix.email}`, this.form);
        this.createAccountButton = DOMHelper.getElementById(`${this.formID}${this.classSuffix.button}`);
        if (!this.email)
            return;
        this.emailErrorHandler = new ErrorHandler(this.email);
        this.createAccountError = DOMHelper.querySelector(`${this.oneDayPassClass}${this.classSuffix.createAccountError}`, this.form);
    }
    initializePromocode() {
        if (this.oneDayPass.dataset.promocode)
            this.promocode = this.oneDayPass.dataset.promocode;
    }
    initializeSpinner() {
        this.spinnerCreateAccount = new Spinner(`${this.oneDayPassClass}${this.classSuffix.spinner}`);
    }
    initializeCheckbox() {
        this.checkbox = new Checkbox(`${this.formID}${this.classSuffix.checkbox}`);
    }
    initializeParent() {
        var _a;
        this.parent = (_a = this.oneDayPass) === null || _a === void 0 ? void 0 : _a.closest(`[${MarketingClientConstants.placeholderDataSetAttribute}]`);
    }
    bindEvents() {
        this.bindEventsToRegistrationAccount();
        this.bindEventsToEmailInput();
    }
    bindEventsToRegistrationAccount() {
        if (this.createAccountButton) {
            this.createAccountButton.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                const validateCheckboxes = this.validateCheckboxes();
                if (this.validateEmail() && validateCheckboxes) {
                    this.hideRegistrationAccountError();
                    this.spinnerCreateAccount.show();
                    this.utilize();
                    this.sendData();
                }
            });
        }
    }
    bindEventsToEmailInput() {
        if (this.email) {
            this.email.addEventListener(EventType.CHANGE, this.validateEmail.bind(this));
            this.email.addEventListener(EventType.INPUT, () => {
                var _a;
                return ((_a = this.email) === null || _a === void 0 ? void 0 : _a.value.length)
                    ? this.emailErrorHandler.hide()
                    : this.emailErrorHandler.show(this.messages.required);
            });
        }
    }
    validateCheckboxes() {
        this.checkbox.toggle();
        return this.checkbox.isValidate();
    }
    validateEmail() {
        const value = this.email ? this.email.value : "", emailIsValid = Utils.validateEmail(value);
        if (!value.length) {
            return this.emailErrorHandler.show(this.messages.required);
        }
        else if (!emailIsValid) {
            return this.emailErrorHandler.show(this.messages.banned);
        }
        else {
            return this.emailErrorHandler.hide();
        }
    }
    errorRegistrationAccount(error) {
        error.status === 400
            ? this.emailErrorHandler.show(this.messages.accountExists)
            : this.showRegistrationAccountError();
        this.spinnerCreateAccount.hide();
    }
    hideRegistrationAccountError() {
        var _a;
        (_a = this.createAccountError) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
    }
    showRegistrationAccountError() {
        var _a;
        (_a = this.createAccountError) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hiddenClassName);
        this.spinnerCreateAccount.hide();
    }
    utilize(clickID = "", onlyBigData = false) {
        const condition = "brak";
        if (this.parent) {
            const dataToSend = this.utilizationObject.prepareDataToSend(this.parent, condition), attribute = BigDataHelper.createAttribute(this.parent, true, clickID);
            if (!onlyBigData) {
                this.utilizationObject.utilize(dataToSend);
            }
            BigData.sendDataOceanEvent(BigDataEventConstants.onClick, Object.assign(Object.assign({}, dataToSend), attribute));
        }
    }
    buildOfferBody(accountType) {
        return Object.assign({ offerId: this.offerID, email: DOMHelper.getEmailValue(this.email), promoCode: this.promocode, accountType: accountType }, requiredOrderConstants);
    }
    sendData() {
        return __awaiter(this, void 0, void 0, function* () {
            this.spinnerCreateAccount.show();
            const data = {
                email: DOMHelper.getEmailValue(this.email)
            };
            yield httpInstance
                .get({
                url: `${this.path}accounts/validate?` + new URLSearchParams(data),
                withCredentials: true,
                headers: {
                    "Content-Type": "application/stream+json",
                    Accept: "*/*"
                }
            })
                .then(this.successEmailIsValid.bind(this), this.errorRegistrationAccount.bind(this));
        });
    }
    successEmailIsValid() {
        return __awaiter(this, void 0, void 0, function* () {
            const data = {
                registrationEmail: DOMHelper.getEmailValue(this.email),
                accountType: "WYBORCZA"
            };
            yield httpInstance
                .post({
                url: `${this.path}accounts/register-v2`,
                data: data,
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "*/*"
                }
            })
                .then(this.successRegistrationAccount.bind(this), this.showRegistrationAccountError.bind(this));
        });
    }
    successRegistrationAccount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield httpInstance
                .get({
                url: `${this.path}promo-codes/${this.promocode}/offerId`,
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "*/*"
                }
            })
                .then((response) => {
                this.checkIfDataOfferId(response);
            }, this.showRegistrationAccountError.bind(this));
        });
    }
    checkIfDataOfferId(response) {
        if (response.data.offerId) {
            this.offerID = response.data.offerId;
            this.softLogin();
        }
        else {
            this.showRegistrationAccountError();
        }
    }
    softLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            const data = {
                email: DOMHelper.getEmailValue(this.email)
            };
            yield httpInstance
                .post({
                url: `${this.path}softlogin`,
                data: data,
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "*/*"
                }
            })
                .then(this.getAccountData.bind(this), this.showRegistrationAccountError.bind(this));
        });
    }
    getAccountData() {
        return __awaiter(this, void 0, void 0, function* () {
            yield httpInstance
                .get({
                url: `${this.path}accounts`,
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "*/*"
                }
            })
                .then((response) => {
                this.checkIfDataAccountType(response);
            }, this.showRegistrationAccountError.bind(this));
        });
    }
    checkIfDataAccountType(response) {
        response.data.accountType ? this.makeTheOrder(response.data.accountType) : this.showRegistrationAccountError();
    }
    makeTheOrder(accountType) {
        return __awaiter(this, void 0, void 0, function* () {
            const orderData = this.buildOfferBody(accountType);
            yield httpInstance
                .post({
                url: `${this.path}orders`,
                data: orderData,
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "*/*"
                }
            })
                .then(() => {
                this.spinnerCreateAccount.hide();
                window.location.reload();
            }, this.showRegistrationAccountError.bind(this));
        });
    }
}
