import { Utils } from "../utils/utils";
export class Http {
    execute(config, type) {
        const promise = new Promise((resolve, reject) => {
            var _a, _b;
            const request = new XMLHttpRequest();
            request.onreadystatechange = () => {
                if (request.readyState === 4 &&
                    request.status >= 200 &&
                    request.status < 300) {
                    resolve(this.prepareResponse(request));
                }
                else if (request.readyState === 4 &&
                    request.status >= 300 &&
                    request.status <= 599) {
                    reject(this.prepareResponse(request));
                }
            };
            request.onerror = () => {
                reject(this.prepareResponse(request));
            };
            request.withCredentials = Boolean((_a = config.withCredentials) !== null && _a !== void 0 ? _a : true);
            const payload = this.prepareDataToSend(config.data, type);
            const isGet = type === "GET";
            const url = `${config.url}${isGet && Utils.stringNotEmpty(payload) ? payload : ""}`;
            request.open(type, url, true);
            if (!isGet && !((_b = config.headers) === null || _b === void 0 ? void 0 : _b["Content-Type"])) {
                request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            }
            if (config.headers) {
                this.applyAllRequestHeaders(request, config.headers);
            }
            request.send(!isGet ? payload : undefined);
        });
        return promise;
    }
    get(config) {
        return this.execute(config, "GET");
    }
    post(config) {
        return this.execute(config, "POST");
    }
    put(config) {
        return this.execute(config, "PUT");
    }
    delete(config) {
        return this.execute(config, "DELETE");
    }
    prepareDataToSend(data, type) {
        let output;
        if (type !== "GET") {
            if (data instanceof URLSearchParams) {
                return data.toString();
            }
            try {
                output = JSON.stringify(data);
            }
            catch (e) {
                output = data.toString();
            }
            return output;
        }
        else if (Utils.isObject(data)) {
            output = "?";
            const temporalArray = Object.keys(data).map(key => {
                let value;
                if (Utils.isObject(data[key]) || Utils.isArray(data[key])) {
                    try {
                        value = encodeURIComponent(JSON.stringify(data[key]));
                    }
                    catch (e) {
                        value = encodeURIComponent(data[key]);
                    }
                }
                else {
                    value = encodeURIComponent(data[key]);
                }
                return `${key}=${value}`;
            });
            output += temporalArray.join("&");
            return output;
        }
    }
    applyAllRequestHeaders(request, headers) {
        Object.keys(headers).forEach(headerKey => {
            request.setRequestHeader(headerKey, headers[headerKey]);
        });
    }
    prepareResponse(request) {
        let resultantData = this.tryParsingData(request.responseText);
        const responseHeaders = this.parseResponseHeaders(request), responseObject = {
            status: request.status,
            requestState: request.readyState,
            data: resultantData,
            responseHeaders
        };
        return responseObject;
    }
    parseResponseHeaders(request) {
        const output = {};
        if (!request.HEADERS_RECEIVED) {
            console.warn("Request headers are not yet present, returning empty object");
            return output;
        }
        const headers = request.getAllResponseHeaders();
        if (Utils.stringNotEmpty(headers)) {
            const headersArray = headers.trim().split(/[\r\n]+/);
            headersArray.forEach(headerPair => {
                const parts = headerPair.split(": "), headerKey = parts.shift(), headerValue = parts.join(": ");
                if (Utils.stringNotEmpty(headerKey)) {
                    output[headerKey] = headerValue;
                }
            });
            return output;
        }
        return output;
    }
    tryParsingData(requestResponseText) {
        if (!Utils.stringNotEmpty(requestResponseText) || requestResponseText === "undefined") {
            return undefined;
        }
        if (requestResponseText === "true") {
            return true;
        }
        if (requestResponseText === "false") {
            return false;
        }
        if (requestResponseText === "null") {
            return null;
        }
        const parsedNumber = Number(requestResponseText);
        if (!isNaN(parsedNumber)) {
            return parsedNumber;
        }
        try {
            return JSON.parse(requestResponseText);
        }
        catch (e) {
            return requestResponseText;
        }
    }
}
export const httpInstance = new Http();
