import { Utils } from "../utils/utils";
import { MarketingClientConstants } from "../constants/marketing-client-constants";
import { DeviceInformationHelper } from "../helpers/device-information-helper";
export class UserData {
    constructor() {
        this.profile = {
            url: "",
            tags: []
        };
    }
    getProfile() {
        const urlParameters = Utils.getURLParamsAsObject(), refererParam = urlParameters[MarketingClientConstants.refererParam], referrerParam = urlParameters[MarketingClientConstants.referrerParam], os = DeviceInformationHelper.getOS(), device = DeviceInformationHelper.getDeviceType();
        let dmData = Utils.getDMParams(), behavioralProfile = {};
        if (!Utils.isNullOrUndefined(dmData)) {
            dmData = dmData;
            try {
                const behavioralProfileString = dmData[MarketingClientConstants.dmBehavioralProfilesKey];
                if (Utils.stringNotEmpty(behavioralProfileString)) {
                    behavioralProfile = JSON.parse(behavioralProfileString);
                }
            }
            catch (e) {
                console.warn("Could not convert behavioral profile. Possibly empty or wrongly formatted");
            }
            Object.assign(this.profile, behavioralProfile);
            Object.assign(this.profile, dmData);
            delete this.profile[MarketingClientConstants.dmBehavioralProfilesKey];
            this.profile.url = window.location.href;
            this.profile.referer = refererParam || document.referrer;
            this.profile.referrer = referrerParam || document.referrer;
            this.profile.os = os;
            this.profile.device = device;
        }
    }
}
