var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { httpInstance } from "./http/http";
import { WYBORCZA_PL } from "./marketing-client";
import { Utils } from "./utils/utils";
import { ErrorHandler } from "./utils/error-handler-first-rw";
import { DOMHelper } from "./helpers/dom-helper";
import { HpNltClassName, HpNltObjectConstants, HpNltrrorMessage, HpNltSliderOptions, HpNltSelector, WoHpNltSelector, WoHpNltSliderOptions } from "./constants/newsletter-saver-hp-constants";
import { EventType } from "./constants/event-type-constants";
import { BigData } from "./marketing-client-bigdata";
import { BigDataEventConstants } from "./constants/big-data-constants";
import { sha256 } from "./helpers/onboarding-helper";
import Glide from "@glidejs/glide";
export class NewsletterSaverHp {
    constructor() {
        var _a;
        this.email = ((_a = WYBORCZA_PL === null || WYBORCZA_PL === void 0 ? void 0 : WYBORCZA_PL.userInfo) === null || _a === void 0 ? void 0 : _a.email) || "";
        this.path = "https://api-newsletter.wyborcza.pl/cors/profiles";
        this.emailErrorHandler = [];
        this.messages = HpNltrrorMessage;
        this.intersectionObserverOptions = {
            rootMargin: '0px',
            threshold: 0,
        };
        this.isWO = false;
        this.selectors = HpNltSelector;
    }
    init() {
        this.detectTouchPoint();
        if (!DOMHelper.querySelector(this.selectors.hpNewsletterSlider))
            return;
        this.initSlider();
        this.pauseSliderOnFocus();
        this.autoPlaySlider();
        this.initializeEmailInput();
        this.bindNewsletterEvents();
        this.initializeExpandButtonEvents();
    }
    detectTouchPoint() {
        const container = DOMHelper.querySelector(WoHpNltSelector.hpNewsletterContainer);
        if (!container)
            return;
        this.selectors = WoHpNltSelector;
        this.isWO = true;
    }
    initSlider() {
        this.glideOptions = this.isWO ? WoHpNltSliderOptions : HpNltSliderOptions;
        if (Glide)
            this.slider = new Glide(this.selectors.hpNewsletterSlider, this.glideOptions).mount();
    }
    pauseSliderOnFocus() {
        const el = DOMHelper.querySelectorAsArray(this.selectors.hpNewsletterInput);
        el === null || el === void 0 ? void 0 : el.forEach((item, index) => {
            item.addEventListener(EventType.FOCUS, (e) => __awaiter(this, void 0, void 0, function* () {
                this.slider.update({ autoplay: false });
                this.slider.pause();
            }));
        });
    }
    autoPlaySlider() {
        var _a;
        this.observer = new IntersectionObserver((entries) => this.onIntersect(entries), this.intersectionObserverOptions);
        this.observer.observe(DOMHelper.querySelector(this.selectors.hpNewsletterSlider));
        (_a = this.slider) === null || _a === void 0 ? void 0 : _a.on(['run'], () => this.slider.update({ autoplay: 5000 }));
    }
    onIntersect(entries) {
        entries.forEach((entry) => {
            var _a, _b;
            if (!entry.isIntersecting)
                return;
            (_a = this.slider) === null || _a === void 0 ? void 0 : _a.update({ autoplay: 5000 });
            (_b = this.slider) === null || _b === void 0 ? void 0 : _b.play();
        });
    }
    initializeEmailInput() {
        var _a;
        this.emailInput = DOMHelper.querySelectorAsArray(this.selectors.hpNewsletterInput);
        (_a = this.emailInput) === null || _a === void 0 ? void 0 : _a.forEach((item) => {
            this.emailErrorHandler.push(new ErrorHandler(item));
        });
    }
    bindNewsletterEvents() {
        const el = DOMHelper.querySelectorAsArray(this.selectors.hpNewsletterButton);
        el === null || el === void 0 ? void 0 : el.forEach((item, index) => {
            item.addEventListener(EventType.CLICK, (e) => __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const el = (_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.parentNode;
                const isValid = this.validateEmail(index);
                if (!isValid)
                    return;
                const input = el.querySelector(this.selectors.hpNewsletterInput);
                const email = DOMHelper.getEmailValue(input);
                const newsletterId = (_c = input === null || input === void 0 ? void 0 : input.getAttribute(HpNltObjectConstants.newsletterId)) !== null && _c !== void 0 ? _c : "";
                const response = yield this.subscribe(email, newsletterId);
                if (response) {
                    this.showThankYouContent(el);
                    this.sendBigDataEvent(email, newsletterId);
                    return;
                }
                this.emailErrorHandler[index].show(this.messages.subscriptionError);
            }));
        });
    }
    showThankYouContent(element) {
        var _a, _b;
        (_a = element === null || element === void 0 ? void 0 : element.classList) === null || _a === void 0 ? void 0 : _a.toggle(HpNltClassName.hidden);
        (_b = element === null || element === void 0 ? void 0 : element.nextElementSibling) === null || _b === void 0 ? void 0 : _b.classList.toggle(HpNltClassName.hidden);
    }
    sendBigDataEvent(email, newsletterId) {
        return __awaiter(this, void 0, void 0, function* () {
            BigData.sendDataOceanEvent(BigDataEventConstants.onAddingToNewsletter, {
                hm: yield sha256(email),
                newsletter: [newsletterId]
            });
        });
    }
    validateEmail(index) {
        const value = DOMHelper.getEmailValue(this.emailInput[index]), emailIsValid = Utils.validateEmail(value);
        if (!value.length) {
            return this.emailErrorHandler[index].show(this.messages.required);
        }
        else if (!emailIsValid) {
            return this.emailErrorHandler[index].show(this.messages.banned);
        }
        else {
            return this.emailErrorHandler[index].hide();
        }
    }
    subscribe(email, newsletterId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!email || !newsletterId)
                return;
            const data = {
                email,
                newsletters: [newsletterId],
                confirmed: false
            };
            return yield httpInstance
                .post({
                url: `${this.path}/override`,
                data: data,
                withCredentials: false,
            })
                .then((response) => __awaiter(this, void 0, void 0, function* () { return response.status === 200; }))
                .catch(() => false);
        });
    }
    initializeExpandButtonEvents() {
        const button = DOMHelper.querySelector(this.selectors.hpNewsletterExpandBtn);
        button === null || button === void 0 ? void 0 : button.addEventListener(EventType.CLICK, () => {
            var _a;
            button.classList.toggle(HpNltClassName.expandButtonActive);
            (_a = DOMHelper.querySelector(this.selectors.hpNewsletterAgreements)) === null || _a === void 0 ? void 0 : _a.classList.toggle(HpNltClassName.collapseAgreements);
        });
    }
}
