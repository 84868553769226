import { CopConstants } from "../../constants/cop-constants";
import { OnboardingConstants, RecommendationOnboardingConst } from "../../constants/onboarding-constants";
import { Utils } from "../utils";
export default class RecommendationBox {
    constructor(recommendation, tile, collectionIdsObject, ctaObject, pageViewId) {
        const { id, lead, params, photoUrl, publicationDate, example, salesProductId, signature, title, url } = recommendation;
        this.id = id;
        this.lead = lead;
        this.params = params;
        this.photoUrl = photoUrl;
        this.publicationDate = publicationDate;
        this.example = example;
        this.salesProductId = salesProductId;
        this.signature = signature;
        this.title = title;
        this.url = url;
        this.pageViewId = pageViewId;
        this.tile = tile;
        this.collectionIdsObject = collectionIdsObject;
        this.ctaObject = ctaObject;
        this.recommendationUrl = this.addParamsToUrl(url);
    }
    addParamsToUrl(url) {
        try {
            const newUrl = new URL(url), { cta = "" } = this.ctaObject, { campaignId = "", actionId = "", touchPointId = "" } = this.collectionIdsObject;
            newUrl.searchParams.append("cta", cta);
            newUrl.searchParams.append("actionId", actionId);
            newUrl.searchParams.append("campaignId", campaignId);
            newUrl.searchParams.append("touchPointId", touchPointId);
            newUrl.searchParams.append("art_pvid", this.pageViewId);
            newUrl.searchParams.append("tile", this.tile.toString());
            return newUrl.toString();
        }
        catch (error) {
            return url;
        }
    }
    createBoxElement() {
        const boxElement = document.createElement("div");
        boxElement.classList.add(RecommendationOnboardingConst.sliderElement);
        const tag = document.createElement("a");
        if (this.params.link) {
            tag.setAttribute("href", this.addParamsToUrl(this.params.link));
        }
        tag.setAttribute("target", "_blank");
        tag.classList.add(RecommendationOnboardingConst.sliderTag);
        tag.innerHTML = this.params.tagName;
        const title = document.createElement("a");
        title.setAttribute("href", this.recommendationUrl);
        title.setAttribute("target", "_blank");
        title.setAttribute("data-bd-viewability", OnboardingConstants.viewAbility);
        title.setAttribute("data-bd-viewability-id", OnboardingConstants.viewAbilityId);
        title.setAttribute("data-bd-viewability-href", this.recommendationUrl);
        title.classList.add(RecommendationOnboardingConst.sliderTitle);
        title.innerHTML = this.title;
        boxElement.appendChild(this.createPhotoElement());
        boxElement.appendChild(tag);
        boxElement.appendChild(title);
        return boxElement;
    }
    createPhotoElement() {
        const link = document.createElement("a");
        link.setAttribute("href", this.recommendationUrl);
        link.setAttribute("target", "_blank");
        link.classList.add(RecommendationOnboardingConst.sliderPhotoWrapper);
        const img = document.createElement("img");
        img.setAttribute("src", Utils.changeFormatImg(this.photoUrl, CopConstants.ddboxGraphicFormat));
        img.classList.add(RecommendationOnboardingConst.sliderPhoto);
        link.appendChild(img);
        return link;
    }
}
