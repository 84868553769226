export const WidgetHpPhotoSelectors = {
    section: ".nw-widget-photo, .wo-widget-photo",
};
export const WidgetHpPhotoConstans = {
    coordsAttribute: "coords",
    coordsSeparator: ","
};
export const MapAreaDataConst = [
    {
        sizePhoto: { x: 904, y: 904 },
        imageSelector: ".nw-widget-photo__mobile img",
        areasSelector: ".nw-widget-photo__mobile area"
    },
    {
        sizePhoto: { x: 652, y: 1386 },
        imageSelector: ".wo-area-elements-mobile img",
        areasSelector: ".wo-area-elements-mobile area"
    },
    {
        sizePhoto: { x: 1352, y: 364 },
        imageSelector: ".wo-area-elements-desktop img",
        areasSelector: ".wo-area-elements-desktop area"
    }
];
