export const ObjectConstants = {
    active: "active"
};
export const Selector = {
    accordionHeader: ".nw_2023_closing_without_lp-content-accordion-header",
    accordionArrow: ".nw_2023_closing_without_lp-content-accordion-header-arrow",
    hardClosingAccordionHeader: ".nw_2023_hard_closing-content-accordion-header",
    hardClosingAccordionArrow: ".nw_2023_hard_closing-content-accordion-header-arrow",
    hardClosing: ".nw_2023_hard_closing",
    hardClosingDesktopScrollButton: ".nw_2023_hard_closing-content-box-desktop-action-scroll",
    hardClosingMobileActionButton: ".nw_2023_hard_closing-content-action-mobile-button-container",
    hardClosingMobileFixedButton: ".mobile-fixed-button",
    hardClosingMobileScrollButton: ".nw_2023_hard_closing-content-scroll-button",
    hardClosingConditionsOfPromotion: ".nw_2023_hard_closing-content-conditions-promotion",
    hardClosingAccordionsContent: ".nw_2023_hard_closing-content-scrollable",
    hardClosingHeader: ".nw_2023_hard_closing-header",
    closer: "closer",
    sticky: "sticky",
    unvisible: "unvisible",
    hidden: "hidden",
    placeholder: '[data-mc-placeholder-id="PLDM_PAYWALL"]',
    marketingParams: 'domain_marketing_params',
    padlock: '.nw_2021_padlock',
    fixedScreening: 'fixedScreening',
    fixedPadlock: "fixedPadlock"
};
