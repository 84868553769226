export var EventType;
(function (EventType) {
    EventType["RESIZE"] = "resize";
    EventType["SCROLL"] = "scroll";
    EventType["SUBMIT"] = "submit";
    EventType["CLICK"] = "click";
    EventType["DISPLAY"] = "display";
    EventType["CHANGE"] = "change";
    EventType["INPUT"] = "input";
    EventType["ORIENTATION_CHANGE"] = "orientationchange";
    EventType["MOUSELEAVE"] = "mouseleave";
    EventType["MOUSEWHEEL"] = "mousewheel";
    EventType["MOUSEMOVE"] = "mousemove";
    EventType["TOUCHMOVE"] = "touchmove";
    EventType["FOCUS"] = "focus";
    EventType["LOAD"] = "load";
})(EventType || (EventType = {}));
