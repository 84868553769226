export default class ReadLaterView {
    static doAnimation(button, destElement) {
        const initRect = button.getBoundingClientRect();
        const destRect = destElement.getBoundingClientRect();
        const icon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        const symbol = document.createElementNS('http://www.w3.org/2000/svg', 'use');
        console.log('%cReadLater', 'color: #faa', 'animation');
        symbol.setAttributeNS(null, 'href', '#sbook');
        icon.insertAdjacentElement('beforeend', symbol);
        icon.classList.add('read-later--ghost');
        icon.style.top = (initRect.top + initRect.height / 2 + window.scrollY) + 'px';
        icon.style.left = (initRect.left + initRect.width / 2) + 'px';
        document.body.insertAdjacentElement('beforeend', icon);
        icon.style.top = (destRect.top + destRect.height / 2 + window.scrollY) + 'px';
        icon.style.left = (destRect.left + destRect.width / 2) + 'px';
        setTimeout(() => icon.remove(), 1100);
    }
}
