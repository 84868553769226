var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
class ReadLaterService {
    static add(pageId, pageUrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield fetch(this.apiUrl, {
                    method: 'PUT',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify([{
                            pageId,
                            pageUrl
                        }]),
                });
                if (!res.ok) {
                    throw new Error('Internal server error in ReadLater.add()');
                }
                return true;
            }
            catch (error) {
                console.log('%cReadLater', 'color: #faa', 'error adding');
            }
            return false;
        });
    }
    static remove(pageId) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield fetch(this.apiUrl + pageId, {
                    method: 'DELETE',
                    cache: 'no-cache',
                });
                if (!res.ok) {
                    throw new Error('Internal server error in ReadLater.remove()');
                }
                return true;
            }
            catch (error) {
                console.log('%cReadLater', 'color: #faa', 'error removing');
            }
            return false;
        });
    }
    static list(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield fetch(this.apiUrl + '?page=0&size=1000', {
                    cache: 'no-cache',
                });
                if (!res.ok) {
                    throw new Error('Internal server error in ReadLater.list()');
                }
                const data = yield res.json();
                const pageId = data.content.length && data.content[0].pageId !== id;
                const content = (data.content.length > 1 ? data.content[1] : null);
                const suggested = pageId ? data.content[0] : content;
                return {
                    list: data.content.map((element) => element.pageId),
                    suggested: suggested,
                    total: data.totalElements,
                };
            }
            catch (error) {
                console.log('%cReadLater', 'color: #faa', 'error getting list');
            }
            return {
                list: [],
                suggested: null,
                total: 0,
            };
        });
    }
}
ReadLaterService.apiUrl = '/api/read-later/v2/pages/';
export default ReadLaterService;
