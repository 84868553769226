import { EventType } from "../constants/event-type-constants";
import { DefaultActiveSlideSelector } from "../constants/slider-constants";
import { DOMHelper } from "../helpers/dom-helper";
export class Slider {
    constructor(params) {
        this.currentSlide = 0;
        this.activeSlideSelector = DefaultActiveSlideSelector;
        if (params.activeSlideSelector) {
            this.activeSlideSelector = params.activeSlideSelector;
        }
        this.slides = DOMHelper.querySelectorAsArray(params.slidesSelector);
        this.setCurrentSlide();
        this.setSlide(this.currentSlide);
        this.bindButtons(params.nextButtonSelector, params.prevButtonSelector);
        this.nextCallback = params.nextCallback;
        this.prevCallback = params.prevCallback;
    }
    setCurrentSlide() {
        this.currentSlide = Math.max(0, this.slides.findIndex((el) => el.classList.contains(this.activeSlideSelector)));
    }
    bindButtons(nextButtonSelector, prevButtonSelector) {
        var _a, _b;
        (_a = DOMHelper.querySelector(nextButtonSelector)) === null || _a === void 0 ? void 0 : _a.addEventListener(EventType.CLICK, this.slideNext.bind(this));
        (_b = DOMHelper.querySelector(prevButtonSelector)) === null || _b === void 0 ? void 0 : _b.addEventListener(EventType.CLICK, this.slidePrev.bind(this));
    }
    slidePrev() {
        var _a;
        this.currentSlide--;
        if (this.currentSlide < 0) {
            this.currentSlide = this.slides.length - 1;
        }
        this.setSlide(this.currentSlide);
        (_a = this.prevCallback) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    slideNext() {
        var _a;
        this.currentSlide++;
        if (this.currentSlide > this.slides.length - 1) {
            this.currentSlide = 0;
        }
        this.setSlide(this.currentSlide);
        (_a = this.nextCallback) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    setSlide(index) {
        var _a;
        this.slides.forEach((slide) => {
            slide.classList.remove(this.activeSlideSelector);
        });
        (_a = this.slides[index]) === null || _a === void 0 ? void 0 : _a.classList.add(this.activeSlideSelector);
    }
}
