export const CopConstants = {
    cop: ".cop_banners",
    dataDownloadArticle: "data-download-Article",
    dataKeyValue: "data-key-value",
    dataWidgetId: "data-widgetid",
    dataDomainKey: "data-domain-key",
    dataDmParameter: "data-dm-parameter",
    box: "#box",
    bookmark: "#bookmark",
    boxHeader: ".recommendation-box-header",
    boxImg: ".recommendation-box-image",
    boxDescription: ".recommendation-box-description",
    boxButtonsWrap: ".recommendation-box-buttons",
    boxRecommendationAnchor: ".recommendation-box-anchor",
    swimmerArticleLoader: ".cop_swimmer_carousel-button-loader",
    visible: "is-visible",
    hidden: "hidden",
    ddboxGraphicFormat: "II",
    swimmerDesktopGraphicFormat: "H",
    swimmerMobileGraphicFormat: "NWA",
    fetchFailedMessage: "Failed to Fetch recommended articles",
};
