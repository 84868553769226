import { EventType } from "./constants/event-type-constants";
import { MarketingClientConstants, OperatingSystems } from "./constants/marketing-client-constants";
import { NEXT_STEP, OnboardingConstants, PREV_STEP } from "./constants/onboarding-constants";
import { DeviceInformationHelper } from "./helpers/device-information-helper";
import { DOMHelper } from "./helpers/dom-helper";
export class AppDownloadDdbox {
    constructor(onboarding) {
        this.prevBtnSelector = ".mc-app-download-back-btn";
        this.nextBtnSelector = ".mc-app-download-forward-btn";
        this.finishButtonSelector = ".mc-app-download-save-btn";
        this.downloadButtonSelector = ".mc-download-button";
        this.androidButtonSelector = ".mc-download-button-android";
        this.iosButtonSelector = ".mc-download-button-ios";
        this.downloadLinkSelector = ".ddbox-onboarding__app-download__qr-container-instruction__link";
        this.prevButton = DOMHelper.querySelector(this.prevBtnSelector);
        this.nextButton = DOMHelper.querySelector(this.nextBtnSelector);
        this.finishButton = DOMHelper.querySelector(this.finishButtonSelector);
        this.prevStepEvent = new Event(PREV_STEP);
        this.nextStepEvent = new Event(NEXT_STEP);
        this.onboarding = onboarding;
    }
    init() {
        this.updateDownloadIcons();
        this.bindNavigationEvents();
        this.bindFinishEvent();
        this.bindDownloadEvent();
    }
    updateDownloadIcons() {
        const os = DeviceInformationHelper.getOS();
        this.hideIcon();
        if (os === OperatingSystems.ANDROID) {
            this.showIcon(this.androidButtonSelector);
            return;
        }
        if (os === OperatingSystems.IPHONE_OS) {
            this.showIcon(this.iosButtonSelector);
        }
    }
    hideIcon() {
        const buttons = DOMHelper.querySelectorAsArray(this.downloadButtonSelector);
        buttons.forEach((button) => {
            if (button)
                button.classList.add(MarketingClientConstants.hiddenClassName);
        });
    }
    showIcon(selector) {
        const button = DOMHelper.querySelector(selector);
        if (button)
            button.classList.remove(MarketingClientConstants.hiddenClassName);
    }
    bindNavigationEvents() {
        var _a, _b;
        (_a = this.nextButton) === null || _a === void 0 ? void 0 : _a.addEventListener(EventType.CLICK, () => {
            this.onboarding.sendBigDataEvent(OnboardingConstants.nextStepEvent);
            window.dispatchEvent(this.nextStepEvent);
        });
        (_b = this.prevButton) === null || _b === void 0 ? void 0 : _b.addEventListener(EventType.CLICK, () => {
            this.onboarding.sendBigDataEvent(OnboardingConstants.prevStepEvent);
            window.dispatchEvent(this.prevStepEvent);
        });
    }
    bindFinishEvent() {
        var _a;
        (_a = this.finishButton) === null || _a === void 0 ? void 0 : _a.addEventListener(EventType.CLICK, () => {
            this.onboarding.finishOnboarding();
        });
    }
    bindDownloadEvent() {
        const link = DOMHelper.querySelector(this.downloadLinkSelector);
        const androidButton = DOMHelper.querySelector(this.androidButtonSelector);
        const iosButton = DOMHelper.querySelector(this.iosButtonSelector);
        link === null || link === void 0 ? void 0 : link.addEventListener(EventType.CLICK, () => {
            this.onboarding.sendBigDataEvent(OnboardingConstants.downloadApp);
        });
        androidButton === null || androidButton === void 0 ? void 0 : androidButton.addEventListener(EventType.CLICK, () => {
            this.onboarding.sendBigDataEvent(OnboardingConstants.downloadAppAndroid);
        });
        iosButton === null || iosButton === void 0 ? void 0 : iosButton.addEventListener(EventType.CLICK, () => {
            this.onboarding.sendBigDataEvent(OnboardingConstants.downloadAppIos);
        });
    }
}
