import { MarketingClientConstants } from "../constants/marketing-client-constants";
import { Utils } from "../utils/utils";
import { DOMHelper } from "./dom-helper";
export class ShareArticleErrorHandler {
    constructor(email) {
        this.errorMessageContainerClass = ".share-article-error-message";
        this.dataEmailErrorSelector = "[data-error=email]";
        this.email = email;
        this.element = DOMHelper.querySelector(this.dataEmailErrorSelector);
        this.shareArticleErrorMessageElement = this.element && DOMHelper.querySelector(this.errorMessageContainerClass, this.element);
        if (this.shareArticleErrorMessageElement) {
            this.defaultMessage = this.shareArticleErrorMessageElement.innerHTML;
        }
    }
    setMessage(message) {
        if (this.shareArticleErrorMessageElement) {
            this.shareArticleErrorMessageElement.innerHTML = (message || this.defaultMessage);
        }
    }
    ;
    show(message = "") {
        var _a;
        this.setMessage(message);
        Utils.showContainer(this.element);
        (_a = this.email) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hasErrorClassName);
        return false;
    }
    hide() {
        var _a;
        Utils.hideContainer(this.element);
        (_a = this.email) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hasErrorClassName);
        return true;
    }
}
export var LayerType;
(function (LayerType) {
    LayerType["DEFAULT"] = "default";
    LayerType["SUCCESS"] = "success";
    LayerType["BLOCKED"] = "blocked";
    LayerType["ERROR"] = "error";
})(LayerType || (LayerType = {}));
export const WYBORCZA_PL = window["wyborcza_pl"] || {};
