import { EventType } from "./constants/event-type-constants";
import { NEXT_STEP, OnboardingConstants, PREV_STEP } from "./constants/onboarding-constants";
import { DOMHelper } from "./helpers/dom-helper";
export class Welcome {
    constructor(onboarding) {
        this.prevBtnSelector = ".mc-welcome-back-btn";
        this.nextBtnSelector = ".mc-welcome-forward-btn";
        this.finishButtonSelector = ".mc-welcome-save-btn";
        this.prevButton = DOMHelper.querySelector(this.prevBtnSelector);
        this.nextButton = DOMHelper.querySelector(this.nextBtnSelector);
        this.finishButton = DOMHelper.querySelector(this.finishButtonSelector);
        this.prevStepEvent = new Event(PREV_STEP);
        this.nextStepEvent = new Event(NEXT_STEP);
        this.onboarding = onboarding;
    }
    init() {
        this.bindNavigationEvents();
        this.bindFinishEvent();
    }
    bindNavigationEvents() {
        var _a, _b;
        (_a = this.nextButton) === null || _a === void 0 ? void 0 : _a.addEventListener(EventType.CLICK, () => {
            this.onboarding.sendBigDataEvent(OnboardingConstants.nextStepEvent);
            window.dispatchEvent(this.nextStepEvent);
        });
        (_b = this.prevButton) === null || _b === void 0 ? void 0 : _b.addEventListener(EventType.CLICK, () => {
            this.onboarding.sendBigDataEvent(OnboardingConstants.prevStepEvent);
            window.dispatchEvent(this.prevStepEvent);
        });
    }
    bindFinishEvent() {
        var _a;
        (_a = this.finishButton) === null || _a === void 0 ? void 0 : _a.addEventListener(EventType.CLICK, () => {
            this.onboarding.finishOnboarding();
        });
    }
}
