import { Utils } from "./utils";
export class Spinner {
    constructor(selector) {
        this.element = document.querySelector(selector);
    }
    show() {
        Utils.showContainer(this.element);
    }
    hide() {
        Utils.hideContainer(this.element);
    }
}
