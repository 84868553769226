export const UtilizationConstants = {
    dataUtilizeRegistrationWall: "data-utilize-registration-wall",
    dataUtilizeChild: "data-utilize-child",
    dataUtilize: "data-utilize",
    dataCampaignId: "data-campaign-id",
    dataActionId: "data-action-id",
    dataTouchPointId: "data-touch-point-id",
    dataReferrer: "data-referrer",
    tagA: "a",
    tagArea: "area",
    classMcBan: ".mcBan",
    classMod: ".mod",
    visible: "is-visible"
};
