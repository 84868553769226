export const requiredOrderConstants = {
    marketing: false,
    businessParamsDto: {},
    accessToken: null,
    accountToRegister: false,
    blikCode: null,
    brand: "WYBORCZA",
    cardId: null,
    dataOcean: {},
    familyEmails: [],
    firstName: "null",
    lastName: "null",
    marketingParams: {},
    openAccess: true,
    registerCard: false,
    terms: true,
    totalPrice: 0
};
