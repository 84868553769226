import { MarketingClientSWFBase } from "./marketing-client-swf-base";
import { MarketingClientConstants } from "./constants/marketing-client-constants";
import { EventType } from "./constants/event-type-constants";
import { DOMHelper } from "./helpers/dom-helper";
import { LayerType } from "./helpers/swf-helper";
export class MarketingClientShareArticleOld extends MarketingClientSWFBase {
    constructor(utilizationObject) {
        super(utilizationObject);
        this.shareArticleInfoBtn = ".share-article-info-btn";
        this.wyborczaHat = "#wyborczaHat";
    }
    init() {
        this.initialize();
        this.bindEvents();
    }
    bindEvents() {
        this.bindEventsToModals();
        this.bindEventsToFormSubmit();
        this.bindEventsToEmailInput();
    }
    bindEventsToModals() {
        var _a;
        if (!this.form)
            return;
        (_a = DOMHelper.querySelector(this.shareArticleInfoBtn)) === null || _a === void 0 ? void 0 : _a.addEventListener(EventType.CLICK, () => {
            if (this.form) {
                this.form.reset();
            }
            this.errorHandler.hide();
            this.showLayer(LayerType.DEFAULT);
        });
    }
    showLayer(layer = LayerType.DEFAULT, withScrollingOnMobile = false) {
        var _a;
        Object.values(this.layers).forEach((modal) => modal.classList.add(MarketingClientConstants.hiddenClassName));
        (_a = this.layers[layer]) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hiddenClassName);
        if (withScrollingOnMobile && this.isMobile) {
            this.scrollToLayer(this.layers[layer]);
        }
    }
    successResponseHandler(response) {
        if (response.status) {
            this.showLayer(LayerType.SUCCESS, true);
        }
        else if (response.result === LayerType.BLOCKED.toUpperCase()) {
            this.showLayer(LayerType.BLOCKED, true);
        }
        else {
            this.showLayer(LayerType.ERROR, true);
        }
    }
    errorResponseHandler() {
        this.showLayer(LayerType.ERROR, true);
    }
    scrollToLayer(layer) {
        let cap;
        cap = DOMHelper.getElementById(this.wyborczaHat);
        const capHeight = (cap === null || cap === void 0 ? void 0 : cap.offsetHeight) || 0, layerTop = layer.offsetTop, scrollTop = window.scrollY + capHeight;
        if (layerTop < scrollTop) {
            window.scrollTo({
                top: layerTop - capHeight - 20,
                behavior: "smooth"
            });
        }
    }
}
