export const RwObjectConstants = {
    dataArticleCounter: "data-rw-article-counter",
    dataStage: "data-rw-stage",
    dataStageEndDate: "data-rw-stage-end-date"
};
export const RwSelector = {
    rwFirstPromotionSelector: ".rw-first-promotion",
    rwSecondPromotionSelector: ".rw-second-promotion",
    rwOwnerSelector: ".rw_owner"
};
export const RwAccountErrorCode = {
    accountExists: "konto istnieje",
    accountUnconfirmed: "konto do aktywacji"
};
export const RwErrorMessage = {
    required: "Pole e-mail jest wymagane!",
    banned: "Podany e-mail jest błędny, wpisz poprawny adres",
    accountExists: "Masz już konto w Wyborczej",
    accountUnconfirmed: `Proces zakładania konta już rozpoczęty. Nie masz e-maila aktywacyjnego? <a style="text-decoration: underline;">Wyślij go ponownie.</a>`,
    subscriptionError: 'Błąd! Nie udało się zapisać na newsletter. Spróbuj ponownie za chwilę.'
};
