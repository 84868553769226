import { DOMHelper } from "./dom-helper";
import { MarketingClientConstants } from "../constants/marketing-client-constants";
export var DeviceType;
(function (DeviceType) {
    DeviceType["MOBILE"] = "mobile";
    DeviceType["DESKTOP"] = "desktop";
})(DeviceType || (DeviceType = {}));
export var PageType;
(function (PageType) {
    PageType["ARTICLE"] = "article";
    PageType["HP"] = "hp";
})(PageType || (PageType = {}));
export class DeviceInformationHelper {
    static getDeviceType() {
        var _a;
        return (_a = DOMHelper.querySelector(MarketingClientConstants.rootElement)) === null || _a === void 0 ? void 0 : _a.getAttribute("data-device");
    }
    static getOS() {
        var _a;
        return (_a = DOMHelper.querySelector(MarketingClientConstants.rootElement)) === null || _a === void 0 ? void 0 : _a.getAttribute("data-os");
    }
    static getXX() {
        var _a;
        return (_a = DOMHelper.querySelector(MarketingClientConstants.rootElement)) === null || _a === void 0 ? void 0 : _a.getAttribute("data-xx");
    }
    static getPageType() {
        var _a;
        return (_a = DOMHelper.querySelector(MarketingClientConstants.rootElement)) === null || _a === void 0 ? void 0 : _a.getAttribute("data-pagetype");
    }
}
