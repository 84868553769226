var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class AdsRewarded {
    constructor() {
        this.apiUrl = "https://access.wyborcza.pl/additionalAccess";
        this.nodeSelector = "[data-rewarded-ads]";
        this.slotInstance = null;
        this.slotPosition = "019-TOPLAYER";
        this.logger = (...args) => {
            console.log("%cAdsRewarded", "color: #fed049", ...args);
        };
        this.init = () => {
            var _a;
            this.nodeElements = document.querySelectorAll(this.nodeSelector);
            if (!((_a = this.nodeElements) === null || _a === void 0 ? void 0 : _a.length))
                return;
            this.initSlot();
        };
        this.initSlot = () => {
            // @ts-ignore
            window.googletag = window.googletag || { cmd: [] };
            const searchParams = new URLSearchParams(location.search);
            const slotUnitPath = searchParams.get("adsRewardedSlot");
            googletag.cmd.push(() => {
                this.slotInstance = googletag.defineOutOfPageSlot(slotUnitPath !== null && slotUnitPath !== void 0 ? slotUnitPath : `${dfpParams.prefix}/${this.slotPosition}`, googletag.enums.OutOfPageFormat.REWARDED);
                this.logger("initSlot", this.slotInstance);
                if (!this.slotInstance)
                    return;
                this.slotInstance
                    .setTargeting("kw", [...AdviewAdsTag.kwTargeting, "rewarded"])
                    .setTargeting("pos", [this.slotPosition])
                    .setTargeting("yb_ab", AdviewAdsTag._YB.ab())
                    .setTargeting("domena", location.hostname)
                    .setTargeting("dir", dfpParams.dir)
                    .setTargeting("dx", dfpParams.dx);
                this.slotInstance.addService(googletag.pubads());
                googletag
                    .pubads()
                    .addEventListener("rewardedSlotReady", this.onReady)
                    .addEventListener("rewardedSlotClosed", this.onClosed)
                    .addEventListener("rewardedSlotGranted", this.onGranted);
                googletag.pubads().refresh([this.slotInstance]);
            });
        };
        this.destroySlot = () => {
            googletag.destroySlots([this.slotInstance]);
            this.slotInstance = null;
        };
        this.onReady = (event) => {
            var _a;
            this.logger("onReady", event);
            (_a = this.nodeElements) === null || _a === void 0 ? void 0 : _a.forEach((node) => {
                node.addEventListener("click", (e) => {
                    if (!this.slotInstance)
                        return;
                    e.preventDefault();
                    e.stopPropagation();
                    event.makeRewardedVisible();
                });
            });
        };
        this.onClosed = (event) => {
            this.logger("onClosed", event);
            this.destroySlot();
        };
        this.onGranted = (event) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            this.logger("onGranted", event);
            const res = yield fetch(this.apiUrl, {
                method: "post",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: (_a = document.body.dataset.xx) !== null && _a !== void 0 ? _a : ""
            });
            (res === null || res === void 0 ? void 0 : res.status) === 200 ? this.onSuccess() : this.onError();
        });
        this.onSuccess = () => {
            this.logger("onSuccess");
            location.reload();
        };
        this.onError = () => {
            this.logger("onError");
            this.destroySlot();
        };
    }
}
