import { EventType } from "./constants/event-type-constants";
import { UtilizationConstants } from "./constants/marketing-client-utilization-constants";
import { Utils } from "./utils/utils";
import { MarketingClientAttributesConstants } from "./constants/marketing-client-attributes-constants";
import { BigData } from "./marketing-client-bigdata";
import { BigDataEventConstants } from "./constants/big-data-constants";
import { BigDataHelper } from "./helpers/big-data-helper";
export class Utilization {
    constructor(http) {
        this.http = http;
    }
    init() {
        this.searchLinksToUtilize();
        this.bindUtilization();
    }
    setGUID(guid) {
        this.guid = guid;
    }
    searchLinksToUtilize() {
        this.links = document.querySelectorAll(`[${UtilizationConstants.dataUtilize}]`);
    }
    bindUtilization() {
        this.links.forEach((link) => {
            const utilizeChild = link.querySelector(`[${UtilizationConstants.dataUtilizeChild}]`), utilizeLinkElement = utilizeChild ? link.querySelector(UtilizationConstants.tagA) : link;
            if (utilizeLinkElement) {
                utilizeLinkElement.removeEventListener(EventType.CLICK, (event) => {
                    this.utilizeLink(event);
                });
                utilizeLinkElement.addEventListener(EventType.CLICK, (event) => {
                    this.utilizeLink(event);
                });
            }
        });
    }
    utilizeLink(event) {
        var _a, _b;
        const linkElementFromTarget = event.target, linkNodeName = linkElementFromTarget.nodeName.toLocaleLowerCase(), isTagAOrTagArea = [UtilizationConstants.tagA, UtilizationConstants.tagArea].includes(linkNodeName), linkElement = isTagAOrTagArea
            ? linkElementFromTarget
            : linkElementFromTarget.closest(UtilizationConstants.tagA), parentLinkElement = linkElement &&
            (linkElement.closest(`[${MarketingClientAttributesConstants.placeholderId}]`) ||
                linkElement.closest(`[${MarketingClientAttributesConstants.dataDm}]`)), condition = (_a = (linkElement === null || linkElement === void 0 ? void 0 : linkElement.getAttribute(UtilizationConstants.dataUtilize))) !== null && _a !== void 0 ? _a : ((_b = linkElement === null || linkElement === void 0 ? void 0 : linkElement.closest(`[${UtilizationConstants.dataUtilizeChild}]`)) === null || _b === void 0 ? void 0 : _b.getAttribute(UtilizationConstants.dataUtilize)), referrer = (linkElement === null || linkElement === void 0 ? void 0 : linkElement.getAttribute(UtilizationConstants.dataReferrer)) === "";
        let href = linkElement === null || linkElement === void 0 ? void 0 : linkElement.getAttribute("href");
        if (href && referrer) {
            href = Utils.addReferrerAndLpParams(href);
        }
        if (condition && parentLinkElement) {
            event.preventDefault();
            this.sendBigDataEvent(parentLinkElement, condition);
            const dataToSend = this.prepareDataToSend(parentLinkElement, condition), utilize = this.utilize(dataToSend);
            utilize.then(() => {
                if (href && window.location) {
                    window.location.href = href;
                }
            });
        }
    }
    sendBigDataEvent(parentLinkElement, condition = "brak") {
        const attribute = BigDataHelper.createAttribute(parentLinkElement, true), dataToSend = this.prepareDataToSend(parentLinkElement, condition);
        BigData.sendDataOceanEvent(BigDataEventConstants.onClick, Object.assign(Object.assign({}, attribute), dataToSend));
    }
    prepareDataToSend(parent, condition) {
        const visitId = BigDataHelper.getVisitId(), pageViewId = BigDataHelper.getPageViewId(), collectionIdsObject = Utils.prepareCollectionIdsObject(parent), dataToSend = Object.assign({ guid: this.guid, visitId,
            pageViewId,
            condition }, collectionIdsObject);
        return dataToSend;
    }
    utilize(data) {
        const isHarmonyTest = Utils.getURLParamsAsObject().enableHarmonyTest, url = isHarmonyTest ? process.env["HARMONY_TEST_URL"] : process.env["MARKETING_PROXY_URL"], headers = { "Content-Type": "application/json" };
        return this.http.post({
            url: `${url}events/utilize`,
            headers,
            data: data
        });
    }
}
