var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BigDataObjectConstants } from "../../constants/big-data-object-constants";
import { NEXT_STEP, OnboardingConstants, PREV_STEP, RecommendationOnboardingConst } from "../../constants/onboarding-constants";
import { BigDataHelper } from "../../helpers/big-data-helper";
import { DOMHelper } from "../../helpers/dom-helper";
import { BigData } from "../../marketing-client-bigdata";
import { Slider } from "../slider";
import RecommendationBox from "./recommendation-box";
import { EventType } from "../../constants/event-type-constants";
export default class RecommendationOnboarding {
    constructor(onboarding, ddbox) {
        this.prevBtnSelector = ".mc-reco-back-btn";
        this.nextBtnSelector = ".mc-reco-forward-btn";
        this.finishButtonSelector = ".mc-reco-save-btn";
        this.ddbox = ddbox;
        this.prevButton = DOMHelper.querySelector(this.prevBtnSelector);
        this.nextButton = DOMHelper.querySelector(this.nextBtnSelector);
        this.finishButton = DOMHelper.querySelector(this.finishButtonSelector);
        this.prevStepEvent = new Event(PREV_STEP);
        this.nextStepEvent = new Event(NEXT_STEP);
        this.onboarding = onboarding;
    }
    init() {
        this.bindNavigationEvents();
        this.recommendation = DOMHelper.querySelector(`[${RecommendationOnboardingConst.dataRecommendation}]`);
        if (!this.recommendation)
            return;
        this.setPageViewId();
        this.prepareData();
        this.addDomainKeyToParams();
        this.pullRecommendations();
        this.bindFinishEvent();
    }
    bindNavigationEvents() {
        var _a, _b;
        (_a = this.nextButton) === null || _a === void 0 ? void 0 : _a.addEventListener(EventType.CLICK, () => __awaiter(this, void 0, void 0, function* () {
            this.onboarding.sendBigDataEvent(OnboardingConstants.nextStepEvent);
            window.dispatchEvent(this.nextStepEvent);
        }));
        (_b = this.prevButton) === null || _b === void 0 ? void 0 : _b.addEventListener(EventType.CLICK, () => {
            this.onboarding.sendBigDataEvent(OnboardingConstants.prevStepEvent);
            window.dispatchEvent(this.prevStepEvent);
        });
    }
    setPageViewId() {
        this.pageViewId = BigDataHelper.getBigDataOrCookieValue(BigDataObjectConstants.pageViewCookie, BigDataObjectConstants.pageViewBigData);
    }
    prepareData() {
        this.dataRecommendation = JSON.parse(decodeURIComponent(this.recommendation.getAttribute(RecommendationOnboardingConst.dataRecommendation)));
    }
    addDomainKeyToParams() {
        if (this.dataRecommendation.shouldAttachDomainKey)
            this.dataRecommendation.keyValuePair[this.dataRecommendation.domainKey] = window.location.hostname;
    }
    pullRecommendations() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.dataRecommendation.widgetID)
                return;
            try {
                const recommendations = yield BigData.getRecommendations(this.dataRecommendation.widgetID, this.dataRecommendation.keyValuePair);
                this.recommendations = recommendations
                    .slice(0, 12)
                    .map((recommendation, index) => new RecommendationBox(recommendation, index + 1, this.ddbox.collectionIdsObject, this.ddbox.ctaObject, this.pageViewId));
                this.createSlider();
            }
            catch (_a) {
                console.warn("recommendations Error");
            }
        });
    }
    createSlider() {
        const slider = DOMHelper.querySelector(`.${RecommendationOnboardingConst.sliderElements}`);
        let recommendations = [...this.recommendations];
        if (!slider) {
            return;
        }
        for (let i = 0; i < this.recommendations.length / 3; i++) {
            this.prepereElementWrapper(recommendations.slice(0, 3), slider);
            recommendations = recommendations.slice(3);
        }
        new Slider({
            slidesSelector: `.${RecommendationOnboardingConst.desktopWrapper}`,
            activeSlideSelector: RecommendationOnboardingConst.activeDesktop,
            nextButtonSelector: `.${RecommendationOnboardingConst.arrowRight}.${RecommendationOnboardingConst.arrowDesktop}`,
            prevButtonSelector: `.${RecommendationOnboardingConst.arrowLeft}.${RecommendationOnboardingConst.arrowDesktop}`,
            nextCallback: this.nextCarouselEvent.bind(this),
            prevCallback: this.prevCarouselEvent.bind(this),
        });
        new Slider({
            slidesSelector: `.${RecommendationOnboardingConst.sliderElement}`,
            activeSlideSelector: RecommendationOnboardingConst.activeElement,
            nextButtonSelector: `.${RecommendationOnboardingConst.arrowRight}.${RecommendationOnboardingConst.arrowMobile}`,
            prevButtonSelector: `.${RecommendationOnboardingConst.arrowLeft}.${RecommendationOnboardingConst.arrowMobile}`,
            nextCallback: this.nextCarouselEvent.bind(this),
            prevCallback: this.prevCarouselEvent.bind(this),
        });
    }
    prevCarouselEvent() {
        this.onboarding.sendBigDataEvent(OnboardingConstants.prevCarouselEvent);
    }
    nextCarouselEvent() {
        this.onboarding.sendBigDataEvent(OnboardingConstants.nextCarouselEvent);
    }
    prepereElementWrapper(recommendations, slider) {
        const elementWrapper = document.createElement("div");
        elementWrapper.classList.add(RecommendationOnboardingConst.desktopWrapper);
        recommendations.forEach((recommendation) => {
            elementWrapper.appendChild(recommendation.createBoxElement());
        });
        slider.appendChild(elementWrapper);
    }
    bindFinishEvent() {
        var _a;
        (_a = this.finishButton) === null || _a === void 0 ? void 0 : _a.addEventListener(EventType.CLICK, () => {
            this.onboarding.finishOnboarding();
        });
    }
}
