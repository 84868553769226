import { EventType } from "./constants/event-type-constants";
import { WidgetHpPhotoSelectors, WidgetHpPhotoConstans, MapAreaDataConst } from "./constants/widget-hp-photo-constans";
import { DOMHelper } from "./helpers/dom-helper";
export class WidgetHpPhoto {
    constructor() {
        this.elements = [];
    }
    init() {
        if (!DOMHelper.querySelector(WidgetHpPhotoSelectors.section))
            return;
        this.createCollection();
        this.bindEventsOnResizePhoto();
        this.bindEventOnResize();
    }
    bindEventsOnResizePhoto() {
        this.elements.forEach((element) => {
            this.bindEventOnLoadPhoto(element);
        });
    }
    createCollection() {
        MapAreaDataConst.forEach((element) => {
            this.getElements(element);
        });
    }
    getElements(data) {
        const image = DOMHelper.querySelector(data.imageSelector);
        const areaElements = DOMHelper.querySelectorAsArray(data.areasSelector);
        if (!image)
            return;
        this.elements.push({
            image,
            areaElements: areaElements,
            areasCords: this.getCoords(areaElements),
            sizePhoto: data.sizePhoto,
            currentSizePhoto: Object.assign({}, data.sizePhoto)
        });
    }
    getCoords(areaElements) {
        return areaElements.map((element) => {
            const coordsString = element.getAttribute(WidgetHpPhotoConstans.coordsAttribute);
            const coords = coordsString === null || coordsString === void 0 ? void 0 : coordsString.split(WidgetHpPhotoConstans.coordsSeparator).map((coordsString) => Number(coordsString));
            return coords || [];
        });
    }
    setScalingCoords(element) {
        element.areasCords.forEach((areaCoords, index) => {
            const areaCoordsScaled = areaCoords.map((coord, cordIndex) => {
                const dimension = cordIndex % 2 === 0 ? "x" : "y";
                return (coord / element.sizePhoto[dimension]) * element.currentSizePhoto[dimension];
            }), coords = areaCoordsScaled.join(WidgetHpPhotoConstans.coordsSeparator);
            coords && element.areaElements[index].setAttribute(WidgetHpPhotoConstans.coordsAttribute, coords);
        });
    }
    bindEventOnLoadPhoto(areaElement) {
        areaElement.image.addEventListener(EventType.LOAD, () => {
            this.setSizeAndUpdatePosition(areaElement);
        });
    }
    bindEventOnResize() {
        window.addEventListener(EventType.RESIZE, () => {
            this.elements.forEach((element) => {
                this.setSizeAndUpdatePosition(element);
            });
        });
    }
    setSizeAndUpdatePosition(areaElement) {
        this.setCurrentImageSize(areaElement);
        this.setScalingCoords(areaElement);
    }
    setCurrentImageSize(areaElement) {
        areaElement.currentSizePhoto.x = areaElement.image.clientWidth || areaElement.sizePhoto.x;
        areaElement.currentSizePhoto.y = areaElement.image.clientHeight || areaElement.sizePhoto.y;
    }
}
