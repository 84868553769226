export const MarketingClientAttributesConstants = {
    placeholderId: "data-mc-placeholder-id",
    isSwimmer: "data-mc-placeholder-swimmer",
    isDDBox: "data-mc-placeholder-ddbox",
    campaignId: "data-campaign-id",
    cta: "data-cta",
    ctaCategory: "data-cta-category",
    actionId: "data-action-id",
    dataDm: "data-dm",
    touchPointId: "data-touch-point-id",
    initializedClass: "is-initialized",
    videoClassSelector: "#background-cfp",
};
