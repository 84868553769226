import { EventType } from "./constants/event-type-constants";
import { MarketingClientConstants } from "./constants/marketing-client-constants";
import { RwSelector } from "./constants/rw-object.constants";
import { DOMHelper } from "./helpers/dom-helper";
import { httpInstance } from "./http/http";
import { MarketingClientRWBase } from "./marketing-client-rw-base";
import { Checkbox } from "./utils/checkbox";
import { Spinner } from "./utils/spinner";
import { BigDataClickID } from "./constants/big-data-constants";
import { Utils } from "./utils/utils";
import { LiveSession } from "./utils/livesession";
export class MarketingClientRWSecondPromotion extends MarketingClientRWBase {
    constructor(capping, utilizationObject, classSection = RwSelector.rwSecondPromotionSelector) {
        super(capping, utilizationObject, classSection);
        this.capping = capping;
        this.utilizationObject = utilizationObject;
        this.classSection = classSection;
        this.idPrefixSelector = "rwSecondPromotion-";
        this.idSuffixCheckboxA = "form-checkbox-A";
        this.classSuffix = {
            subscribeNewsletterButton: "_form-button",
            subscribeNewsletterError: "_form-subscribe-newsletter-error",
            subscribeNewsletterAccount: "_form-spinner",
        };
    }
    init() {
        this.initialize();
        this.initializeForm();
        if (!this.section || !this.subscribeNewsletterButton) {
            return;
        }
        this.initializeCheckbox();
        this.initializeSpinner();
        this.initializeLiveSession();
        this.bindEvents();
        this.showSection();
        this.showDdbox();
        if (!this.ddbox)
            this.showSwimmer();
    }
    initializeForm() {
        if (this.section) {
            this.subscribeNewsletterButton = DOMHelper.querySelector(`${this.classSection}${this.classSuffix.subscribeNewsletterButton}`, this.section);
            this.subscribeNewsletterError = DOMHelper.querySelector(`${this.classSection}${this.classSuffix.subscribeNewsletterError}`, this.section);
        }
    }
    initializeSpinner() {
        this.spinner = new Spinner(`${this.classSection}${this.classSuffix.subscribeNewsletterAccount}`);
    }
    initializeCheckbox() {
        this.checkbox = new Checkbox(`${this.idPrefixSelector}${this.idSuffixCheckboxA}`);
    }
    initializeLiveSession() {
        if (!this.section)
            return;
        new LiveSession(this.section).init();
    }
    bindEvents() {
        this.bindEventsToSubscribeNewsletter();
        this.bindSwimmerEvents();
    }
    bindEventsToSubscribeNewsletter() {
        if (this.subscribeNewsletterButton) {
            this.subscribeNewsletterButton.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.validateBeforeSubscribing();
            });
        }
    }
    validateBeforeSubscribing() {
        if (this.checkbox.isValidate()) {
            this.checkbox.hideError();
            this.hideError();
            this.spinner.show();
            this.utilize(BigDataClickID.signupAndContinue);
            this.subscribeNewsletter();
        }
        else {
            this.checkbox.showError();
        }
    }
    subscribeNewsletter() {
        return httpInstance
            .post({
            url: `${this.squidHomeUrl}squid-home/restricted/newsletters`,
            withCredentials: true
        })
            .then(this.goToMK.bind(this), this.showError.bind(this))
            .then(() => {
            this.spinner.hide();
        });
    }
    goToMK() {
        const location = window.location.href, locationWithoutHash = location.toString().replace(/#.*/g, "");
        if (this.section) {
            this.ctaObject = Utils.prepareCtaObject(this.section);
        }
        window.location.href = `https://mojekonto.wyborcza.pl/account/subscriptions?articleLink="${locationWithoutHash}"&registrationWall=true&cta=${this.ctaObject.cta}#settings`;
    }
    showError() {
        var _a;
        (_a = this.subscribeNewsletterError) === null || _a === void 0 ? void 0 : _a.classList.remove(MarketingClientConstants.hiddenClassName);
    }
    hideError() {
        var _a;
        (_a = this.subscribeNewsletterError) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
    }
}
