import { CopConstants } from "../../constants/cop-constants";
import { DOMHelper } from "../../helpers/dom-helper";
import { Utils } from "../utils";
export default class CopBox {
    constructor(boxIndex, downloadFlag) {
        this.articleParameters = {
            articleId: 0,
            pageUrl: ""
        };
        this.imageFormat = "";
        this.boxIndex = boxIndex;
        this.downloadRecommendation = downloadFlag;
    }
    init() {
        this.initializeElements();
    }
    prepareBox(recommendation, ctaObject, collectionIdsObject, pageViewId, dmParameter) {
        if (this.downloadRecommendation && recommendation && this.checkIfAllElementsExist() && this.checkIfRecoHasValuesInRequiredFields(recommendation)) {
            const recoUrl = this.buildRecommendationUrl(recommendation.url, ctaObject, collectionIdsObject, pageViewId, dmParameter);
            this.assignRecommendationData(recommendation, recoUrl);
            this.getParameters(recommendation);
        }
        else {
            this.setParametersForPlanB();
            this.setUrlForPlanB();
        }
    }
    assignImageFormat(ddboxIsVisible, isMobile) {
        if (ddboxIsVisible) {
            return CopConstants.ddboxGraphicFormat;
        }
        if (isMobile) {
            return CopConstants.swimmerMobileGraphicFormat;
        }
        return CopConstants.swimmerDesktopGraphicFormat;
    }
    initializeElements() {
        this.elements = {
            image: DOMHelper.querySelector(`${CopConstants.box}${this.boxIndex} ${CopConstants.boxImg}`),
            title: DOMHelper.querySelector(`${CopConstants.box}${this.boxIndex} ${CopConstants.boxHeader} `),
            description: DOMHelper.querySelector(`${CopConstants.box}${this.boxIndex} ${CopConstants.boxDescription} `),
            anchors: DOMHelper.querySelectorAsArray(`${CopConstants.box}${this.boxIndex} ${CopConstants.boxRecommendationAnchor}`),
            readLaterButton: DOMHelper.querySelector(`${CopConstants.bookmark}${this.boxIndex}`)
        };
    }
    buildRecommendationUrl(url, ctaObject, collectionIdsObject, pageViewId, dmParameter) {
        const urlWithAnalytics = new URL(url);
        urlWithAnalytics.searchParams.append("cta", `${ctaObject.cta}`);
        urlWithAnalytics.searchParams.append("actionId", `${collectionIdsObject.actionId}`);
        urlWithAnalytics.searchParams.append("campaignId", `${collectionIdsObject.campaignId}`);
        urlWithAnalytics.searchParams.append("touchPointId", `${collectionIdsObject.touchPointId}`);
        urlWithAnalytics.searchParams.append("art_pvid", `${pageViewId}`);
        urlWithAnalytics.searchParams.append("tile", `${this.boxIndex + 1}`);
        if (dmParameter) {
            urlWithAnalytics.searchParams.append("mapcampdm", dmParameter);
        }
        return urlWithAnalytics.href;
    }
    assignRecommendationData(reco, recoUrl) {
        const { title, lead, params, photoUrl } = reco;
        this.elements.image.src = Utils.changeFormatImg(photoUrl, this.imageFormat);
        this.elements.image.alt = params.tagName;
        this.elements.title.textContent = title;
        this.elements.description.textContent = lead;
        this.elements.anchors.forEach((el) => { el.href = recoUrl; });
    }
    checkIfAllElementsExist() {
        var _a;
        return Boolean(((_a = this.elements) === null || _a === void 0 ? void 0 : _a.image) && this.elements.title && this.elements.description && this.elements.anchors);
    }
    checkIfRecoHasValuesInRequiredFields(recommendation) {
        return Boolean(recommendation.title && recommendation.lead && recommendation.photoUrl && recommendation.url);
    }
    getParameters(reco) {
        this.articleParameters.articleId = reco.id;
        this.articleParameters.pageUrl = reco.url;
    }
    setParametersForPlanB() {
        var _a;
        if ((_a = this.elements) === null || _a === void 0 ? void 0 : _a.anchors[0].href) {
            this.articleParameters.articleId = Number(this.elements.anchors[0].href.split(",")[2]);
            this.articleParameters.pageUrl = this.elements.anchors[0].href;
        }
    }
    setUrlForPlanB() {
        this.elements.anchors.forEach((el) => {
            const urlWithAnalytics = new URL(el.href);
            urlWithAnalytics.searchParams.append("tile", `${this.boxIndex + 1}`);
            el.href = urlWithAnalytics.href;
        });
    }
}
