import { BigDataEventConstants } from "./constants/big-data-constants";
import { DdboxConstants, ZcpClickIds } from "./constants/ddbox-constants";
import { EventType } from "./constants/event-type-constants";
import { MarketingClientConstants } from "./constants/marketing-client-constants";
import { UtilizationConstants } from "./constants/marketing-client-utilization-constants";
import { RequestPaths, URLBase } from "./constants/url-constants";
import { BigDataHelper } from "./helpers/big-data-helper";
import { DOMHelper } from "./helpers/dom-helper";
import { httpInstance } from "./http/http";
import { BigData } from "./marketing-client-bigdata";
import { Checkbox } from "./utils/checkbox";
import { LiveSession } from "./utils/livesession";
import { Utils } from "./utils/utils";
export class ZCPHat {
    constructor(ddbox, utilization) {
        this.agreementFormId = 87;
        this.isHarmonyTest = Utils.getURLParamsAsObject().enableHarmonyTest;
        this.squidHomeHost = this.isHarmonyTest ? "https://coreos07.wyborcza.pl:8094/" : "https://squid-api.wyborcza.pl/";
        this.squidHomeUrl = `${this.squidHomeHost}squid-home/open/accounts/agreements?formId=${this.agreementFormId}`;
        this.agreementUrl = `${this.isHarmonyTest ? URLBase.TEST : URLBase.PROD}${RequestPaths.AGREEMENT}`;
        this.classPrefix = ".ddbox-zcp";
        this.classSuffix = {
            modalOpen: "-modal-open-button",
            modalClose: "-modal-close",
            modal: "-modal",
            ddboxClose: "-ddbox-close",
            accept: "-accept-button",
            textarea: "-textarea",
            helpLink: "__right-box__help",
        };
        this.idCheckbox = "ddbox-zcp-checkbox";
        this.Constants = {
            acceptChangesAndConsent: "AKCEPTUJĘ ZMIANY I WYRAŻAM ZGODĘ",
            acceptChanges: "AKCEPTUJĘ ZMIANY",
            close: "zamknij",
            dontAgree: "Mimo to nie wyrażam zgody",
            thankYouMessage: "Dziękujemy, że jesteś z nami",
        };
        this.isZcp = false;
        this.isLoop = false;
        this.clickFromX = false;
        this.localStorageKeyDate = "MARKETING_CLIENT_HAT_ZCP_DATE";
        this.localStorageKeyCount = "MARKETING_CLIENT_HAT_ZCP_COUNT";
        this.hatClass = {
            modal: ".mod_hat",
            button: ".mod_hat-button",
            text: ".nw_2021_hat-wrapper-text",
            section: "section",
        };
        this.ctaCategory = "ddbox-zcp";
        this.ddbox = ddbox;
        this.utilization = utilization;
    }
    init() {
        this.initializeDom();
        this.hideHatWhenDdboxIsVisible();
        if (!this.box || this.ddbox.ddboxIsVisible) {
            return;
        }
        this.getAgreementForm();
        this.bindEvents();
        this.initializeLiveSession();
    }
    bindOpenModal() {
        if (this.openModalButton) {
            this.openModalButton.addEventListener(EventType.CLICK, () => {
                this.sendBigDataEvent(ZcpClickIds.openModal);
                this.sendBigDataEvent(ZcpClickIds.displayedModal, true);
                this.showDdbox();
            });
        }
    }
    setThankYouMessage() {
        if (this.hatText) {
            this.hatText.innerHTML = this.Constants.thankYouMessage;
        }
    }
    hideHatWhenDdboxIsVisible() {
        var _a, _b;
        this.ddbox.ddboxIsVisible && ((_b = (_a = this.section) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.remove());
    }
    hideOpenModalButton() {
        var _a;
        (_a = this.openModalButton) === null || _a === void 0 ? void 0 : _a.classList.add(MarketingClientConstants.hiddenClassName);
    }
    initializeDom() {
        this.initializeDdbox();
        this.initializeHat();
        this.initializeTextArea();
        this.initializeCheckbox();
        this.initializeAcceptButton();
        this.initializeCancelButton();
        this.initializeModal();
        this.initializeBackModalButton();
        this.initializeCancelModalButton();
        this.initializeHelpLink();
    }
    initializeDdbox() {
        this.box = DOMHelper.querySelector(this.hatClass.modal);
        if (!this.box) {
            return;
        }
        this.closeButton = DOMHelper.querySelector(DdboxConstants.closeButton, this.box);
        this.box.style.display = "none";
        const fragment = document.createDocumentFragment();
        fragment.appendChild(this.box);
        document.body.appendChild(fragment);
    }
    initializeHat() {
        var _a;
        this.openModalButton = DOMHelper.querySelector(this.hatClass.button);
        this.hatText = DOMHelper.querySelector(this.hatClass.text);
        this.section = ((_a = this.openModalButton) === null || _a === void 0 ? void 0 : _a.closest(this.hatClass.section)) || null;
    }
    initializeTextArea() {
        this.agreementTextArea = DOMHelper.querySelector(`${this.classPrefix}${this.classSuffix.textarea}`);
    }
    initializeCheckbox() {
        this.checkbox = new Checkbox(this.idCheckbox, this.toggleAcceptButtonText.bind(this));
    }
    initializeAcceptButton() {
        this.acceptButton = DOMHelper.querySelector(`${this.classPrefix}${this.classSuffix.accept}`);
    }
    initializeModal() {
        this.modal = DOMHelper.querySelector(`${this.classPrefix}${this.classSuffix.modal}`);
    }
    initializeCancelButton() {
        this.cancelButton = DOMHelper.querySelector(`${this.classPrefix}${this.classSuffix.modalOpen}`);
    }
    initializeBackModalButton() {
        this.backModalButton = DOMHelper.querySelector(`${this.classPrefix}${this.classSuffix.modalClose}`);
    }
    initializeHelpLink() {
        this.helpLink = DOMHelper.querySelector(`${this.classPrefix}${this.classSuffix.helpLink} a`);
    }
    initializeCancelModalButton() {
        this.cancelModalButton = DOMHelper.querySelector(`${this.classPrefix}${this.classSuffix.ddboxClose}`);
    }
    initializeLiveSession() {
        if (!this.box)
            return;
        new LiveSession(this.box).init();
    }
    bindEvents() {
        this.bindEventToAcceptAgreements();
        this.bindEventsToShowModal();
        this.bindEventsToHideModal();
        this.bindEventsToCloseDdbox();
        this.bindEventsToCloseDdboxFromX();
        this.bindEventsToHelpLink();
        this.bindOpenModal();
    }
    bindEventToAcceptAgreements() {
        if (this.acceptButton) {
            this.acceptButton.addEventListener(EventType.CLICK, () => {
                this.setAgreement();
            });
        }
    }
    bindEventsToShowModal() {
        if (this.cancelButton) {
            this.cancelButton.addEventListener(EventType.CLICK, () => {
                this.clickFromX = false;
                this.setCancelModalButtonText(this.Constants.dontAgree);
                this.showModal();
                this.sendBigDataEvent(ZcpClickIds.doNotConsent);
            });
        }
    }
    bindEventsToHideModal() {
        if (this.backModalButton) {
            this.backModalButton.addEventListener(EventType.CLICK, () => {
                this.hideModal();
                this.sendBigDataEvent(ZcpClickIds.back);
            });
        }
    }
    bindEventsToCloseDdbox() {
        if (this.cancelModalButton) {
            this.cancelModalButton.addEventListener(EventType.CLICK, () => {
                this.closeDdbox();
                this.sendBigDataEvent(this.clickFromX ? ZcpClickIds.closeInModal : ZcpClickIds.inAnyCaseDoNotConsent);
            });
        }
    }
    bindEventsToCloseDdboxFromX() {
        if (this.closeButton) {
            this.closeButton.addEventListener(EventType.CLICK, () => {
                this.clickFromX = true;
                this.setCancelModalButtonText(this.Constants.close);
                this.isLoop ? this.showModal() : this.closeDdbox();
                this.sendBigDataEvent(ZcpClickIds.close);
            });
        }
    }
    bindEventsToHelpLink() {
        var _a;
        if (this.helpLink) {
            const href = (_a = this.helpLink) === null || _a === void 0 ? void 0 : _a.getAttribute("href");
            this.helpLink.addEventListener(EventType.CLICK, (event) => {
                event.preventDefault();
                this.sendBigDataEvent(ZcpClickIds.helpLink);
                if (href) {
                    window.open(href, "_blank");
                }
            });
        }
    }
    prepareDataToSetAgreement() {
        const ids = [this.agreementRequire.idAgreement];
        if (this.checkbox.getValue()) {
            ids.push(this.agreementOptional.idAgreement);
        }
        return {
            agreements: ids
        };
    }
    utilize() {
        var _a, _b;
        if ((_a = this.section) === null || _a === void 0 ? void 0 : _a.parentElement) {
            const condition = (_b = this.section.getAttribute(UtilizationConstants.dataUtilize)) !== null && _b !== void 0 ? _b : "brak", dataToSend = this.utilization.prepareDataToSend(this.section.parentElement, condition);
            this.utilization.utilize(dataToSend);
        }
    }
    sendBigDataEvent(clickID, onDisplay = false) {
        var _a, _b;
        if ((_a = this.section) === null || _a === void 0 ? void 0 : _a.parentElement) {
            const condition = (_b = this.section.getAttribute(UtilizationConstants.dataUtilize)) !== null && _b !== void 0 ? _b : "brak", dataToSend = this.utilization.prepareDataToSend(this.section.parentElement, condition), attribute = BigDataHelper.createAttribute(this.section.parentElement, true, clickID), eventId = onDisplay ? BigDataEventConstants.onDisplay : BigDataEventConstants.onClick;
            if (onDisplay) {
                attribute.ctaCategory = this.ctaCategory;
            }
            BigData.sendDataOceanEvent(eventId, Object.assign(Object.assign({}, dataToSend), attribute));
        }
    }
    setAgreement() {
        const data = this.prepareDataToSetAgreement(), clickId = this.checkbox.getValue() ? ZcpClickIds.acceptChangesAndConsent : ZcpClickIds.acceptChanges;
        this.closeDdbox();
        this.hideOpenModalButton();
        this.setThankYouMessage();
        this.utilize();
        this.sendBigDataEvent(clickId);
        return httpInstance.post({
            url: this.agreementUrl,
            data,
        });
    }
    getAgreementForm() {
        return httpInstance
            .get({
            url: this.squidHomeUrl
        })
            .then(this.successResponseAgreementForm.bind(this));
    }
    successResponseAgreementForm(response) {
        var _a;
        const data = response.data;
        if (((_a = data === null || data === void 0 ? void 0 : data.agreements) === null || _a === void 0 ? void 0 : _a.length) >= 2) {
            const agreements = response.data.agreements;
            this.setAgreementsAndShowDdbox(agreements);
        }
    }
    setAgreementsAndShowDdbox(agreements) {
        const agreementRequire = agreements.find((agreement) => agreement.optional === false), agreementOptional = agreements.find((agreement) => agreement.optional === true);
        if (agreementRequire && agreementOptional) {
            this.agreementRequire = agreementRequire;
            this.agreementOptional = agreementOptional;
            this.setAgreementsText();
            this.showDdboxEveryFiveDaysAndMaxThreeTime();
        }
    }
    showDdboxEveryFiveDaysAndMaxThreeTime() {
        const lastTime = localStorage.getItem(this.localStorageKeyDate), countString = localStorage.getItem(this.localStorageKeyCount), count = countString ? Number(countString) : 0;
        if (!lastTime) {
            this.showDdbox();
            this.sendBigDataEvent(ZcpClickIds.displayedModal, true);
            localStorage.setItem(this.localStorageKeyDate, new Date().toString());
            localStorage.setItem(this.localStorageKeyCount, (count + 1).toString());
        }
        else {
            const currentDate = new Date(), fiveDayEarly = new Date(currentDate.setDate(currentDate.getDate() - 5));
            if (new Date(lastTime) < fiveDayEarly && count < 3) {
                this.showDdbox();
                this.sendBigDataEvent(ZcpClickIds.displayedModal, true);
                localStorage.setItem(this.localStorageKeyDate, new Date().toString());
                localStorage.setItem(this.localStorageKeyCount, (count + 1).toString());
            }
        }
    }
    setAgreementsText() {
        this.checkbox.setText(this.agreementOptional.agreementText);
        this.setRequireAgreementText();
    }
    showModal() {
        if (this.modal) {
            this.modal.classList.remove(MarketingClientConstants.hiddenClassName);
        }
    }
    hideModal() {
        if (this.modal) {
            this.modal.classList.add(MarketingClientConstants.hiddenClassName);
        }
    }
    closeDdbox() {
        this.hideModal();
        if (this.box) {
            this.box.style.display = "none";
        }
        this.checkbox.reset();
        this.toggleAcceptButtonText();
        this.enableScroll();
    }
    showDdbox() {
        if (this.box) {
            this.box.style.display = "block";
            this.box.classList.add(DdboxConstants.visible);
            this.disableScroll();
        }
    }
    setRequireAgreementText() {
        if (this.agreementTextArea) {
            this.agreementTextArea.innerHTML = this.agreementRequire.agreementText;
        }
    }
    toggleAcceptButtonText() {
        if (this.acceptButton) {
            this.acceptButton.innerHTML = this.checkbox.getValue()
                ? this.Constants.acceptChangesAndConsent
                : this.Constants.acceptChanges;
        }
    }
    setCancelModalButtonText(text) {
        if (this.cancelModalButton) {
            this.cancelModalButton.innerHTML = text;
        }
    }
    disableScroll() {
        document.body.classList.add(MarketingClientConstants.stopScrolling);
    }
    enableScroll() {
        document.body.classList.remove(MarketingClientConstants.stopScrolling);
    }
}
