import { BigDataEventConstants } from "./constants/big-data-constants";
import { CappingConstants } from "./constants/capping-constants";
import { BigDataHelper } from "./helpers/big-data-helper";
import { BigData } from "./marketing-client-bigdata";
import { Utils } from "./utils/utils";
export class Capping {
    constructor(http) {
        this.http = http;
    }
    setGUID(guid) {
        this.guid = guid;
    }
    sendCapping(data, display = false) {
        const visitId = BigDataHelper.getVisitId(), pageViewId = BigDataHelper.getPageViewId(), isHarmonyTest = Utils.getURLParamsAsObject().enableHarmonyTest, url = isHarmonyTest ? process.env["HARMONY_TEST_URL"] : process.env["MARKETING_PROXY_URL"], endPath = display ? CappingConstants.displayPath : CappingConstants.registerPath, headers = { "Content-Type": "application/json" };
        data.visitId = visitId;
        data.pageViewId = pageViewId;
        data.guid = this.guid;
        return this.http
            .put({
            url: `${url}${endPath}`,
            headers,
            data: data
        });
    }
    sendCappingEvent(element, onDisplay = false) {
        const eventName = onDisplay ? BigDataEventConstants.onDisplay : BigDataEventConstants.onLoad, attribute = BigDataHelper.createAttribute(element), { campaignId, actionId, touchPointId } = attribute, collectionIdsObject = { campaignId, actionId, touchPointId };
        this.sendCapping(collectionIdsObject, onDisplay);
        BigData.sendDataOceanEvent(eventName, attribute);
    }
}
